import React from "react";
import ProductDetails from "../components/detailPage/ProductDetails";

const DetailPage = () => {
  return (
    <>
      <ProductDetails />
    </> 
  );
};

export default DetailPage;
