import React from "react";
import ProductCard from "../components/mainPage/ProductCard";
import Filterproduct from "../components/shopPage/Filterproduct";
import Filterproduct1 from "../components/shopPage/Filterproduct1";
// import { MdAddShoppingCart } from "react-icons/md";

const MainPage = () => {
  return (
    <>
      {/* <div className="shopCart__wrapper bg-blue-600">
        <span className="bg-red-500">10</span>
        <MdAddShoppingCart className="_cartIcon" />
      </div> */}
      <div className="">
        <div className="flex items-end justify-end mt-2 ">
          <Filterproduct1 />
        </div>

        <ProductCard />
      </div>
    </>
  );
};

export default MainPage;
