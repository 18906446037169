import React, { useState, useEffect } from "react";
import { BsCheck } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProduct,
  setProductPan,
  updateQuantity,
  dec,
} from "../../Feature/PanierSlice";
import Axios from "axios";

const ShopCartCard = ({ Id, Stock, Add, Name, Total, Unit, Ig }) => {
  //
  // const [present, dismiss] = useIonToast();
  const [loaded, setLoaded] = useState(false);
  const [trashed, setTrash] = useState(false);
  const [edited, setEdited] = useState(false);
  const [quantite, setQuantite] = useState(Add);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );

  const suppression = (ide) => {
    // Axios.delete(`https://backendtrader.digitalfirst.space/deletepan/${ide}`).then((ret)=>{
    //     setTrash(false)
    // });
    dispatch(deleteProduct(ide));
    // setTrash(false);
    dispatch(dec(!trigger));
  };
  const maj = () => {
    if (quantite > 0) {
      // Axios.put('https://backendtrader.digitalfirst.space/majpan', {
      //     product_quantity: parseInt(quantite),
      //     product_id: Id,
      //     price: Unit,
      // }).then((ret) => {
      //     if (ret.data) {
      //     } else {
      //     }
      // })
      dispatch(updateQuantity([parseInt(quantite), Id, quantite * Unit]));
      dispatch(dec(!trigger));
      setEdited(false);
    } else {
      suppression(Id);
      setEdited(false);
    }
  };
  const increm = () => {
    if (quantite < Stock) {
      setQuantite(quantite + 1);
    }
  };
  const decrem = () => {
    if (quantite > 0) {
      setQuantite(quantite - 1);
    }
  };

  useEffect(() => {
    // refr();
  }, []);

  //
  return (
    <div className="shopping__cart__card hover:bg-blue-100 bg-blue-50">
      <div className="cart__card__img">
        {loaded ? null : (
          <img src="./images/loading.gif" className="chargement" />
        )}
        <img
          src={`https://backendtrader.digitalfirst.space/${Ig}`}
          style={loaded ? {} : { display: "none" }}
          onLoad={() => setLoaded(true)}
          alt="Cart product"
        />
      </div>

      <div className="prdName__prdPrice">
        <p className="nom">{Name}</p>
        <p className="prix">
          <span>{quantite} x {" "}</span>
          {new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "XOF",
          }).format(Unit)}
        </p>
      </div>

      {edited ? (
        <>
          <div className="container">
            <div className="qte__wrapper ">
              <div className="qte__select">
                <GoChevronUp className="ctrl__icon" onClick={() => increm()} />
                <input
                  // min={0}
                  type="number"
                  value={quantite}
                />
                <GoChevronDown
                  className="ctrl__icon"
                  onClick={() => decrem()}
                />
              </div>
            </div>
            <BsCheck
              className="shop__add__icon bg-blue-700 ml-2"
              onClick={() => maj()}
            />
          </div>
        </>
      ) : (
        <>
          <button
            className="edit__wrapper bg-blue-700"
            onClick={() => setEdited(true)}
          >
            Editer
          </button>
        </>
      )}

      <RxCross2 className="cart__delete" onClick={()=>{suppression(Id)}} />
    </div>
  );
};

export default ShopCartCard;
