/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useEffect, useRef, useState } from "react";
import {
  IonApp,
  IonButton,
  IonCol,
  IonList,
  IonModal,
  IonThumbnail,
  IonSearchbar,
  IonContent,
  IonAvatar,
  IonSelectOption,
  IonPage,
  IonItemDivider,
  IonSelect,
  IonRadioGroup,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonAlert,
  IonButtons,
  IonMenuButton,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonNote,
  IonBadge,
  IonRouterLink,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from "@ionic/react";

import "./homes.css";
import {
  triangle,
  ellipse,
  square,
  arrowBack,
  arrowForward,
  personCircleOutline,
  globeOutline,
  calendar,
  informationCircle,
  map,
  personCircle,
  chevronBack,
  search,
} from "ionicons/icons";
import { Route, Redirect } from "react-router";

// import { zer } from '../../pages/Nouv2';
// import { SearchModal } from './searchModal';
import { IonReactRouter } from "@ionic/react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { App } from "@capacitor/app";
import { useIonRouter } from "@ionic/react";
import { Conteneur } from "../../components/home/conteneur";
import { recupProduct } from "../../Feature/ProductSlice";
import { FiRefreshCw } from "react-icons/fi";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";

// console.log(Date.parse('2001-10-10'));            // Convertir un string en date (renvoie le nombre de seconde avant 1970)

interface Ajout_utiliformprops {
  Panier: [][];
}

export const Paiement: React.FC<Ajout_utiliformprops> = () => {
  const [response, setresponse] = useState("");
  const [showToast, setShowToast] = useState(false);

  const open = async (totalprix: any) => {
    await openKkiapayWidget({
      // amount: 1,
      amount: totalprix,
      api_key: "f360c365307f9afa1c1cded51173173beef6f22b",
      // sandbox: true,
      // email: "mevivital@gmail.com",
      // phone: "61940010",
      // name: "viyt",
    });
  };

  function successHandler(response: any) {
    console.log(response.transactionId);
    setresponse(response.transactionId);
  }
  const putidboutique = (payload: any) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  const maj_stat = (ide: any) => {
    Axios.post("https://backendtrader.digitalfirst.space/maj_status_paiment", {
      invoice: ide,
      transactionId: response,
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      if (ret.data === "success") {
        setTimeout(() => {
          // putidboutique(JSON.parse(localStorage.getItem("BoutiqueId") + ""));
          window.location.href = "/download_file";
        }, 3000);
      }
    });
  };
  useEffect(() => {
    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success");
    };
  }, []);
  useEffect(() => {
    if (response !== "") {
      maj_stat(localStorage.getItem("invoice_command"));
    }
  }, [response]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <div className="flex justify-between items-center">
            {/* <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  window.location.href = "/home";
                }}
              >
                <IonIcon color="medium" icon={chevronBack} />
              </IonButton>
            </IonButtons> */}
            <IonTitle className="nereide">Digital trader</IonTitle>

            {/* <IonButtons
              slot="end"
              className="mr-5 text-xl cursor-pointer"
              onClick={() => {
                window.location.href = "/prodbout";
              }}
            >
              <FiRefreshCw />
            </IonButtons> */}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Module en cours d'intégration "
        duration={3000}
        position="top"
      />
      <IonContent fullscreen className="">
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="flex flex-col text-xl text-neutral-700 mb-3">
            <span>Veuillez choisir un mode de paiement</span>
          </div>
          <div className="flex flex-wrap gap-4 mt-4 justify-center items-center w-full ">
            <div
              className="flex flex-col justify-center items-center cursor-pointer"
              onClick={() => {
                open(localStorage.getItem("montant_paiement"));
              }}
            >
              <img
                src="kkiapay.jpg"
                alt=""
                className="rounded-full w-20 h-20 object-cover"
              />
              <div>
                <h2 className="text-2xl text-neutral-800">kkia pay</h2>
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-center cursor-pointer"
              onClick={() => {
                setShowToast(true);
                // console.log(localStorage.getItem("montant_paiement"));
                // console.log(localStorage.getItem("invoice_command"));
              }}
            >
              <img
                src="fedapay.png"
                alt=""
                className="rounded-full w-20 h-20 object-cover"
              />
              <div>
                <h2 className="text-2xl text-neutral-800">Feda pay</h2>
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-center cursor-pointer"
              onClick={() => {
                setShowToast(true);
              }}
            >
              <img
                src="cinetpay.png"
                alt=""
                className="rounded-full w-20 h-20 object-cover"
              />
              <div>
                <h2 className="text-2xl text-neutral-800">Cinet pay</h2>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
