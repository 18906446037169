/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  IonApp,
  IonButton,
  IonCol,
  IonList,
  IonModal,
  IonThumbnail,
  IonSearchbar,
  IonContent,
  IonAvatar,
  IonSelectOption,
  IonPage,
  IonItemDivider,
  IonSelect,
  IonRadioGroup,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonAlert,
  IonButtons,
  IonMenuButton,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonNote,
  IonBadge,
  IonRouterLink,
  IonFabButton,
  IonLoading,
  IonToast,
  useIonRouter,
} from "@ionic/react";
import Axios from "axios";

import "./conteneur.css";
import {
  triangle,
  ellipse,
  square,
  arrowBack,
  arrowForward,
  personCircleOutline,
  globeOutline,
  removeCircleSharp,
  removeOutline,
  addOutline,
  informationCircle,
  star,
  chevronBack,
  heart,
  heartOutline,
} from "ionicons/icons";
import { Route, Redirect } from "react-router";

import {
  deleteProduct,
  setProductPan,
  updateQuantity,
  dec,
} from "../../Feature/PanierSlice";

import { IonReactRouter } from "@ionic/react-router";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import AddToCartButton from "./AddToCartButton";
import { log } from "console";
import { useSelector, useDispatch } from "react-redux";
import {
  decdateact,
  setcategories,
  setlike_list,
  deletelike_list,
} from "../../Feature/DeclencheursSlice";
import * as imageConversion from "image-conversion";
import Description from "./description";
import { Link } from "react-router-dom";

interface Ajout_utiliformprops {
  // nom: String;
  // prenom: String;
  Nom: String;
  Prix: number;
  Id: number;
  Stock: number;
  BoutiqueId: number;
  Ig: String;
  quantifiable_product: String;
  // transit: (a: number | React.SetStateAction<any>,
  //     b: number | React.SetStateAction<any>,
  //     c: number | React.SetStateAction<any>) => void;
  Panier: [][];
}

export let boolcont = false;

const aff1 = () => {
  setTimeout(() => {
    boolcont = false;
  }, 500);
  boolcont = true;
};

export const Conteneurs: React.FC<Ajout_utiliformprops> = ({
  Nom,
  Prix,
  Id,
  Stock,
  BoutiqueId,
  Ig,
  quantifiable_product,
}) => {
  const [clic, setClic] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  // const [panier, setPanier] = useState<any[]>(useSelector((state: any) => state.panier.panier));
  let panier = useSelector((state: any) => state.panier.panier);
  let article: any = [].concat(
    useSelector((state: any) => state.product.product)
  );
  const [totalsold, setTotalsold] = useState(
    useSelector((state: any) => state.product.product).filter(
      (t: any) => t.id == Id
    )[0].total_sold
  );
  const [nom, setNom] = useState<String>(Nom);
  const [achatv, setAchatv] = useState<any>(false);
  const [quantite, setQuantite] = useState<any>(1);
  const [prix, setPrix] = useState<any>(Prix);
  const [command, setCommand] = useState<any>();
  const [ajoute, setAjoute] = useState<any>();
  const [telephone, setTelephone] = useState<String>("rr");
  const [remarque, setRemarque] = useState<String>("rr");
  const [adresse, setAdresse] = useState<String>("rr");
  const [antecedant, setantecedant] = useState<String>("rr");
  const [datenaissance, setdatenaissance] = useState<String>("rr");
  const [chargeimg, setChargeImg] = useState<any>(false);
  const [id, setId] = useState<number>(0);
  const [trigger, setTrigger] = useState<any>(
    useSelector((state: any) => state.panier.trigg)
  );
  const likeList = useSelector((state: any) => state.triggers.like_list);
  const dispatch = useDispatch();
  const ionRouter = useIonRouter();
  const [chargelike, setchargelike] = useState<any>(false);
  const [loaded, setLoaded] = useState(false);
  var myImage: any = document.querySelector(".imga");

  // const nav = useNa
  // var downloadingImage = new Image();
  // downloadingImage.onload = function(){
  //     myImage.src = "images/loading.gif";
  // };
  // downloadingImage.src =`https://backendtrader.digitalfirst.space/${Ig}`

  // const trigger= useSelector((state: any) => state.panier.trigg);

  // const [showLoading, setShowLoading] = useState(true);

  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [showToast5, setShowToast5] = useState(false);

  const [nomboutik, setNomboutik] = useState();
  const [descboutik, setDescboutik] = useState();
  const [adressboutik, setAdressboutik] = useState();
  const [paysboutik, setPaysboutik] = useState();
  const [whatboutik, setWhatboutik] = useState();
  const [imagboutik, setImagboutik] = useState();
  const [codeboutik, setCodeboutik] = useState();

  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: BoutiqueId,
      }).then((ret) => {
        // dispatch(setBoutik(ret.data));
        setNomboutik(ret.data[0].store_name);
        setDescboutik(ret.data[0].description);
        setAdressboutik(ret.data[0].adress);
        setPaysboutik(ret.data[0].pays);
        setWhatboutik(ret.data[0].whatsapp);
        setImagboutik(ret.data[0].image);
        setCodeboutik(ret.data[0].boutiqueName);
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const majlike = (n: any | React.SetStateAction<any>) => {
    Axios.put("https://backendtrader.digitalfirst.space/majlike", {
      id: Id,
      nblike: article.find((e: any) => e.id == Id).like_number,
      addlike: n,
      id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id,
    }).then((ret) => {
      if (ret.data == "success") {
        console.log("like ajouté");
      } else {
      }
    });
  };

  // setTimeout(() => {
  //     setShowLoading(false);
  // }, 10000);
  const declike = () => {
    fetch("https://backendtrader.digitalfirst.space/obtimg")
      .then((res) => {
        const data = res;
        return data;
      })
      .then((data) => {
        console.log(data);
        // setCategoryList(data);
      });
  };

  const increm = () => {
    if (quantite < Stock) {
      if (Stock > 0) {
        setQuantite(quantite + 1);
      }
    }
  };
  const decrem = () => {
    if (quantite > 0) {
      if (Stock > 0) {
        setQuantite(quantite - 1);
      }
    }
  };
  const recherche = (ide: any | React.SetStateAction<any>) => {
    if (ide) {
      setCommand(true);
    } else {
      setCommand(false);
    }
  };

  const getpan = () => {
    if (panier.filter((t: any) => t.product_id == Id)[0]) {
      setAjoute(
        panier.filter((t: any) => t.product_id == Id)[0].product_quantity
      );
      setCommand(true);
    } else {
      setAjoute(0);
      setCommand(false);
    }
    setTotalsold(article.filter((t: any) => t.id == Id)[0].total_sold);

    // if(ajoute){
    //     setCommand(true);
    // }else{
    //     setCommand(false);
    // }

    // else {
    //     fetch('https://backendtrader.digitalfirst.space/affichepanier').then((res) => {
    //         const data = res.json()
    //         return data
    //     }).then((data) => {
    //         setPanier(data);
    //         setClic(false);
    //         for (var i = 0, len = data.length, a = 0; i < len; i++) {
    //             if (data[i].product_id == Id) {
    //                 setCommand(true);
    //                 a = a + 1;
    //                 console.log(data[i].product_quantity);
    //                 setAjoute(data[i].product_quantity);
    //             }
    //             if (a == 0 && i == (len - 1)) {
    //                 setCommand(false)
    //             }
    //         }
    //     })
    // }
  };

  const convertimg = async () => {
    // console.log("rr");
    var myImage: any = document.querySelector(".imga");
    fetch(`https://backendtrader.digitalfirst.space/${Ig}`).then((res) => {
      console.log(res);
    });
  };

  const suppression = (ide: number | React.SetStateAction<any>) => {
    // Axios.delete(`https://backendtrader.digitalfirst.space/deletepan/${ide}`);
    // setCommand(false);
    // setAjoute(quantite);
    // setClic(true);
    dispatch(deleteProduct(ide));
    dispatch(dec(!trigger));
    setCommand(false);
    setShowToast3(true);
  };

  const change = () => {
    if (command) {
      setQuantite(ajoute);
    }
    setAchatv(true);
  };

  const ajout = () => {
    if (Stock > 0) {
      setClic(true);
      if (ajoute) {
        if (quantite == 0) {
          // suppression(Id);
          dispatch(deleteProduct(Id));
          dispatch(dec(!trigger));
          // setCommand(false);
          setShowToast3(true);
          // setAjoute(0);
        } else {
          setAjoute(quantite);
          setCommand(true);

          dispatch(
            updateQuantity([parseInt(quantite), Id, Prix * parseInt(quantite)])
          );
          dispatch(dec(!trigger));
          setShowToast2(true);
          setQuantite(1);
        }
      } else {
        setAjoute(quantite);
        setCommand(true);
        if (!panier.find((e: any) => e.product_id == Id) && quantite > 0) {
          dispatch(
            setProductPan({
              product_id: Id,
              product_quantity: parseInt(quantite),
              product_name: Nom,
              unite_price: Prix,
              total_price: Prix * parseInt(quantite),
              picture1: Ig,
              stock: Stock,
              total_sold: totalsold,
              BoutiqueId: BoutiqueId,
            })
          );
          dispatch(dec(!trigger));
          setShowToast1(true);
          setQuantite(1);
        }
      }
    } else if (Stock <= 0) {
      setShowToast5(true);
    }
  };
  const selectboutique = (payload: any) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  useEffect(() => {
    getpan();
    profile_full();
    // if((panier.find((e:any)=>e.product_id==Id))){
    //     setAjoute((panier.find((e:any)=>e.product_id==Id)).product_quantity);
    //     console.log('dia'); }

    // console.log('dia');
  }, [(trigger: any) => {}]);

  useEffect(() => {
    // console.log((article.find((e: any) => e.id == Id)).like_number);
    // convertimg()
  }, []);
  return (
    <>
      {command ? (
        <IonFabButton color="secondary" className="notifbadge">
          {ajoute}
        </IonFabButton>
      ) : null}
      {likeList.includes(Id) ? (
        // <IonFabButton color="secondary" id="fave-button">{ajoute}</IonFabButton>
        <IonIcon
          icon={heart}
          className="favbadge"
          color="danger"
          size="large"
          onClick={() => {
            dispatch(deletelike_list(Id));
            majlike(0);
          }}
        />
      ) : (
        <IonIcon
          icon={heartOutline}
          className="favbadge"
          color="danger"
          size="large"
          onClick={() => {
            dispatch(setlike_list(Id));
            majlike(1);
          }}
        />
      )}
      {/* <Link to={`/details/${Id}`}> */}
      <IonCard className="card relative">
        {loaded ? null : <img src={`loading.gif`} className="imga" />}
        <img
          src={`https://backendtrader.digitalfirst.space/${Ig}`}
          style={loaded ? {} : { display: "none" }}
          onLoad={() => setLoaded(true)}
          alt="card"
          className="imga"
          //  <img src="images/yelan.png" alt="card" className="imga"
          onClick={() => {
            // setShowmodal(true);
            window.location.href = `/details/${Id}/${BoutiqueId}`;
          }}
        />
        <div className="w-[100px] h-6 absolute bottom-2 right-4 pl-2 rounded-xl flex items-center justify-center bg-blue_done1 text-10 text-white truncate">
          <span className="w-full truncate">
            {paysboutik === ""
              ? "Non Configurer"
              : paysboutik + " / " + adressboutik}
          </span>
        </div>
        <IonCardContent className="cardcontents">
          <IonRow className="r1">
            <h5 className="nom">{Nom}</h5>
          </IonRow>
          <IonRow className="r2">
            {/* <IonNote className="note1">{prix}$  </IonNote> */}
            <IonNote className="note1">
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "XOF",
              }).format(Prix)}{" "}
            </IonNote>
            <IonNote className="note1">
              Stock:{quantifiable_product === "oui" ? Stock : " null"}{" "}
            </IonNote>
          </IonRow>
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-between text-sm p-1 w-full gap-2">
              <span className="w-1/2">Vendeur: </span>
              <a
                onClick={() => {
                  selectboutique(BoutiqueId);
                  // console.log(totalsold);
                }}
                className="w-1/2 text-10 truncate cursor-pointer text-blue_done1"
              >
                {" "}
                {nomboutik}
              </a>
            </div>
            {/* <div className="flex items-start justify-start text-sm p-1 w-full truncate">
              <span className="w-full truncate">{adressboutik}</span>
            </div> */}
          </div>
          {/* <IonButton
            className="comb"
            color="secondary"
            onClick={() => {
              selectboutique(BoutiqueId);
              // console.log(totalsold);
            }}
          >
            Voir la boutique
          </IonButton> */}
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="Elément ajouté au panier"
            icon={informationCircle}
            position="top"
            duration={800}
          />
          <IonToast
            isOpen={showToast2}
            onDidDismiss={() => setShowToast2(false)}
            message="Quantité mise à jour"
            icon={informationCircle}
            position="top"
            duration={800}
          />
          <IonToast
            isOpen={showToast3}
            onDidDismiss={() => setShowToast3(false)}
            message="Elément supprimé du panier"
            icon={informationCircle}
            position="top"
            duration={800}
          />

          <IonToast
            isOpen={showToast5}
            onDidDismiss={() => setShowToast5(false)}
            message="article en rupture de stock"
            icon={informationCircle}
            position="top"
            duration={800}
          />
          <IonModal
            isOpen={showmodal}
            onDidDismiss={() => {
              setShowmodal(false);
            }}
          >
            <IonItem className="Item1" lines="none">
              <IonButtons
                slot="start"
                onClick={() => {
                  setShowmodal(false);
                }}
              >
                <IonIcon icon={chevronBack} />
              </IonButtons>
              {Nom}
            </IonItem>
            <Description Id={Id} BoutiqueId={BoutiqueId} />
          </IonModal>

          {/* <IonCardTitle className="title">{nom}</IonCardTitle>
                    <IonNote className="subtitle">{prix}</IonNote> */}
        </IonCardContent>
      </IonCard>
      {/* </Link> */}
    </>
  );
};
