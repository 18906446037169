import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setboutique_encoded } from "../../Feature/ProductSlice";

const ShopForm = () => {
  //
  const [boutik, setboutik] = useState("");
  const [numeros, setnumeros] = useState(0);
  const [ifboutik, setIfboutik] = useState(false);
  const [ifnumeros, setIfnumeros] = useState(false);
  const [ifnumeross, setIfnumeross] = useState(false);
  const [ifmontdepa, setIfmontdepa] = useState(false);
  const [type, settype] = useState("sellers");
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [progress, setprogress] = useState(false);
  // const Regex = /^\+229\d{8}$/;
  const Regex = /^\+\d{1,3}\d{8,9}$/; 

  const dispatch = useDispatch();

  const dep = () => {
    if (!boutik) {
      setIfboutik(true);
      setTimeout(() => {
        setIfboutik(false);
      }, [4000]);
    } else {
      setIfboutik(false);
      setprogress(true);
      // setShowLoading(true);
      Axios.post("https://backendtrader.digitalfirst.space/verif_parrainage", {
        boutique: boutik,
      }).then((res) => {
        // console.log(res.data.message);
        if (res.data.message === "succ") {
          // setprogress(false);
          // setShowLoading(false);
          // setShowToast(true);
          dispatch(setboutique_encoded(res.data.result));
          setboutik("");
          // dispatch(setnumberUser(numeros));
          // window.location.href = "/home"
        } else if (res.data.message === "sacc") {
          setprogress(false);
          // setShowLoading(false);
          setShowToast1(true);
        }
        // if(res.data)
        // setShowLoading(false);
        // setShowToast(true);
        // setboutik("");
        // setnumeros("");

        // console.log(res.data);
      });
    }
    // if (!numeros) {
    //   setIfnumeros(true);
    //   setTimeout(() => {
    //     setIfnumeros(false);
    //   }, [4000]);
    // } else {
    //   setIfnumeros(false);
    // }
    // if (numeros.match(Regex)) {
    //   setIfnumeross(false);
    // } else {
    //   setIfnumeross(true);
    //   setTimeout(() => {
    //     setIfnumeross(false);
    //   }, [4000]);
    // }

    // if (boutik) {

    // }
  };

  useEffect(() => {
    // console.log(caisse_value[0].caisse);
    // console.log(userid.userId);
  }, []);

  //
  return (
    <div className="form__wrapper">
      <h2>Entrez le code de la boutique</h2>
      <input
        type="text"
        placeholder="Code de la boutique affiliée"
        value={boutik}
        onChange={(e) => setboutik(e.target.value)}
      />
      {ifboutik && (
        <div className="empty_full">
          Veuillez entrez le code d'une boutique!
        </div>
      )}
      <button className="bg-blue-700" onClick={dep}>
        Valider
      </button>
      <div className="sparator"></div>
    </div>
  );
};

export default ShopForm;
