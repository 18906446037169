import React from 'react';
import AddShopForm from '../components/addShop/AddShopForm';

const AddShopPage = () => {
    return (
        <>
        <AddShopForm/>
        </>
    );
};

export default AddShopPage;