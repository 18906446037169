/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BiFilter } from "react-icons/bi";
import ProductCard from "../components/mainPage/ProductCard";
import BottomForm from "../components/shopPage/BottomForm";
import Filterproduct from "../components/shopPage/Filterproduct";
import SearchSection from "../components/shopPage/SearchSection";
import Tab1Section from "../components/shopPage/Tab1Section";
import Tab2Section from "../components/shopPage/Tab2Section";
import Tab3Section from "../components/shopPage/Tab3Section";
import toast, { Toaster } from "react-hot-toast";
import { IonBadge, IonButton, IonFab, IonIcon } from "@ionic/react";
import { cart } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { dectriggmod, setnumberUser } from "../Feature/DeclencheursSlice";
import { dec, setnumberclients, vider } from "../Feature/PanierSlice";
import Axios from "axios";
import { recupProduct } from "../Feature/ProductSlice";
import { Modal } from "react-bootstrap";
import ShopContent from "../components/shopCart/ShopContent";

const ShopPage = () => {
  const [data, setData] = useState(false);
  let panier = useSelector((state) => state.panier.panier);
  let toastaddcommande = useSelector(
    (state) => state.triggers.toastaddcommande
  );
  let message = useSelector((state) => state.triggers.message);

  // const dispatch = useDispatch();
  // const [trigger, setTrigger] = useState(
  //   useSelector((state) => state.panier.trigg)
  // );
  // let trigger2 = useSelector((state) => state.triggers.triggermod);
  // const [whatsapp, setWhatsapp] = useState("");
  // // let panier = useSelector((state) => state.panier.panier);
  // // let numUser = useSelector((state) => state.triggers.numberUser);
  // const boutique = useSelector((state) => state.product.boutique);
  // const [numUser, setnumUser] = useState("");
  // let commande = [];
  // const [reclusia, setRec] = useState([]);
  // const Regex = /^\+229\d{8}$/;

  // const [openMenu, setOpenMenu] = useState(false);

  // let totalprice = useSelector((state) => state.panier.totalPrix);
  // let totalqte = useSelector((state) => state.panier.totalQuantite);
  // const numberclient = useSelector((state) => state.panier.numberclient);

  // const [progress, setprogress] = useState(false);
  // const [progress1, setprogress1] = useState(false);

  // const [showmodal, setShowmodal] = useState(false);

  // const [show, setShow] = useState(false);

  // const calc = () => {
  //   console.log(whatsapp);
  //   if (whatsapp.match(Regex)) {
  //     dispatch(setnumberUser(whatsapp));
  //     dispatch(setnumberclients(whatsapp));
  //     envoi1();
  //   } else {
  //     // setShowToast1(true);
  //     setprogress(true);
  //     toast.error("Vueillez configurer le numeros de telephone");
  //   }
  // };

  // const envoi1 = () => {
  //   if (parseInt(panier.length) > 0) {
  //     // setprogress(true);
  //     setprogress1(true);
  //     Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList", {}).then(
  //       (ret) => {
  //         for (let index = 0; index < panier.length; index++) {
  //           Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList1", {
  //             product_quantity: panier[index].product_quantity,
  //             total_price: panier[index].total_price,
  //             unite_price: panier[index].unite_price,
  //             product_name: panier[index].product_name,
  //             product_id: panier[index].product_id,
  //             stock: panier[index].stock,
  //             picture1: panier[index].picture1,
  //             BoutiqueId: panier[index].BoutiqueId,
  //             total_sold: panier[index].total_sold,
  //             whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
  //             invoice: ret.data,
  //           }).then((rets) => {
  //             console.log(rets.data);
  //           });
  //         }
  //         console.log(ret.data);
  //         console.log("1ere etape");
  //         envoi(ret.data);
  //       }
  //     );
  //   } else {
  //     // setShowToast3(true);
  //   }
  // };

  // const envoi = (ide) => {
  //   console.log(ide, "facture");
  //   console.log(totalqte, "quantite total");
  //   console.log(totalprice, "prix total");
  //   console.log(JSON.parse(localStorage.getItem("BoutiqueId") + ""));
  //   Axios.post("https://backendtrader.digitalfirst.space/ajoutcommande", {
  //     totalquant: totalqte,
  //     totalprix: totalprice,
  //     invoice: ide,
  //     whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
  //     // whatsapp: !numUser ? whatsapp : numUser,
  //     id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
  //   }).then((ret) => {
  //     if (ret.data === "suc") {
  //       console.log("commande ajoutée");
  //       // setShowToast2(true);
  //       // setprogress(false);
  //       console.log("2ere etape");
  //       envoi3();
  //     } else {
  //     }
  //   });
  // };

  // const envoi3 = () => {
  //   dispatch(dec(!trigger));
  //   dispatch(dectriggmod(!trigger2));
  //   // const whats = `https://wa.me/${
  //   //   JSON.parse(localStorage.getItem("parrain") + "")
  //   // }?text=${commande}`;
  //   dispatch(vider(""));
  //   setprogress1(false);
  //   setprogress(true);
  //   toast.success("commande effectué avec success");
  //   setShowmodal(false);
  //   Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
  //     id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
  //   }).then((ret) => {
  //     dispatch(recupProduct(ret.data));
  //     console.log(ret.data);
  //     console.log("3ere etape");
  //   });
  //   const whats = `https://wa.me/${numUser}?text=${commande}`;
  //   setTimeout(() => {
  //     {
  //       numUser !== "" && (window.location.href = whats);
  //     }
  //   }, 2000);

  //   // setShowmodal(false);
  // };
  // useEffect(() => {
  //   for (var i = 0; i < panier.length; i++) {
  //     commande = [
  //       ...commande,
  //       "\n" + panier[i].product_name + "x" + panier[i].product_quantity,
  //     ];
  //   }
  //   console.log(commande);
  //   // profile_full();
  //   if (boutique[0]) {
  //     setnumUser(boutique[0].whatsapp);
  //   }
  // }, []);
  // useEffect(() => {
  //   if (boutique[0]) {
  //     setnumUser(boutique[0].whatsapp);
  //   }
  // }, [boutique]);

  // useEffect(() => {
  //   if (JSON.parse(localStorage.getItem("numberclient") + "")) {
  //     dispatch(
  //       setnumberclients(JSON.parse(localStorage.getItem("numberclient") + ""))
  //     );
  //   }
  // }, [(trigger) => {}]);

  useEffect(() => {
    // if (message === 0) {
      toast.error(
        "Ce produits appartient à une boutique autre que celle de votre commande",
        {
          duration: 6000,
        }
      );
    // }else if(message === 1){
    //   toast.success('Produit ajouté à la commande ', {
    //     duration: 6000,
    //   })
    // }
  }, [toastaddcommande]);

  document.getElementById("fars")?.addEventListener("touchmove", (e) => {
    document.getElementById("fars").style.left =
      ((e.changedTouches[0].clientX - 25) / window.innerWidth) * 100 + "%";
    document.getElementById("fars").style.top =
      ((e.changedTouches[0].clientY - 25) / window.innerHeight) * 100 + "%";
  });
  return (
    <div className="shop__Page">
      {toastaddcommande && (
        <div>
          <Toaster />
        </div>
      )}
      {/* <SearchSection /> */}
      <Filterproduct />
      <Tab1Section />
      {/* <div
        className="flex items-center justify-end mt-0"
        onClick={() => {
          setShow(true);
        }}
        // id="fars"
      >
        <button
          className="bg-blue-700 px-2 py-2 flex rounded-lg text-xl text-white items-center"
          id="fars"
        >{panier.length} {" "}
          <BiFilter className="mr-2" />
          Votre commande
        </button>
      </div> */}
      <Tab2Section />
      <Tab3Section />
      {/* <div className="other_btn">
       <button className="bg-blue-700">
       Autres produits
       </button>
      </div> */}
      {/* <ProductCard/> */}
      <BottomForm />
      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="shopping__cart__wrapper">
          {progress && (
            <div>
              <Toaster />
            </div>
          )}
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h5 class="modal-title" id="exampleModalLongTitle">
                Panier({panier.length} articles)
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="cart__content">
              <ShopContent />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {progress1 ? (
              <div className="bottom__ctrl">
                <p>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "XOF",
                  }).format(totalprice)}
                </p>
                <div class="spinner"></div>
              </div>
            ) : numberclient === "" ? (
              <div className="bottom__ctrl gap-2">
                <p>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "XOF",
                  }).format(totalprice)}
                </p>
                <div className="flex items-center justify-center gap-2 -mt-3">
                  <div class="mb-0">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Ex: 69889350"
                      onChange={(e) => {
                        setWhatsapp("+229" + e.target.value);
                        console.log("+229" + e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="bg-blue-700 hover:bg-blue-600"
                    onClick={() => calc()}
                  >
                    Commander
                  </button>
                </div>
              </div>
            ) : (
              <div className="bottom__ctrl">
                <p>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "XOF",
                  }).format(totalprice)}
                </p>
                <button
                  className="bg-blue-700 hover:bg-blue-600"
                  onClick={() => envoi1()}
                >
                  Commander
                </button>
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal> */}
    </div>
  );
};

export default ShopPage;
