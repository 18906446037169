/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import {
  dec,
  setTotalPrix,
  setTotalQuantite,
  vider,
} from "../../Feature/PanierSlice";
import { recupProduct, setBoutik } from "../../Feature/ProductSlice";
import { dectriggmod, setnumberUser } from "../../Feature/DeclencheursSlice";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import ShopCartCard from "./ShopCartCard";

const ShopContent = () => {
  //
  const [qte, setqte] = useState(1);

  const [showmodal, setShowmodal] = useState(false);
  const [progress, setprogress] = useState(false);
  const [totalquant, setTotalquant] = useState(0);
  const [totalprix, setTotalprix] = useState(0);
  const [invoice, setInvoice] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [sexe, setSexe] = useState("");
  const [trashed, setTrash] = useState(false);
  const [edited, setEdited] = useState(false);
  const [telephone2, setTelephone2] = useState(0);
  const [quartier, setQuartier] = useState("Quartier");
  const [ville, setVille] = useState("Ville");
  const [maison, setMaison] = useState("Maison");
  const [adresse, setAdresse] = useState("");
  let [antecedants, setAntecedants] = useState(" ");
  let [date, setdate] = useState("");
  const [remarque, setRemarque] = useState(" ");
  // const [commande, setCommande] = useState<any>();
  const [showLoading, setShowLoading] = useState(true);
  const [zer, setZer] = useState(useSelector((state) => state.panier.panier));
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );
  let trigger2 = useSelector((state) => state.triggers.triggermod);
  let panier = useSelector((state) => state.panier.panier);
  // let numUser = useSelector((state) => state.triggers.numberUser);
  const boutique = useSelector((state) => state.product.boutique);
  const [numUser, setnumUser] = useState("");

  const [reclusia, setRec] = useState([]);
  // const Regex = /^\+229\d{8}$/;
  const Regex = /^\+\d{1,3}\d{8,9}$/; 

  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  let commande = "";
  const dispatch = useDispatch();

  const getpan = () => {
    // fetch('https://backendtrader.digitalfirst.space/affichepanier').then((res) => {
    //     const data = res.json()
    //     return data
    // }).then((data) => {
    //     setPanier(data);
    //     setInvoice(data[0].invoice);
    // })
    setTotalquant(
      panier
        .map((e) => e.product_quantity)
        .reduce((prev, curr) => prev + curr, 0)
    );
    dispatch(
      setTotalQuantite(
        panier
          .map((e) => e.product_quantity)
          .reduce((prev, curr) => prev + curr, 0)
      )
    );
    setTotalprix(
      panier.map((e) => e.total_price).reduce((prev, curr) => prev + curr, 0)
    );
    dispatch(
      setTotalPrix(
        panier.map((e) => e.total_price).reduce((prev, curr) => prev + curr, 0)
      )
    );

    for (var i = 0; i < panier.length; i++) {
      commande = [
        ...commande,
        "\n" + panier[i].product_name + "x" + panier[i].product_quantity,
      ];
    }
  };
  const suppression = (ide) => {
    Axios.delete(`https://backendtrader.digitalfirst.space/deletepan/${ide}`);
    getpan();
    // aff();
    getpan();
  };

  const calc = () => {
    console.log(whatsapp);

    if (whatsapp.match(Regex)) {
      dispatch(setnumberUser(whatsapp));
      envoi1();
    } else {
      setShowToast1(true);
    }
  };

  const envoi1 = () => {
    if (parseInt(panier.length) > 0) {
      setprogress(true);
      Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList", {
        panier: panier,
        tail: parseInt(panier.length),
        whatsapp: numUser === "" ? whatsapp : numUser,
        // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id,
        id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      }).then((ret) => {
        console.log(ret.data);
        envoi(ret.data);
      });
    } else {
      setShowToast3(true);
    }
  };
  const suppr = () => {
    fetch("https://backendtrader.digitalfirst.space/supprpan", {}).then((data) => {
      if (data) {
      } else {
      }
    });
    getpan();
    // aff();
  };
  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      }).then((ret) => {
        dispatch(setBoutik(ret.data));
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const envoi = (ide) => {
    Axios.post("https://backendtrader.digitalfirst.space/ajoutcommande", {
      totalquant: totalquant,
      totalprix: totalprix,
      invoice: ide,
      status_paiement: 
          panier[0].type_product === "Physique" ? "PAYER" : "NON PAYER",
      whatsapp: numUser === "" ? whatsapp : numUser,
      // whatsapp: !numUser ? whatsapp : numUser,
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      if (ret.data === "suc") {
        console.log("commande ajoutée");
        setShowToast2(true);
        setprogress(false);
        setTimeout(() => {
          envoi3();
        }, 3000);
      } else {
      }
    });
  };

  const envoi3 = () => {
    dispatch(dec(!trigger));
    dispatch(dectriggmod(!trigger2));
    // const whats = `https://wa.me/${
    //   JSON.parse(localStorage.getItem("parrain") + "")
    // }?text=${commande}`;
    const whats = `https://wa.me/${numUser}?text=${commande}`;
    {
      window.location.href = whats;
    }
    dispatch(vider(""));
    Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recupProduct(ret.data));
      console.log(ret.data);
    });
    setShowmodal(false);
  };

  const refr = () => {
    Axios.post("http://127.0.0.1:8000/api/auth/checkToken", {
      token: "",
    }).then((ret) => {
      if (ret.data.success) {
        console.log("success");
      } else {
        console.log("non success");
      }
    });
  };

  useEffect(() => {
    getpan();
    profile_full();
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
    }
  }, []);
  useEffect(() => {
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
    }
  }, [boutique]);

  useEffect(() => {
    getpan();
  }, [(trigger) => {}]);

  //
  return (
    <>
      {panier.map((val, index) => {
        return (
          <ShopCartCard
            Id={val.product_id}
            Stock={val.stock}
            Add={val.product_quantity}
            Name={val.product_name}
            Unit={val.unite_price}
            Total={val.total_price}
            Ig={val.picture1}
          />
        );
      })}
    </>
  );
};

export default ShopContent;
