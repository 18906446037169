import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonSplitPane,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Nouv from "./pages/Nouvpage";
import Nouv2 from "./pages/Nouv2";
import "./App.css";
import "animate.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import {
  homeOutline,
  listOutline,
  cogOutline,
  cart,
  personOutline,
} from "ionicons/icons";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { recupPan, recupPan2, setnumberclients } from "./Feature/PanierSlice";
import {
  createboutique_encoded,
  createfavoris,
  recupProduct,
  setBadge,
  setfeaturedprod,
  setproduitbout,
} from "./Feature/ProductSlice";
import { IonButton, useIonLoading } from "@ionic/react";
import {
  decdateact,
  setcategories,
  setlike_list,
  recuplike_list,
  setnumberUser,
} from "./Feature/DeclencheursSlice";

import Axios from "axios";
import ProtectedRoute from "./components/home/ProtectedRoute";
import Nouv3 from "./pages/Nouv3";
import Search from "./components/home/Search";
import Parrainage from "./components/home/Parrainage";
import Home from "./pages/Home";
import Populaire from "./components/home/Populaire";
import Details from "./components/home/Details";
import AppUrlListener from "./components/home/AppUrlListener";
import { Paiement } from "./components/home/Paiement";
import { Download_file } from "./components/home/Download_file";
import Version from "./components/home/Version";
import Populaire2 from "./components/home/Populaire2";

setupIonicReact();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [present, dismiss] = useIonLoading();
  const [authuser, setauthuser] = useState(true);
  const [version, setversion] = useState(true);
  const fav = JSON.parse(localStorage.getItem("favoris") + "");

  useEffect(() => {
    //  console.log(JSON.parse(localStorage.getItem("parrain") + "")[0].id);
    // if (JSON.parse(localStorage.getItem("parrain") + "")) {
    //  let tab = JSON.parse(localStorage.getItem("parrain") + "")[0];
    getVersion();
    setauthuser(true);
    getproduitparboutik();
    if (JSON.parse(localStorage.getItem("numberclient") + "")) {
      dispatch(
        setnumberclients(JSON.parse(localStorage.getItem("numberclient") + ""))
      );
    } else {
      dispatch(setnumberclients(""));
    }
    try {
      dispatch(recupPan(JSON.parse(localStorage.getItem("panier") + "")));
      dispatch(recupPan2(JSON.parse(localStorage.getItem("panier2") + "")));
      dispatch(
        recuplike_list(JSON.parse(localStorage.getItem("like_list") + ""))
      );
      dispatch(
        setnumberUser(JSON.parse(localStorage.getItem("numberUser") + ""))
      );
    getVersion();
      // dispatch(setaffiliate(JSON.parse(localStorage.getItem("parrain") + "")[0]));
      // dispatch(setnumberUser(JSON.parse(localStorage.getItem("numberUser") + "")[0]));
      Axios.get(
        "https://backendtrader.digitalfirst.space/get_all_promotion"
        // "https://backendtrader.digitalfirst.space/allfeaturedproducts"
      ).then((ret) => {
        dispatch(setfeaturedprod(ret.data));
        console.log(ret.data);
      });
      Axios.get("https://backendtrader.digitalfirst.space/allproducts").then(
        (ret) => {
          dispatch(recupProduct(ret.data));
          console.log(ret.data);
        }
      );
    } catch (e) {}
    try {
      fetch("https://backendtrader.digitalfirst.space/date_time")
        .then((res) => {
          const data = res.json();
          return data;
        })
        .then((data) => {
          dispatch(decdateact(data[0].time_actu));
        });
    } catch (e) {}
    present({
      message: "Veuillez patienter",
      duration: 200,
    });
    // window.setInterval(() => {
    //   Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
    //     id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
    //     // id_boutique: 8
    //   }).then((ret) => {
    //     dispatch(recupProduct(ret.data));
    //     console.log(ret.data);
    //   });
    // }, 2000);
    // } else {
    //   setauthuser(false)
    //   if (window.location.pathname != "/parrainage") {
    //     window.location.href = "/parrainage";
    //   }
    // }
    if (JSON.parse(localStorage.getItem("favoris") + "")) {
      dispatch(createfavoris(JSON.parse(localStorage.getItem("favoris") + "")));
    } else {
      var tab = [
        [
          {
            store_name: "",
            adress: "",
            description: "",
            website: "",
            facebook: "",
            whatsapp: "",
            boutiqueName: "startzeroh",
            image: "",
            id: "",
          },
        ],
      ];
      dispatch(createfavoris(tab));
    }
    // if (JSON.parse(localStorage.getItem("badge_client") + "")) {
    //   dispatch(setBadge(JSON.parse(localStorage.getItem("badge_client") + "")));
    // }else{
    //  let  tab = 0;
    //    fav.map((t: any) => dispatch(setBadge(t[0].id)));

    // }
    if (JSON.parse(localStorage.getItem("boutique_encoded") + "")) {
      dispatch(
        createboutique_encoded(
          JSON.parse(localStorage.getItem("boutique_encoded") + "")
        )
      );
    } else {
      var tabe = [
        [
          {
            store_name: "",
            adress: "",
            description: "",
            website: "",
            facebook: "",
            whatsapp: "",
            boutiqueName: "startzeroh",
            image: "",
            id: "",
          },
        ],
      ];
      dispatch(createboutique_encoded(tabe));
    }
  }, []);
  const getproduitparboutik = () => {
    Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
      // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId")+"") ? JSON.parse(localStorage.getItem("BoutiqueId")+"") : 1,
    }).then((ret) => {
      dispatch(setproduitbout(ret.data));
      console.log(ret.data);
      Axios.post(
        "https://backendtrader.digitalfirst.space/affichecategory",
        {
          id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
          // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId")+"") ? JSON.parse(localStorage.getItem("BoutiqueId")+"") : 1,
          // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
        }
      ).then((ret) => {
        console.log(ret.data);
        dispatch(setcategories(ret.data));
      });
    });
  };
  const getVersion = () => {
    Axios.get("https://backendtrader.digitalfirst.space/version", {}).then(
      (response) => {
        if (response.data[0].version === "1.0.2") {
          console.log(response.data);
          setversion(true);
          localStorage.setItem("change_version1", "non");
        } else {
          console.log(response.data);
          setversion(false);
          localStorage.setItem("change_version1", "oui");
          if (response.data[0].status === "obligatoire") {
            localStorage.setItem("status_version1", response.data[0].status);
          } else if (response.data[0].status === "facultative") {
            localStorage.setItem("status_version1", response.data[0].status);
          }
        }
      }
    );
  };

  return (
    <IonApp className="alice">
      <IonReactRouter>
        {/* <Menu /> */}
        <AppUrlListener></AppUrlListener>

        <IonRouterOutlet id="main" mode="ios">
          <ProtectedRoute
            path="/home"
            // component={Home}
            component={Populaire}
            // authuser={authuser}
            version={version}
          />
          <ProtectedRoute
            path="/prodbout"
            component={Home}
            // authuser={authuser}
            version={version}
          />
          <ProtectedRoute
            path="/paiement"
            component={Paiement}
            // authuser={authuser}
            version={version}
          />
          <ProtectedRoute
            path="/download_file"
            component={Download_file}
            // authuser={authuser}
            version={version}
          />
          <Route exact path="/version">
            <Version />
          </Route>
          <Route exact path="/tt">
            <Nouv3 />
          </Route>
          <Route exact path="/Search">
            <Search />
          </Route>
          <Route path="/add">
            <Nouv />
          </Route>
          <Route path="/add2">
            <Nouv2 />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/details/:id/:idbout">
            <Details />
          </Route>
          <Route exact path="/home/articledesc/:id">
            <Home />
          </Route>
          <Route exact path="/home/categorie/:id">
            <Home />
          </Route>
          <Route exact path="/addboutique">
            <Parrainage />
          </Route>
        </IonRouterOutlet>
        {/* <IonTabBar slot='bottom' color='secondary'>
            <IonTabButton tab="tab1" href="/home">
              <IonIcon icon={homeOutline} />
            </IonTabButton>
            <IonTabButton tab="tab2" href="/panier" >
              <IonIcon icon={personOutline} />
            </IonTabButton>
            <IonTabButton tab="tab3" href="/tab3">
              <IonIcon icon={cogOutline} />
            </IonTabButton>
          </IonTabBar> */}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
