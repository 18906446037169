/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import HomeCard from "./HomeCard";
import { useDispatch, useSelector } from "react-redux";
// import Paniermodal from "./Paniermodal";
import { setBadge } from "../../Feature/ProductSlice";
import HomeCard1 from "./HomeCard1";
import { calcule_temp } from "../home/Populaire";
import Filterproduct from "../shopPage/Filterproduct";

const ProductCard = () => {
  //
  const [width, setWidth] = useState(window.innerWidth);
  const [size, setSize] = useState(650);
  //
  const dispatch = useDispatch();
  let panier = useSelector((state) => state.panier.panier);
  const [showmodal, setShowmodal] = useState(false);
  const [sho, setSho] = useState(false);
  const products_featured = useSelector(
    (state) => state.product.product_featured
  );
  const favoris = useSelector((state) => state.product.favoris);
  const boutique_encoded = useSelector(
    (state) => state.product.boutique_encoded
  );
  // const badge = useSelector((state) => state.product.badge_client);
  let article2 = []
    .concat(useSelector((state) => state.product.product))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });
  const [accorde, setaccorde] = useState(true);
  // const boutiquecompte = useSelector((state) => state.Hash.boutiquecompte);
  const [commandeRech, setCommandeRech] = useState("rr");
  //   let far = useSelector((state) => state.auth.user.username);
  let username = "syunbiose";
  // JSON.parse(localStorage.getItem("store_name") + "") === ""
  //   ? far
  //   : JSON.parse(localStorage.getItem("store_name") + "");

  let numberwhat = "229 xxxxxxxx";
  // JSON.parse(localStorage.getItem("whatsapp") + "") === ""
  //   ? "229 xxxxxxxx"
  //   : JSON.parse(localStorage.getItem("whatsapp") + "");
  const selectboutique = (payload) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  const actu_time = () => {
    try {
      fetch("https://backendtrader.digitalfirst.space/date_time")
        .then((res) => {
          const data = res.json();
          return data;
        })
        .then((data) => {
          console.log(data[0].time_actu.split("T")[0]);
          localStorage.setItem(
            "dateActue",
            JSON.stringify(data[0].time_actu.split("T")[0])
          );
        });
    } catch (e) {}
  };

  document.getElementById("far")?.addEventListener("touchmove", (e) => {
    document.getElementById("far").style.left =
      ((e.changedTouches[0].clientX - 25) / window.innerWidth) * 100 + "%";
    document.getElementById("far").style.top =
      ((e.changedTouches[0].clientY - 25) / window.innerHeight) * 100 + "%";
  });
  //
  const UpdateDimension = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    actu_time();
  }, []);

  window.addEventListener("resize", UpdateDimension);
  //

  return (
    <div className="flex items-center justify-center">
      {article2[0] && products_featured[0] ? (
        <div className="container-fluid mt-3 mb-3 flex flex-wrap justify-center items-center gap-3 card__wraper">
          {products_featured
            // .filter((t) => t.status === "Actif")
            .filter((t) => calcule_temp(t.date_fin) > 0 && t.status === "Actif")
            .map((feature, index) => {
              let idproduit = feature.id_product;
              return (
                <>
                  {article2
                    // .slice(0, 10)
                    .filter((t) => t.id === idproduit)
                    .map((card, index) => {
                      return (
                        <HomeCard1
                          Nom={card.name}
                          Prix={card.price}
                          Id={card.id}
                          Stock={card.stock}
                          BoutiqueId={card.seller_id}
                          Ig={card.picture1}
                          Totalsold={card.total_sold}
                          quantifiable_product={card.quantifiable_product}
                          Panier={panier}
                        />
                      );
                    })}
                </>
              );
            })}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center mt-80">
          <div class="loader">
            <div class="cell d-0"></div>
            <div class="cell d-1"></div>
            <div class="cell d-2"></div>

            <div class="cell d-1"></div>
            <div class="cell d-2"></div>

            <div class="cell d-2"></div>
            <div class="cell d-3"></div>

            <div class="cell d-3"></div>
            <div class="cell d-4"></div>
          </div>
          <div className="flex items-center justify-center text-xl mt-10">
            <span>Chargement des données en cours </span>
          </div>
        </div>
      )}

      {/* <HomeCard/> */}
    </div>
  );
};

export default ProductCard;
