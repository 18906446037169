/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, Fragment, useEffect } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
// import { NavLink } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import ShopContent from "./shopCart/ShopContent";
import { dectriggmod, setnumberUser } from "../Feature/DeclencheursSlice";
import { recupProduct, setBoutik } from "../Feature/ProductSlice";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { dec, setnumberclients, vider } from "../Feature/PanierSlice";
import FavoriteList from "./FavoriteList";
import ShopList from "./ShopList";
import { IonButtons, IonIcon, IonItem, IonModal, IonToast } from "@ionic/react";
import { chevronBack, informationCircle } from "ionicons/icons";
import { Modal, Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { BiFilter } from "react-icons/bi";

const Navigation = () => {
  //
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );
  let trigger2 = useSelector((state) => state.triggers.triggermod);
  const [whatsapp, setWhatsapp] = useState("");
  const [messagewhatsapp, setmessageWhatsapp] = useState("");
  // let panier = useSelector((state) => state.panier.panier);
  // let numUser = useSelector((state) => state.triggers.numberUser);
  const boutique = useSelector((state) => state.product.boutique);
  const [numUser, setnumUser] = useState("");
  let commande = "";
  const [reclusia, setRec] = useState([]);
  // const Regex = /^\+229\d{8}$/;
  const Regex = /^\+\d{1,3}\d{8,9}$/;

  const [openMenu, setOpenMenu] = useState(false);
  let panier = useSelector((state) => state.panier.panier);

  let totalprice = useSelector((state) => state.panier.totalPrix);
  let totalqte = useSelector((state) => state.panier.totalQuantite);
  const numberclient = useSelector((state) => state.panier.numberclient);

  const [progress, setprogress] = useState(false);
  const [progress1, setprogress1] = useState(false);
  const [progress2, setprogress2] = useState(false);

  const [showToast9, setShowToast9] = useState(false);

  const [showmodal, setShowmodal] = useState(false);

  const [show, setShow] = useState(false);

  const [totalquant, setTotalquant] = useState(0);
  const [totalprix, setTotalprix] = useState(0);

  const getpan = () => {
    // fetch('https://backendtrader.digitalfirst.space/affichepanier').then((res) => {
    //     const data = res.json()
    //     return data
    // }).then((data) => {
    //     setPanier(data);
    //     setInvoice(data[0].invoice);
    // })
    setTotalquant(
      panier
        .map((e) => e.product_quantity)
        .reduce((prev, curr) => prev + curr, 0)
    );
    setTotalprix(
      panier.map((e) => e.total_price).reduce((prev, curr) => prev + curr, 0)
    );

    for (var i = 0; i < panier.length; i++) {
      commande = [
        ...commande,
        "\n" + panier[i].product_quantity + " " + panier[i].product_name,
      ];
    }
    setmessageWhatsapp(String(commande));
    console.log(String(commande));
  };

  const calc = () => {
    console.log(whatsapp);
    if (whatsapp.match(Regex)) {
      dispatch(setnumberUser(whatsapp));
      dispatch(setnumberclients(whatsapp));
      envoi1();
    } else {
      // setShowToast1(true);
      setprogress(true);
      toast.error("Vueillez configurer le numeros de telephone");
    }
  };

  const envoi1 = () => {
    if (parseInt(panier.length) > 0) {
      // setprogress(true);
      setprogress1(true);
      setprogress(true);
      setprogress(true);
      toast.loading(
        "Opération en cours de traitement....\n\nVeuillez patienter.",
        {
          duration: 6000,
        }
      );
      Axios.post(
        "https://backendtrader.digitalfirst.space/ajoutcomList",
        {}
      ).then((ret) => {
        for (let index = 0; index < panier.length; index++) {
          Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList1", {
            product_quantity: panier[index].product_quantity,
            total_price: panier[index].total_price,
            unite_price: panier[index].unite_price,
            product_name: panier[index].product_name,
            product_id: panier[index].product_id,
            stock: panier[index].stock,
            picture1: panier[index].picture1,
            BoutiqueId: panier[index].BoutiqueId,
            total_sold: panier[index].total_sold,
            quantifiable_product: panier[index].quantifiable_product,
            whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
            invoice: ret.data,
          }).then((rets) => {
            console.log(rets.data, "inserted");
          });
        }
        console.log(ret.data);
        console.log("1ere etape");
        setTimeout(() => {
          envoi(ret.data);
        }, 3000);
      });
    } else {
      // setShowToast3(true);
    }
  };

  const envoi = (ide) => {
    console.log(ide, "facture");
    console.log(totalqte, "quantite total");
    console.log(totalprice, "prix total");
    console.log(JSON.parse(localStorage.getItem("BoutiqueId") + ""));
    Axios.post("https://backendtrader.digitalfirst.space/ajoutcommande", {
      totalquant: totalqte,
      totalprix: totalprice,
      invoice: ide,
      status_paiement:
        panier[0].type_product === "Physique" ? "PAYER" : "NON PAYER",
      whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
      // whatsapp: !numUser ? whatsapp : numUser,
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      if (ret.data === "suc") {
        console.log("commande ajoutée");
        // setShowToast2(true);
        // setprogress(false);
        console.log("2ere etape");
        envoi3(ide);
      } else {
      }
    });
  };

  const envoi3 = (ide) => {
    dispatch(dec(!trigger));
    dispatch(dectriggmod(!trigger2));
    // const whats = `https://wa.me/${
    //   JSON.parse(localStorage.getItem("parrain") + "")
    // }?text=${commande}`;
    dispatch(vider(""));
    setprogress1(false);
    setprogress(false);
    // setprogress(true);
    toast.success("commande effectué avec success");
    setShowmodal(false);
    Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recupProduct(ret.data));
      console.log(ret.data);
      console.log("3ere etape");
      console.log(messagewhatsapp);
    });

    if (panier[0].type_product === "Physique") {
      const whats = `https://wa.me/${numUser}?text=${
        "Commande d'articles via la platform digitalTrader : " + messagewhatsapp
      }`;
      setTimeout(() => {
        {
          numUser !== "" && (window.location.href = whats);
        }
      }, 2000);
    } else if (panier[0].type_product === "Numerique") {
      // setShowToast9(true);
      localStorage.setItem("montant_paiement", String(totalprix));
      localStorage.setItem("invoice_command", String(ide));
      setTimeout(() => {
        window.location.href = "/paiement";
      }, 1000);
    }

    // setShowmodal(false);
  };
  useEffect(() => {
    getpan();
  }, [show]);
  useEffect(() => {
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
    }
  }, [boutique]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("numberclient") + "")) {
      dispatch(
        setnumberclients(JSON.parse(localStorage.getItem("numberclient") + ""))
      );
    }
  }, [(trigger) => {}]);
  /////////////////////////////////////////////////////////////////////////////

  // const nav = useNavigate();
  //

  const Toggle = () => {
    setOpenMenu(!openMenu);
  };
  const close = () => {
    setOpenMenu(false);
  };
  // const closeAndNav = () => {
  //   setOpenMenu(false);
  //   nav("/ajouter-une-boutique")
  // };

  return (
    <>
      <nav className="navbar">
        {/* logo           */}
        <h2 className="logo" onClick={() => (window.location.href = "/")}>
          Digital Traders
        </h2>
        {/* panel          */}
        <ul className={openMenu ? "panel" : "panel__close"}>
          <li>
            <a
              onClick={close}
              href="/"
              // className={(nav) => (nav.isActive ? "nav-active" : "")}
              className="text-blue_done text-xl mr-10 no-underline"
            >
              Accueil
            </a>
          </li>
          <li className="dropdown">
            <a
              className="dropdown-toggle text-white no-underline"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              Mes favoris
            </a>
            <div
              className="dropdown-menu fav_drop"
              aria-labelledby="dropdownMenuLink"
            >
              <FavoriteList />
            </div>
          </li>
          <li className="dropdown">
            <a
              className="dropdown-toggle text-white no-underline"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              Boutiques
            </a>
            <div
              className="dropdown-menu fav_drop"
              aria-labelledby="dropdownMenuLink"
            >
              <p
                className="flex items-center text-white text-lg mb-4 hover:bg-black cursor-pointer py-2 px-4 rounded-lg"
                onClick={() => (window.location.href = "/addboutique")}
              >
                <AiOutlineUserAdd className="addicon" />
                Ajouter une boutique
              </p>
              <ShopList />
            </div>
          </li>
        </ul>
        {/* hamburger menu */}
        <div className="hamburger" onClick={Toggle}>
          <div
            className={openMenu ? "menu-icon open" : "menu-icon"}
            // onClick={Toggle}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        {/* cart */}
        <div className="cart__wrapper">
          <button
            className="bg-blue-700 px-2 py-2 flex rounded-lg text-xl text-white items-center"
            onClick={() => {
              setShow(true);
            }}
          >
            {panier.length > 0 ? <span>{panier.length}</span> : null}{" "}
            {/* <BiFilter className="mr-2" /> */}
            Votre commande
          </button>
        </div>
        {/* <div
          className="cart__wrapper"
          onClick={() => {
            setShow(true);
          }}
          // data-bs-toggle="modal"
          // data-bs-target="#exampleModalCenter"
        >
          <AiOutlineShoppingCart className="nav__shop__card bg-blue-700" />
          {panier.length > 0 ? <span>{panier.length}</span> : null}
        </div> */}
      </nav>
      {/* shop cart modal */}

      {/* <!-- Modal medl 2 --> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="shopping__cart__wrapper">
          {progress && (
            <div>
              <Toaster />
            </div>
          )}
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h5 class="modal-title" id="exampleModalLongTitle">
                Votre commande ({panier.length} articles)
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="cart__content">
              <ShopContent />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {progress1 ? (
              <div className="bottom__ctrl">
                <p>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "XOF",
                  }).format(totalprice)}
                </p>
                <div class="spinner"></div>
              </div>
            ) : numberclient === "" ? (
              <div className="bottom__ctrl gap-2">
                <p>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "XOF",
                  }).format(totalprice)}
                </p>
                <div className="flex items-center justify-center gap-2 -mt-3">
                  <div class="mb-0">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Ex: 22969889350"
                      onChange={(e) => {
                        setWhatsapp("+" + e.target.value);
                        console.log("+" + e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="bg-blue-700 hover:bg-blue-600"
                    onClick={() => calc()}
                  >
                    Commander
                  </button>
                </div>
              </div>
            ) : (
              <div className="bottom__ctrl">
                <p>
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "XOF",
                  }).format(totalprice)}
                </p>
                <button
                  className="bg-blue-700 hover:bg-blue-600"
                  onClick={() => envoi1()}
                >
                  Commander
                </button>
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal>

      {/* <IonModal
        isOpen={showmodal}
        onDidDismiss={() => {
          setShowmodal(false);
        }}
      >
        <IonItem className="Item1" lines="none">
          <IonButtons
            slot="start"
            onClick={() => {
              setShowmodal(false);
            }}
          >
            <IonIcon icon={chevronBack} />
          </IonButtons>
          Panier({panier.length} articles)
        </IonItem>
        <div className="cart__content">
          <ShopContent />
        </div>
        <hr />
        <div className="bottom__ctrl">
          <p>{totalprice}FCFA</p>
          <button
            className="bg-blue-700 hover:bg-blue-600"
            onClick={() => envoi1()}
          >
            Commander
          </button>
        </div>
      </IonModal> */}

      {/* <!-- Modal medl 2 --> */}
      {/* <!-- Modal --> */}
      <IonToast
        isOpen={showToast9}
        onDidDismiss={() => setShowToast9(false)}
        message="Commande effectuée avec succes vous serrez redirigez vers un autre page pour le paiement"
        icon={informationCircle}
        position="top"
        duration={3000}
      />
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content shopping__cart__wrapper">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Panier({panier.length} articles)
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="cart__content">
                <ShopContent />
              </div>
            </div>
            <div class="modal-footer">
              <div className="bottom__ctrl">
                <p>{totalprice}FCFA</p>
                <button
                  className="bg-blue-700 hover:bg-blue-600"
                  onClick={() => envoi1()}
                >
                  Commander
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
