/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// import { useState } from "react";
import { Tab } from "@headlessui/react";
import HomeCard from "../mainPage/HomeCard";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useIonRouter } from "@ionic/react";
import { App } from "@capacitor/app";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tab1Section = (props) => {
  //
  const updatedata2 = (newdata) => {
    props.updatedata(newdata);
  };

  const categories = ["Récents", "Top ventes", "Tendances"];
  // const [category, setCategoryList] = useState<>([]);
  let panier = useSelector((state) => state.panier.panier);
  let lien = useSelector((state) => state.panier.lien);
  let dateact = useSelector((state) => state.triggers.dateact);
  const ionRouter = useIonRouter();

  document.addEventListener("ionBackButton", (ev) => {
    ev.detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  });

  let article = []
    .concat(useSelector((state) => state.product.product_bout))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });

  let article1 = []
    .concat(useSelector((state) => state.product.product_bout))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });

  let article2 = []
    .concat(useSelector((state) => state.product.product_bout))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });
  let category = [].concat(useSelector((state) => state.triggers.categories));
  const [dat, setDat] = useState(
    useSelector((state) => state.triggers.dateact)
  );
  const [velk, setVelk] = useState(
    useSelector((state) => state.product.product_bout)
  );
  const [alas, setAlas] = useState(
    useSelector((state) => state.product.product_bout)
  );
  const [disl, setDisl] = useState(false);
  // const [category, setCategoryList] = useState<>([]);
  const [nom, setNom] = useState("ee");
  const [prenom, setPrenom] = useState("rr");
  const [age, setAge] = useState(0);
  const [sexe, setSexe] = useState("rr");
  const [groupee, setGroupee] = useState("rr");
  const [nomCli, setNomCli] = useState([]);
  const [telephone, setTelephone] = useState("rr");
  const [commandeRech, setCommandeRech] = useState("rr");
  const [adresse, setAdresse] = useState("rr");
  const [antecedant, setantecedant] = useState("rr");
  const [datenaissance, setdatenaissance] = useState("zaratras");
  const [id, setId] = useState(0);
  const [zer, setZer] = useState(useSelector((state) => state.panier.panier));
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );
  let dateactu = useSelector((state) => state.triggers.dateact);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [seg, setSeg] = useState("Récents");
  const [seg2, setSeg2] = useState(1);
  const [seg3, setSeg3] = useState("Autres produits");
  const router = useIonRouter();
  const prodRef = useRef();
  const prodRef2 = useRef();

  const getpatient = () => {
    // nomCli.includes
  };

  const getart = () => {
    fetch("https://backendtrader.digitalfirst.space/afficheart")
      .then((res) => {
        const data = res.json();
        return data;
      })
      .then((data) => {
        setVelk(data);
        setAlas(data);
      });
  };

  const getnow = () => {
    fetch("https://backendtrader.digitalfirst.space/datenow")
      .then((res) => {
        const data = res.json();
        return data;
      })
      .then((data) => {
        console.log(data[0]);
      });
  };
  // console.log(Date.parse('2001-10-10'));            // Convertir un string en date (renvoie le nombre de seconde avant 1970)

  const change = (ide) => {
    setSearchText(ide);
    const query = ide.toLowerCase();

    setVelk(
      article.filter((t) => t.name.toLowerCase().includes(ide.toLowerCase()))
    );
    // console.log(alas.filter((t:any) => t.name.toLowerCase().includes(ide.toLowerCase()))[0].name);
    // console.log(article.find((e:any)=>e.name == "zior")); Vérifie si le nom d'un élément == zior et renvoie sa ligne
    // console.log(article.every((e:any)=>e.id < 26));   Vérifie si tous les id sont < à 26 et renvoie un true si oui
  };

  const change2 = (ide) => {
    setSearchText(ide);
    const query = ide.toLowerCase();
    setVelk(
      article.filter((t) => t.name.toLowerCase().includes(ide.toLowerCase()))
    );
    // console.log(alas.filter((t:any) => t.name.toLowerCase().includes(ide.toLowerCase()))[0].name);
    // console.log(article.find((e:any)=>e.name == "zior")); Vérifie si le nom d'un élément == zior et renvoie sa ligne
    // console.log(article.every((e:any)=>e.id < 26));   Vérifie si tous les id sont < à 26 et renvoie un true si oui
  };

  const permu = async (n) => {
    await prodRef.current.classList.add("animate__fadeOutLeft");
    setTimeout(() => {
      prodRef.current.classList.remove("animate__fadeOutLeft");
      prodRef.current.classList.add("animate__fadeInRight");
      setSeg(n);
    }, 100);
  };

  const permu2 = async (n) => {
    await prodRef2.current.classList.add("animate__fadeOutLeft");
    setTimeout(() => {
      prodRef2.current.classList.remove("animate__fadeOutLeft");
      prodRef2.current.classList.add("animate__fadeInRight");
      setSeg2(n);
    }, 100);
  };

  // function doRefresh(event: Event | React.SetStateAction<any>) {
  // Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
  //         id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
  //         // id_boutique: 8
  //       }).then((ret) => {
  //         dispatch(recupProduct(ret.data));
  //         console.log(ret.data);
  //       });
  //   setTimeout(() => {
  //     event.detail.complete();
  //   }, 2000);
  // }

  useEffect(() => {
    // getcat();
    // console.log(category);
    // console.log(article.splice(0, 20));
    console.log(article.splice(0, 20));
    console.log(category);
    // console.log(article);
  }, []);
  //
  return (
    <div className="tab__1__wrapper">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {categories.map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-2">
          {/* ---------------------------Tendances-------------------------------------------- */}

          {/* <Tab.Panel
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            <Swiper
              slidesPerView={5}
              spaceBetween={10}
              freeMode={false}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              <div>
                {article
                  .sort(function (a, b) {
                    var key1 = new Date(a.like_number);
                    var key2 = new Date(b.like_number);
                    if (key1 < key2) {
                      return 1;
                    } else if (key1 == key2) {
                      return 0;
                    } else {
                      return -1;
                    }
                  })
                  .map((card, index) => {
                    return index >= 10 ? null : (
                      <SwiperSlide
                        // className="dril2"
                        key={`slide_${index}`}
                        onClick={() => {
                          // { window.location.href = ` /home/categorie/${card.id} ` };
                        }}
                      >
                        <HomeCard
                          Nom={card.name}
                          Prix={card.price}
                          Id={card.id}
                          Stock={card.stock}
                          BoutiqueId={card.seller_id}
                          Ig={card.picture1}
                          Panier={panier}
                        />
                      </SwiperSlide>
                    );
                  })}
              </div>
            </Swiper>
          </Tab.Panel> */}

          {/* ---------------------------------Récents---------------------------------------------- */}
          <Tab.Panel
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            <Swiper
              slidesPerView={5}
              spaceBetween={10}
              freeMode={false}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              <div>
                {article.map((card, index) => {
                  return index >= 10 ? (
                    <div>
                      <h2>aucun produit trouvé</h2>
                    </div>
                  ) : (
                    <SwiperSlide
                      // className="dril2"
                      key={`slide_${index}`}
                      onClick={() => {
                        // { window.location.href = ` /home/categorie/${card.id} ` };
                      }}
                    >
                      <HomeCard
                        Nom={card.name}
                        Prix={card.price}
                        Id={card.id}
                        Stock={card.stock}
                        BoutiqueId={card.seller_id}
                        Ig={card.picture1}
                        Totalsold={card.total_sold}
                        quantifiable_product={card.quantifiable_product}
                        type_product={card.type_product}
                        Panier={panier}
                        updatedata={updatedata2}
                      />
                    </SwiperSlide>
                  );
                })}
              </div>
              {/* cdlkjvcdklvvkdv */}
              {/* <HomeCard/>
            <HomeCard/> */}
            </Swiper>
          </Tab.Panel>
          {/* ----------------------------------Top ventes------------------------------------------- */}

          <Tab.Panel
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            <Swiper
              slidesPerView={5}
              spaceBetween={10}
              freeMode={false}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              <div>
                {article
                  .sort(function (a, b) {
                    var key1 = new Date(a.total_sold);
                    var key2 = new Date(b.total_sold);
                    if (key1 < key2) {
                      return 1;
                    } else if (key1 == key2) {
                      return 0;
                    } else {
                      return -1;
                    }
                  })
                  .map((card, index) => {
                    return index >= 10 ? (
                      <div>
                        <h2>aucun produit trouvé</h2>
                      </div>
                    ) : (
                      <SwiperSlide
                        // className="dril2"
                        key={`slide_${index}`}
                        onClick={() => {
                          // { window.location.href = ` /home/categorie/${card.id} ` };
                        }}
                      >
                        <HomeCard
                          Nom={card.name}
                          Prix={card.price}
                          Id={card.id}
                          Stock={card.stock}
                          BoutiqueId={card.seller_id}
                          Ig={card.picture1}
                          Totalsold={card.total_sold}
                          quantifiable_product={card.quantifiable_product}
                          type_product={card.type_product}
                          Panier={panier}
                          updatedata={updatedata2}
                        />
                      </SwiperSlide>
                    );
                  })}
              </div>

              {/* <HomeCard/>
            <HomeCard/>
            <HomeCard/> */}
            </Swiper>
          </Tab.Panel>
          <Tab.Panel
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            <Swiper
              slidesPerView={5}
              spaceBetween={10}
              freeMode={false}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              <div>
                {article
                  .sort(function (a, b) {
                    var key1 = new Date(a.like_number);
                    var key2 = new Date(b.like_number);
                    if (key1 < key2) {
                      return 1;
                    } else if (key1 == key2) {
                      return 0;
                    } else {
                      return -1;
                    }
                  })
                  .map((card, index) => {
                    return index >= 10 ? (
                      <div>
                        <h2>aucun produit trouvé</h2>
                      </div>
                    ) : (
                      <SwiperSlide
                        // className="dril2"
                        key={`slide_${index}`}
                        onClick={() => {
                          // { window.location.href = ` /home/categorie/${card.id} ` };
                        }}
                      >
                        <HomeCard
                          Nom={card.name}
                          Prix={card.price}
                          Id={card.id}
                          Stock={card.stock}
                          BoutiqueId={card.seller_id}
                          Ig={card.picture1}
                          Totalsold={card.total_sold}
                          quantifiable_product={card.quantifiable_product}
                          type_product={card.type_product}
                          Panier={panier}
                          updatedata={updatedata2}
                        />
                      </SwiperSlide>
                    );
                  })}
              </div>

              {/* <HomeCard/>
            <HomeCard/>
            <HomeCard/> */}
            </Swiper>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tab1Section;
