import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    product: [],
    product_bout: [],
    product_featured: [],
    boutique: [],
    favoris: [],
    badge_client: 0,
    boutique_encoded: [],
  },
  reducers: {
    recupProduct: (state, { payload }) => {
      if (payload) {
        state.product = payload;
        state.product = state.product.sort(function (a, b) {
          var key1 = new Date(a.creation_date);
          var key2 = new Date(b.creation_date);
          if (key1 < key2) {
            return 1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return -1;
          }
        });
      }
    },
    setfeaturedprod: (state, { payload }) => {
      if (payload) {
        state.product_featured = payload;
        state.product_featured = state.product_featured.sort(function (a, b) {
          var key1 = new Date(a.date_creation);
          var key2 = new Date(b.date_creation);
          if (key1 < key2) {
            return 1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return -1;
          }
        });
      }
    },
    setproduitbout: (state, { payload }) => {
      if (payload) {
        state.product_bout = payload;
        state.product_bout = state.product_bout.sort(function (a, b) {
          var key1 = new Date(a.creation_date);
          var key2 = new Date(b.creation_date);
          if (key1 < key2) {
            return 1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return -1;
          }
        });
      }
    },
    setBoutik: (state, { payload }) => {
      // state.panier.push(action.payload);
      state.boutique = payload;
    },
    createfavoris: (state, action) => {
      state.favoris = action.payload;
      localStorage.setItem("favoris", JSON.stringify(state.favoris));
    },
    setfavoris: (state, action) => {
      state.favoris = [...state.favoris, action.payload];
      localStorage.setItem("favoris", JSON.stringify(state.favoris));
    },
    deletefavoris: (state, { payload }) => {
      state.favoris = JSON.parse(localStorage.getItem("favoris"));
      // state.favoris = state.favoris.filter((t) => t !== payload);
      state.favoris = state.favoris.filter(
        (t) => t[0].boutiqueName !== payload
      );
      localStorage.setItem("favoris", JSON.stringify(state.favoris));
    },
    setBadge: (state, { payload }) => {
      state.badge_client = payload;
      localStorage.setItem("badge_client", payload);
    },
    createboutique_encoded: (state, action) => {
      state.boutique_encoded = action.payload;
      localStorage.setItem(
        "boutique_encoded",
        JSON.stringify(state.boutique_encoded)
      );
    },
    setboutique_encoded: (state, action) => {
      state.boutique_encoded = [...state.boutique_encoded, action.payload];
      localStorage.setItem(
        "boutique_encoded",
        JSON.stringify(state.boutique_encoded)
      );
    },
  },
});

export const {
  recupProduct,
  setfeaturedprod,
  setBoutik,
  setproduitbout,
  createfavoris,
  setfavoris,
  deletefavoris,
  setBadge,
  createboutique_encoded,
  setboutique_encoded,
} = productSlice.actions;
export default productSlice.reducer;
