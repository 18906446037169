/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { MdRemove, MdAddShoppingCart } from "react-icons/md";
import { GrFormAdd } from "react-icons/gr";
import { AiOutlinePlayCircle, AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BsShop, BsTelephone } from "react-icons/bs";
import { IoMdCopy } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import {
  createfavoris,
  deletefavoris,
  recupProduct,
  setBoutik,
  setfavoris,
} from "../../Feature/ProductSlice";
import {
  dec,
  setProductPan,
  updateQuantity,
  setnumberclients,
  vider2,
  setProductPan2,
} from "../../Feature/PanierSlice";
import { Modal, Button, Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { dectriggmod, setnumberUser } from "../../Feature/DeclencheursSlice";
import { IonContent, IonItem, IonModal, IonToast } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import { FiRefreshCw, FiLink } from "react-icons/fi";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  LinkedinIcon,
} from "react-share";

const ProductDetails = () => {
  //
  const [qte, setqte] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [loaded4, setLoaded4] = useState(false);

  const [idant, setIdant] = useState(window.location.pathname.split("/")[2]); // window.location.pathname.split("/")[2])
  const [BoutiqueId, setBoutiqueId] = useState(
    window.location.pathname.split("/")[3]
  );

  const [width, setWidth] = useState(window.innerWidth);
  const [size, setSize] = useState(650);
  //
  // let params = useParams();
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast9, setShowToast9] = useState(false);

  const [article, setArticle] = useState([]);
  const [quantite, setQuantite] = useState(1);
  let panier = useSelector((state) => state.panier.panier);
  const [idcategorie, setIdcategorie] = useState(0);
  const [nom, setNom] = useState();
  const [nomboutik, setNomboutik] = useState();
  const [descboutik, setDescboutik] = useState();
  const [adressboutik, setAdressboutik] = useState();
  const [whatboutik, setWhatboutik] = useState();
  const [imagboutik, setImagboutik] = useState();
  const [codeboutik, setCodeboutik] = useState();
  const [prix, setPrix] = useState();
  const [sexe, setSexe] = useState("");
  const [stock, setStock] = useState(0);
  const [totalsold, settotalsold] = useState(0);
  const [description, setDescription] = useState(0);
  const [picture1, setPicture1] = useState();
  const [picture2, setPicture2] = useState();
  const [picture3, setPicture3] = useState();
  const [picture4, setPicture4] = useState();
  const [quantifiable_product, setquantifiable_product] = useState("");
  const [type_product, settype_product] = useState("");
  const [video, setVideo] = useState();
  let [antecedants, setAntecedants] = useState(" ");
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );
  const [command, setCommand] = useState();
  const [showmodal, setShowmodal] = useState(false);
  const [showmodal1, setShowmodal1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const boutique = useSelector((state) => state.product.boutique);
  const favoris = useSelector((state) => state.product.favoris);
  const [copy, setCopy] = useState(false);
  // const [favoris, setfavoriss] = useState(
  //   JSON.parse(localStorage.getItem("favoris") + "")
  // );
  // const [changestart, setchangestart] = useState(favoris.filter(
  //   // (t: any) => t === codeboutik
  //   (t: any) => t[0].boutiqueName === codeboutik
  // )[0] ? false : true);
  // const [favr, setfavr] = useState();
  const [changestart, setchangestart] = useState(false);

  const [modalShowvid, setModalShowvid] = React.useState(false);
  const [modalShowbout, setModalShowbout] = React.useState(false);
  const [modalShownumber, setModalShownumber] = React.useState(false);
  // const Regex = /^\+229\d{8}$/;
  const Regex = /^\+\d{1,3}\d{8,9}$/;

  let commande = "";
  const numberclient = useSelector((state) => state.panier.numberclient);
  const [numUser, setnumUser] = useState("");
  let trigger2 = useSelector((state) => state.triggers.triggermod);

  const panier2 = useSelector((state) => state.panier.panier2);

  const [progress, setprogress] = useState(false);
  const [progress1, setprogress1] = useState(false);
  const [totalquant, setTotalquant] = useState(0);
  const [totalprix, setTotalprix] = useState(0);
  const [whatsapp, setWhatsapp] = useState("");

  const [modalShow2, setModalShow2] = React.useState(false);

  const getart = () => {
    console.log("ID:", idant);
    console.log("BOUTIQUEID:", BoutiqueId);
    Axios.post("https://backendtrader.digitalfirst.space/recupart", {
      id: idant,
      id_boutique: BoutiqueId,
      // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
    }).then((ret) => {
      console.log("DREAT GREAT");
      setArticle(ret.data);

      setStock(ret.data[0].stock);
      setIdcategorie(ret.data[0].idClinique);
      setNom(ret.data[0].name);
      setPrix(ret.data[0].price);
      setDescription(ret.data[0].description);
      setPicture1(ret.data[0].picture1);
      setPicture2(ret.data[0].picture2);
      setPicture3(ret.data[0].picture3);
      setPicture4(ret.data[0].picture4);
      settotalsold(ret.data[0].total_sold);
      setquantifiable_product(ret.data[0].quantifiable_product);
      settype_product(ret.data[0].type_product);
      setVideo(ret.data[0].video);
      // setStock(ret.data[0].stock);
    });
  };
  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: BoutiqueId,
      }).then((ret) => {
        dispatch(setBoutik(ret.data));
        setNomboutik(ret.data[0].store_name);
        setDescboutik(ret.data[0].description);
        setAdressboutik(ret.data[0].adress);
        setWhatboutik(ret.data[0].whatsapp);
        setImagboutik(ret.data[0].image);
        console.log(ret.data[0].image, "valeur de limage");
        setCodeboutik(ret.data[0].boutiqueName);
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const increm = () => {
    if (quantite < stock) {
      setQuantite(quantite + 1);
    }
  };
  const decrem = () => {
    if (quantite > 1) {
      setQuantite(quantite - 1);
    }
  };

  const putidboutique = (payload) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };
  const UpdateDimension = () => {
    setWidth(window.innerWidth);
  };

  const clipboard = () => {
    setprogress1(true);
    setprogress1(true);
    setTimeout(() => {
      toast.success("Lien copié avec success !", {
        duration: 3000,
      });
    }, 1000);
  };
  const clipboard2 = () => {};

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const calc = () => {
    if (quantifiable_product === "oui") {
      if (stock > 0) {
        console.log(whatsapp);
        if (whatsapp.match(Regex)) {
          // dispatch(setnumberUser(whatsapp));
          dispatch(setnumberclients(whatsapp));
          ajout();
        } else {
          // setShowToast1(true);
          setprogress1(true);
          toast.error(
            "Vueillez configurer le numeros de telephone au bon format pour passer la commande "
          );
        }
      } else if (stock <= 0) {
        setShowToast1(true);
      }
    } else if (quantifiable_product === "non") {
      console.log(whatsapp);
      if (whatsapp.match(Regex)) {
        // dispatch(setnumberUser(whatsapp));
        dispatch(setnumberclients(whatsapp));
        ajout();
      } else {
        // setShowToast1(true);
        setprogress1(true);
        toast.error(
          "Vueillez configurer le numeros de telephone au bont format pour passer la commande "
        );
      }
    }
  };
  const tunc = () => {
    return new Promise((resolve) => {
      resolve(
        dispatch(
          setProductPan2({
            product_id: idant,
            product_quantity: parseInt(quantite),
            product_name: nom,
            unite_price: parseInt(prix),
            total_price: parseInt(prix) * parseInt(quantite),
            picture1: picture1,
            Totalsold: totalsold,
            BoutiqueId: BoutiqueId,
            stock: stock,
          })
        )
      );
    });
  };
  const ajout = () => {
    // console.log(panier2,"panier");
    // setTotalquant(
    //   panier2
    //     .map((e) => e.product_quantity)
    //     .reduce((prev, curr) => prev + curr, 0)
    // );
    // setTotalprix(
    //   panier2.map((e) => e.total_price).reduce((prev, curr) => prev + curr, 0)
    // );
    // await tunc();
    console.log(quantifiable_product);
    console.log(type_product);

    if (quantifiable_product === "oui") {
      if (stock > 0) {
        setprogress(true);
        setprogress1(true);
        setTimeout(() => {
          toast.loading(
            "Chargement des données en cours....\n\nVeuillez patienter.",
            {
              duration: 60000,
            }
          );
        }, 1000);
        let pan = [
          {
            product_id: idant,
            product_quantity: parseInt(quantite),
            product_name: nom,
            unite_price: parseInt(prix),
            total_price: parseInt(prix) * parseInt(quantite),
            picture1: picture1,
            Totalsold: totalsold,
            BoutiqueId: BoutiqueId,
            stock: stock,
          },
        ];
        setTotalprix(parseInt(prix) * parseInt(quantite));
        // setTimeout(() => {
        envoi1(
          parseInt(quantite),
          parseInt(prix) * parseInt(quantite),
          parseInt(prix),
          nom,
          idant,
          stock,
          picture1,
          BoutiqueId,
          totalsold,
          quantifiable_product,
          nom + "  x  " + parseInt(quantite)
        );
      } else if (stock <= 0) {
        setShowToast1(true);
      }
    } else if (quantifiable_product === "non") {
      setprogress(true);
      setprogress1(true);
      setTimeout(() => {
        toast.loading(
          "Chargement des données en cours....\n\nVeuillez patienter.",
          {
            duration: 60000,
          }
        );
      }, 1000);
      let pan = [
        {
          product_id: idant,
          product_quantity: parseInt(quantite),
          product_name: nom,
          unite_price: parseInt(prix),
          total_price: parseInt(prix) * parseInt(quantite),
          picture1: picture1,
          Totalsold: totalsold,
          BoutiqueId: BoutiqueId,
          stock: stock,
        },
      ];
      setTotalprix(parseInt(prix) * parseInt(quantite));

      // setTimeout(() => {
      envoi1(
        parseInt(quantite),
        parseInt(prix) * parseInt(quantite),
        parseInt(prix),
        nom,
        idant,
        stock,
        picture1,
        BoutiqueId,
        totalsold,
        quantifiable_product,
        String(parseInt(quantite) + " " + nom)
      );
    }

    // }, 10000);
  };

  const envoi1 = (
    product_quantity,
    total_price,
    unite_price,
    product_name,
    product_id,
    stock,
    picture1,
    BoutiqueId,
    total_sold,
    quantifiable_product,
    messagewhat
  ) => {
    // console.log(messagewhat);
    // console.log(panier2, "panier2");
    // console.log(pan, "pan");
    // console.log(parseInt(pan.length),"longeur panier2");
    // if (parseInt(pan.length) > 0) {
    console.log("1ere etpa");
    // setprogress(true);
    Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList2", {
      product_quantity: product_quantity,
      total_price: total_price,
      unite_price: unite_price,
      product_name: product_name,
      product_id: product_id,
      stock: stock,
      picture1: picture1,
      BoutiqueId: BoutiqueId,
      total_sold: total_sold,
      quantifiable_product: quantifiable_product,
      whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
      // whatsapp: numUser === "" ? numberclient : numberclient,
      // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id,
      // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      console.log(ret.data);
      console.log("2ere etpa");
      envoi(ret.data, messagewhat);
    });
    // } else {
    //   setShowToast3(true);
    // }
  };
  const envoi = (ide, messagewhat) => {
    Axios.post(
      "https://backendtrader.digitalfirst.space/total_command_byseller",
      {
        invoice: ide,
      }
    ).then((ret) => {
      console.log("3ere etpa");
      // for (let index = 0; index < data.length; index++) {
      Axios.post("https://backendtrader.digitalfirst.space/ajoutcommande", {
        totalquant: ret.data[0].TOTALQUANTITE,
        totalprix: ret.data[0].TOTALPRICE,
        invoice: ret.data[0].invoice,
        status_paiement: type_product === "Physique" ? "PAYER" : "NON PAYER",
        // whatsapp: numUser === "" ? whatsapp : numUser,
        whatsapp: ret.data[0].whatsapp,
        // whatsapp: !numUser ? whatsapp : numUser,
        id_boutique: ret.data[0].seller_id,
        // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      }).then((ret) => {
        if (ret.data === "suc") {
          console.log("commande ajoutée");
          console.log("4ere etpa");
          setShowToast2(true);
          setprogress(false);
          setTimeout(() => {
            envoi3(messagewhat, ide);
          }, 3000);
        } else {
        }
      });
      // }
    });
  };

  const envoi3 = (messagewhat, ide) => {
    dispatch(dec(!trigger));
    dispatch(dectriggmod(!trigger2));
    // const whats = `https://wa.me/${
    //   JSON.parse(localStorage.getItem("parrain") + "")
    // }?text=${commande}`;
    // setprogress(false);

    dispatch(vider2(""));
    setprogress1(true);
    toast.success("commande effectué avec success");
    Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
      id_boutique: BoutiqueId,
      // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recupProduct(ret.data));
      console.log(ret.data);
      setprogress(false);
    });
    if (type_product === "Physique") {
      const whats = `https://wa.me/${numUser}?text=${
        "Commande d'articles via la platform digitalTrader : " + messagewhat
      }`;
      setTimeout(() => {
        {
          numUser !== "" && (window.location.href = whats);
        }
      }, 2000);
    } else if (type_product === "Numerique") {
      // setShowToast9(true);
      localStorage.setItem("montant_paiement", String(totalprix));
      localStorage.setItem("invoice_command", String(ide));
      setTimeout(() => {
        window.location.href = "/paiement";
      }, 1000);
    }
    // setShowmodal(false);
  };

 
  useEffect(() => {
    profile_full();
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
      dispatch(setnumberUser(boutique[0].whatsapp));
    }
  }, []);
  useEffect(() => {
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
      dispatch(setnumberUser(boutique[0].whatsapp));
    }
  }, [boutique]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("numberclient") + "")) {
      dispatch(
        setnumberclients(JSON.parse(localStorage.getItem("numberclient") + ""))
      );
    }
  }, [(trigger) => {}]);

  useEffect(() => {
    getart();
    profile_full();
    if (JSON.parse(localStorage.getItem("favoris") + "")) {
      dispatch(createfavoris(JSON.parse(localStorage.getItem("favoris") + "")));
      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""));
    } else {
      var tab = [
        [
          {
            store_name: "",
            adress: "",
            description: "",
            website: "",
            facebook: "",
            whatsapp: "",
            boutiqueName: "startzeroh",
            image: "",
            id: "",
          },
        ],
      ];
      dispatch(createfavoris(tab));
      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""));
    }
  }, []);
  useEffect(() => {
    console.log(favoris);
  }, [favoris]);
  useEffect(() => {
    console.log(panier2);
  }, [panier2]);
  useEffect(() => {
    console.log(panier2);
  }, [panier2]);
  //

  ///////////////////////////////////
  window.addEventListener("resize", UpdateDimension);
  //

  return (
    <>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={"Article en rupture de stock"}
        icon={informationCircle}
        position="top"
        duration={4000}
      />
      <IonToast
        isOpen={showToast3}
        onDidDismiss={() => setShowToast3(false)}
        message={"Lien copié avec succes ..."}
        icon={informationCircle}
        position="top"
        duration={2000}
      />
      {progress1 && (
        <div>
          <Toaster />
        </div>
      )}
      <IonModal
        isOpen={modalShow2}
        onDidDismiss={() => {
          setModalShow2(false);
        }}
        initialBreakpoint={0.2}
        breakpoints={[0, 0.25, 0.5, 0.75]}
      >
        <IonContent className="ion-padding">
          <IonItem>
            <span className="">Partagé le produit</span>
          </IonItem>
          <div className="flex mt-4 items-center justify-center gap-3">
            <div className="flex flex-col justify-center items-center w-8 h-8 rounded-full shadow bg-purple-700 text-white text-xl cursor-pointer">
              <CopyToClipboard
                text={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                onCopy={() => {
                  setShowToast3(true);
                  setModalShow2(false);
                }}
              >
                <FiLink className="" />
              </CopyToClipboard>
            </div>
            <FacebookShareButton
              url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
              onClick={() => {
                // setShowToast1(true);
                setModalShow2(false);
                // setDec(!dec);
              }}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <EmailShareButton
              url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
              onClick={() => {
                // setShowToast1(true);
                setModalShow2(false);
                // setDec(!dec);
              }}
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
            <TwitterShareButton
              url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
              onClick={() => {
                // setShowToast1(true);
                setModalShow2(false);
                // setDec(!dec);
              }}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton
              url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
              onClick={() => {
                // setShowToast1(true);
                setModalShow2(false);
                // setDec(!dec);
              }}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            <TelegramShareButton
              url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
              onClick={() => {
                // setShowToast1(true);
                setModalShow2(false);
                // setDec(!dec);
              }}
            >
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
            <LinkedinShareButton
              url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
              onClick={() => {
                // setShowToast1(true);
                setModalShow2(false);
                // setDec(!dec);
              }}
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
        </IonContent>
      </IonModal>
      <div className="detail__wrapper">
        <div className="img_section__wrapper">
          {/* <img src="./images/1.jpg" alt="Product" /> */}
          {/* Swipper section */}
          <Swiper
            pagination={pagination}
            modules={[Pagination]}
            className="mySwiper"
          >
            {picture1 ? (
              <SwiperSlide>
                {loaded ? null : (
                  <img src="./images/loading.gif" className="chargement" />
                )}
                <img
                  src={`https://backendtrader.digitalfirst.space/${picture1}`}
                  style={loaded ? {} : { display: "none" }}
                  onLoad={() => setLoaded(true)}
                  alt="product name"
                />
              </SwiperSlide>
            ) : null}
            {picture2 ? (
              <SwiperSlide>
                {loaded2 ? null : (
                  <img src="./images/loading.gif" className="chargement" />
                )}
                <img
                  src={`https://backendtrader.digitalfirst.space/${picture2}`}
                  style={loaded2 ? {} : { display: "none" }}
                  onLoad={() => setLoaded2(true)}
                  alt="product name"
                />
              </SwiperSlide>
            ) : null}
            {picture3 ? (
              <SwiperSlide>
                {loaded3 ? null : (
                  <img src="./images/loading.gif" className="chargement" />
                )}
                <img
                  src={`https://backendtrader.digitalfirst.space/${picture3}`}
                  style={loaded3 ? {} : { display: "none" }}
                  onLoad={() => setLoaded3(true)}
                  alt="product name"
                />
              </SwiperSlide>
            ) : null}
            {picture4 ? (
              <SwiperSlide>
                {loaded4 ? null : (
                  <img src="./images/loading.gif" className="chargement" />
                )}
                <img
                  src={`https://backendtrader.digitalfirst.space/${picture4}`}
                  style={loaded4 ? {} : { display: "none" }}
                  onLoad={() => setLoaded4(true)}
                  alt="product name"
                />
              </SwiperSlide>
            ) : null}
          </Swiper>
          {/*  */}
        </div>
        {/*  */}
        <div className="details__section__wapper">
          {/* description and actions */}
          <h1 className="product__name">{nom}</h1>
          <h1 className="descrip__title">Description du produit:</h1>
          <p
            className="product__descrip cursor-pointer"
            onClick={() => {
              clipboard2();
            }}
          >
            {description}
            {/* {quantifiable_product} */}
          </p>
          <p className="product__stock">
            Quantité disponible :{" "}
            {quantifiable_product === "oui" ? (
              <span>{stock <= 0 ? "En rupture de stock" : stock}</span>
            ) : (
              <span>Produit non Quantifiable</span>
            )}
          </p>

          <div className="container">
            <p className="product__price">
              Prix: <span>{prix} FCFA</span>{" "}
            </p>

            {progress ? (
              <div className="quantity__wrapper">
                {quantifiable_product === "oui" ? (
                  <div className="qte__wrapper ">
                    <MdRemove className="ctrl__icon" />
                    <input
                      // onChange={controlQte}
                      // min={0}
                      type="number"
                      value={quantite}
                    />
                    <GrFormAdd className="ctrl__icon" />
                  </div>
                ) : (
                  <div className="qte__wrapper ">
                    <MdRemove className="ctrl__icon" />
                    <input
                      // onChange={controlQte}
                      // min={0}
                      type="number"
                      value={quantite}
                    />
                    <GrFormAdd className="ctrl__icon" />
                  </div>
                )}

                <div class="spinner"></div>
              </div>
            ) : numberclient === "" ? (
              <div className="quantity__wrapper -mt-4 gap-3">
                {quantifiable_product === "oui" ? (
                  <div className="qte__wrapper ">
                    <MdRemove className="ctrl__icon" onClick={() => decrem()} />
                    <input
                      // onChange={controlQte}
                      // min={0}
                      type="number"
                      value={quantite}
                    />
                    <GrFormAdd
                      className="ctrl__icon"
                      onClick={() => increm()}
                    />
                  </div>
                ) : (
                  <div className="qte__wrapper ">
                    <MdRemove className="ctrl__icon" />
                    <input
                      // onChange={controlQte}
                      // min={0}
                      type="number"
                      value={quantite}
                    />
                    <GrFormAdd className="ctrl__icon" />
                  </div>
                )}

                <div class="mb-0">
                  <input
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Ex: 22969889350"
                    onChange={(e) => {
                      setWhatsapp("+" + e.target.value);
                      console.log("+" + e.target.value);
                    }}
                  />
                </div>

                <MdAddShoppingCart
                  className="shop__add__icon bg-blue-700"
                  onClick={() => calc()}
                />
              </div>
            ) : (
              <div className="quantity__wrapper">
                {quantifiable_product === "oui" ? (
                  <div className="qte__wrapper ">
                    <MdRemove className="ctrl__icon" onClick={() => decrem()} />
                    <input
                      // onChange={controlQte}
                      // min={0}
                      type="number"
                      value={quantite}
                    />
                    <GrFormAdd
                      className="ctrl__icon"
                      onClick={() => increm()}
                    />
                  </div>
                ) : (
                  <div className="qte__wrapper ">
                    <MdRemove className="ctrl__icon" />
                    <input
                      // onChange={controlQte}
                      // min={0}
                      type="number"
                      value={quantite}
                    />
                    <GrFormAdd className="ctrl__icon" />
                  </div>
                )}
                <MdAddShoppingCart
                  className="shop__add__icon bg-blue-700"
                  onClick={() => ajout()}
                />
              </div>
            )}
            <Modalnumber
              show={modalShownumber}
              onHide={() => setModalShownumber(false)}
              clac={() => calc()}
            />
          </div>

          <div className="buttons__wrapper">
            {video == null ? null : (
              <>
                <button
                  className="bg-blue-700"
                  onClick={() => setModalShowvid(true)}
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModalCenterVid"
                >
                  <AiOutlinePlayCircle className="mr-1 btn_icon" />
                  Voir la video
                </button>
              </>
            )}
            <button
              className="bg-blue-700"
              onClick={() => setModalShowbout(true)}
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModalCenterBtk"
            >
              <BsShop className="mr-1 btn_icon" />
              Voir la boutique
            </button>
            <button
              className="bg-blue-700"
              onClick={() => {
                setModalShow2(true);
              }}
            >
              <IoMdCopy className="mr-1 btn_icon " />
              Copier le lien du produit
            </button>
          </div>
        </div>
      </div>
      {/* Buttons modal section */}

      {video == null ? null : (
        <>
          <Modalvideo
            show={modalShowvid}
            video={video}
            onHide={() => setModalShowvid(false)}
          />
          {/* <div
            class="modal fade"
            id="exampleModalCenterVid"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title text-lg font-bold"
                    id="exampleModalLongTitle"
                  >
                    Video
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <video
                    controls
                    height="200"
                    src={`https://backendtrader.digitalfirst.space/${video}`}
                    className="m-auto w-full h-full"
                  ></video>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
      {/* modal video */}

      {/* end modal video */}
      {/* ---------------------------------------------------------------------- */}
      {/* modal boutique */}
      <Modalboutique
        show={modalShowbout}
        onHide={() => setModalShowbout(false)}
        imagboutik={imagboutik}
        nomboutik={nomboutik}
        descboutik={descboutik}
        putidboutique={() => {
          putidboutique(BoutiqueId);
        }}
        whatboutik={whatboutik}
        putfavoris={() => {
          // if (favoris[0][0].whatsapp === "") {
          //   console.log("vide");
          // } else {
          //   console.log("quelquechose");
          // }
          console.log(favoris);
          // dispatch(setfavoris([]));
          //
          if (
            favoris.filter(
              // (t: any) => t === codeboutik
              (t) => t[0].boutiqueName === codeboutik
            )[0]
          ) {
            console.log("la boutique a deja ete ajoute au favoris");
            dispatch(deletefavoris(codeboutik));
            // setfavr("Mettre en favoris");
          } else {
            console.log("la boutique na pas ete ajoute au favoris");
            dispatch(setfavoris(boutique));
            // setfavr("Retirer des favoris");
            // setfavoriss()
          }
        }}
        favoris={favoris}
        codeboutik={codeboutik}
      />
      {/* <div
        class="modal fade"
        id="exampleModalCenterBtk"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title text-lg font-bold"
                id="exampleModalLongTitle"
              >
                Boutique
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="boutik__modal">
                <div className="card__btk bg-blue-200">
                  <img
                    className="img__btk"
                    src={`https://backendtrader.digitalfirst.space/uploads/${imagboutik}`}
                    alt="boutique"
                  />
                  <span>
                    {nomboutik === "" ? "Aucun nom configurer" : nomboutik}
                  </span>
                  <p className="info__btk">
                    {descboutik === ""
                      ? "Aucune description configurer"
                      : descboutik}
                  </p>
                  <div className="option__btk">
                    <button
                      className="bg-blue-700"
                      onClick={() => {
                        putidboutique(BoutiqueId);
                      }}
                    >
                      Voir les produits
                    </button>
                    <button
                      className="bg-blue-700"
                      onClick={() => {
                        {
                          window.location.href = `https://wa.me/${whatboutik}`;
                        }
                      }}
                    >
                      <BsTelephone className="mr-1 icn__btn" />
                      Contacter le vendeur
                    </button>
                    <button
                      className="bg-blue-700"
                      onClick={
                        () => {
                        // if (favoris[0][0].whatsapp === "") {
                        //   console.log("vide");
                        // } else {
                        //   console.log("quelquechose");
                        // }
                        console.log(favoris);
                        // dispatch(setfavoris([]));
                        //
                        if (
                          favoris.filter(
                            // (t: any) => t === codeboutik
                            (t) => t[0].boutiqueName === codeboutik
                          )[0]
                        ) {
                          console.log(
                            "la boutique a deja ete ajoute au favoris"
                          );
                          dispatch(deletefavoris(codeboutik));
                          // setfavr("Mettre en favoris");
                        } else {
                          console.log(
                            "la boutique na pas ete ajoute au favoris"
                          );
                          dispatch(setfavoris(boutique));
                          // setfavr("Retirer des favoris");
                          // setfavoriss()
                        }
                      }}
                    >
                      {favoris.filter(
                        // (t: any) => t === codeboutik
                        (t) => t[0].boutiqueName === codeboutik
                      )[0] ? (
                        <AiFillStar className="mr-1 icn__btn" />
                      ) : (
                        <AiOutlineStar className="mr-1 icn__btn" />
                      )}
                      {favoris.filter(
                        // (t: any) => t === codeboutik
                        (t) => t[0].boutiqueName === codeboutik
                      )[0]
                        ? "retirer des favoris"
                        : "Mettre en favoris"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* end modal boutique */}

      {/* end */}
      <IonToast
        isOpen={showToast9}
        onDidDismiss={() => setShowToast9(false)}
        message="Commande effectuée avec succes vous serrez redirigez vers un autre page pour le paiement"
        icon={informationCircle}
        position="top"
        duration={3000}
      />
    </>
  );
};

export default ProductDetails;

function Modalnumber(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Configuration du numéros
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Enter le numéros"
          className="h-14"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modalvideo(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 class="modal-title text-lg font-bold" id="exampleModalLongTitle">
            Video
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <video
          controls
          height="200"
          src={`https://backendtrader.digitalfirst.space/${props.video}`}
          className="m-auto w-full h-full"
        ></video>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

function Modalboutique(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 class="modal-title text-lg font-bold" id="exampleModalLongTitle">
            Boutique
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="boutik__modal">
          <div className="card__btk bg-blue-200">
            {props.imagboutik === "" ? (
              <img src="store.png" className="img__btk" alt="favoris" />
            ) : (
              <img
                className="img__btk"
                src={`https://backendtrader.digitalfirst.space/uploads/${props.imagboutik}`}
                alt="boutique"
              />
            )}

            <span
              onClick={() => {
                console.log(props.imagboutik);
              }}
            >
              {props.nomboutik === ""
                ? "Aucun nom configurer"
                : props.nomboutik}
            </span>
            <p className="info__btk">
              {props.descboutik === ""
                ? "Aucune description configurer"
                : props.descboutik}
            </p>
            <div className="option__btk">
              <button className="bg-blue-700" onClick={props.putidboutique}>
                Voir les produits
              </button>
              <button
                className="bg-blue-700"
                onClick={() => {
                  {
                    window.location.href = `https://wa.me/${props.whatboutik}`;
                  }
                }}
              >
                <BsTelephone className="mr-1 icn__btn" />
                Contacter le vendeur
              </button>
              <button className="bg-blue-700" onClick={props.putfavoris}>
                {props.favoris.filter(
                  // (t: any) => t === codeboutik
                  (t) => t[0].boutiqueName === props.codeboutik
                )[0] ? (
                  <AiFillStar className="mr-1 icn__btn" />
                ) : (
                  <AiOutlineStar className="mr-1 icn__btn" />
                )}
                {props.favoris.filter(
                  // (t: any) => t === codeboutik
                  (t) => t[0].boutiqueName === props.codeboutik
                )[0]
                  ? "retirer des favoris"
                  : "Mettre en favoris"}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}
