/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonNote,
  IonPage,
  IonRouterLink,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import Paniermodal from "./Paniermodal";
import {
  addOutline,
  bagOutline,
  cart,
  chevronBack,
  closeCircleOutline,
  informationCircle,
  playCircleOutline,
  remove,
  share,
  shareSharp,
  star,
  starOutline,
} from "ionicons/icons";
import Axios from "axios";
import {
  createfavoris,
  deletefavoris,
  recupProduct,
  setBoutik,
  setfavoris,
} from "../../Feature/ProductSlice";
import {
  dec,
  setnumberclients,
  setProductPan,
  updateQuantity,
  vider2,
} from "../../Feature/PanierSlice";
import "swiper/scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination } from "swiper";
import { useParams } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Navigation from "../Navigation";
import DetailPage from "../../pages/DetailPage";
import toast, { Toaster } from "react-hot-toast";
import { dectriggmod, setnumberUser } from "../../Feature/DeclencheursSlice";
import { FiRefreshCw, FiLink } from "react-icons/fi";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  LinkedinIcon,
} from "react-share";

const Details = () => {
  // let params = useParams();
  const [params, setparams] = useState(
    parseInt(window.location.pathname.split("/")[2])
  );
  const parameter = useParams();
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast4, setShowToast4] = useState(false);
  const [showToast9, setShowToast9] = useState(false);
  const [idant, setIdant] = useState(window.location.pathname.split("/")[2]); // window.location.pathname.split("/")[2])
  const [BoutiqueId, setBoutiqueId] = useState(
    window.location.pathname.split("/")[3]
  ); // window.location.pathname.split("/")[2])
  const [article, setArticle] = useState([]);
  const [quantite, setQuantite] = useState(1);
  let panier = useSelector((state) => state.panier.panier);
  const [idcategorie, setIdcategorie] = useState(0);
  const [nom, setNom] = useState();
  const [nomboutik, setNomboutik] = useState();
  const [descboutik, setDescboutik] = useState();
  const [adressboutik, setAdressboutik] = useState();
  const [whatboutik, setWhatboutik] = useState();
  const [imagboutik, setImagboutik] = useState();
  const [codeboutik, setCodeboutik] = useState();
  const [prix, setPrix] = useState();
  const [sexe, setSexe] = useState("");
  const [stock, setStock] = useState(0);
  const [description, setDescription] = useState(0);
  const [picture1, setPicture1] = useState();
  const [picture2, setPicture2] = useState();
  const [picture3, setPicture3] = useState();
  const [picture4, setPicture4] = useState();
  const [quantifiable_product, setquantifiable_product] = useState("");
  const [type_product, settype_product] = useState("");
  const [video, setVideo] = useState();
  const [, setAdresse] = useState("");
  let [antecedants, setAntecedants] = useState(" ");
  let [date, setdate] = useState("");
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );
  const [command, setCommand] = useState();
  const [showmodal, setShowmodal] = useState(false);
  const [showmodal1, setShowmodal1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const boutique = useSelector((state) => state.product.boutique);
  const favoris = useSelector((state) => state.product.favoris);
  const [copy, setCopy] = useState(false);
  // const [favoris, setfavoriss] = useState(
  //   JSON.parse(localStorage.getItem("favoris") + "")
  // );
  // const [changestart, setchangestart] = useState(favoris.filter(
  //   // (t: any) => t === codeboutik
  //   (t: any) => t[0].boutiqueName === codeboutik
  // )[0] ? false : true);
  // const [favr, setfavr] = useState();
  const [changestart, setchangestart] = useState(false);

  const [width, setWindowWidth] = useState(window.innerWidth);

  // const Regex = /^\+229\d{8}$/;
  const Regex = /^\+\d{1,3}\d{8,9}$/;

  let commande = "";
  const numberclient = useSelector((state) => state.panier.numberclient);
  const [numUser, setnumUser] = useState("");
  let trigger2 = useSelector((state) => state.triggers.triggermod);

  const panier2 = useSelector((state) => state.panier.panier2);

  const [progress, setprogress] = useState(false);
  const [progress1, setprogress1] = useState(false);
  const [totalquant, setTotalquant] = useState(0);
  const [totalprix, setTotalprix] = useState(0);
  let totalprix1 = 0;
  const [whatsapp, setWhatsapp] = useState("");
  const [totalsold, settotalsold] = useState(0);

  const [modalShow2, setModalShow2] = React.useState(false);
  const [mobile, setmobile] = React.useState(false);

  const updateDimensions = () => {
    // const width = window.innerWidth;
    setWindowWidth(window.innerWidth);
  };

  const getart = () => {
    Axios.post("https://backendtrader.digitalfirst.space/recupart", {
      id: idant,
      id_boutique: BoutiqueId,
      // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
    }).then((ret) => {
      setArticle(ret.data);

      setStock(ret.data[0].stock);
      setIdcategorie(ret.data[0].idClinique);
      setNom(ret.data[0].name);
      setPrix(ret.data[0].price);
      setDescription(ret.data[0].description);
      setPicture1(ret.data[0].picture1);
      setPicture2(ret.data[0].picture2);
      setPicture3(ret.data[0].picture3);
      setPicture4(ret.data[0].picture4);
      settotalsold(ret.data[0].total_sold);
      setVideo(ret.data[0].video);
      setStock(ret.data[0].stock);
      setquantifiable_product(ret.data[0].quantifiable_product);
      settype_product(ret.data[0].type_product);
    });
  };
  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: BoutiqueId,
      }).then((ret) => {
        dispatch(setBoutik(ret.data));
        setNomboutik(ret.data[0].store_name);
        setDescboutik(ret.data[0].description);
        setAdressboutik(ret.data[0].adress);
        setWhatboutik(ret.data[0].whatsapp);
        setImagboutik(ret.data[0].image);
        setCodeboutik(ret.data[0].boutiqueName);
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const increm = () => {
    if (quantite < stock) {
      setQuantite(quantite + 1);
    }
  };
  const decrem = () => {
    if (quantite > 1) {
      setQuantite(quantite - 1);
    }
  };
  // const ajout = () => {
  //   if (panier.filter((t) => t.product_id == idant)[0]) {
  //     // setAjoute((panier.filter((t:any)=>t.product_id== Id)[0]).product_quantity);
  //     dispatch(
  //       updateQuantity([
  //         parseInt(quantite),
  //         idant,
  //         parseInt(prix) * parseInt(quantite),
  //       ])
  //     );
  //     dispatch(dec(!trigger));
  //     setShowToast2(true);
  //   } else {
  //     dispatch(
  //       setProductPan({
  //         product_id: idant,
  //         product_quantity: parseInt(quantite),
  //         product_name: nom,
  //         unite_price: parseInt(prix),
  //         total_price: parseInt(prix) * parseInt(quantite),
  //         picture1: picture1,
  //         stock: stock,
  //       })
  //     );
  //     setShowToast1(true);
  //     dispatch(dec(!trigger));
  //   }

  //   // Axios.post('https://backendtrader.digitalfirst.space/ajoutpanier', {
  //   //         product_quantity: parseInt (quantite),
  //   //         product_name: nom,
  //   //         unite_price: parseInt (prix),
  //   //         total_price:  prix * quantite ,
  //   //         product_id: idant
  //   //     }).then((ret) => {
  //   //         if (ret.data == 'suc') {
  //   //             alert('Element enrégistré');
  //   //         } else {
  //   //             alert('Element non enrégistré');
  //   //         }
  //   //     })
  //   //     console.log(prix);
  // };

  const putidboutique = (payload) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };
  const calc = () => {
    if (quantifiable_product === "oui") {
      if (stock > 0) {
        console.log(whatsapp);
        if (whatsapp.match(Regex)) {
          // dispatch(setnumberUser(whatsapp));
          dispatch(setnumberclients(whatsapp));
          ajout();
        } else {
          // setShowToast1(true);
          setprogress1(true);
          toast.error(
            "Vueillez configurer le numeros de telephone au bon format pour passer la commande "
          );
        }
      } else if (stock <= 0) {
        setShowToast4(true);
      }
    } else if (quantifiable_product === "non") {
      console.log(whatsapp);
      if (whatsapp.match(Regex)) {
        // dispatch(setnumberUser(whatsapp));
        dispatch(setnumberclients(whatsapp));
        ajout();
      } else {
        // setShowToast1(true);
        setprogress1(true);
        toast.error(
          "Vueillez configurer le numeros de telephone au bon format pour passer la commande "
        );
      }
    }
  };

  const ajout = () => {
    console.log(quantifiable_product);
    console.log(type_product);
    if (quantifiable_product === "oui") {
      if (stock > 0) {
        setprogress(true);
        setprogress1(true);
        setTimeout(() => {
          toast.loading(
            "Votre commande est en cours de traitement....\n\nVeuillez patienter.",
            {
              duration: 2000,
            }
          );
        }, 1000);
        let pan = [
          {
            product_id: idant,
            product_quantity: parseInt(quantite),
            product_name: nom,
            unite_price: parseInt(prix),
            total_price: parseInt(prix) * parseInt(quantite),
            picture1: picture1,
            Totalsold: totalsold,
            BoutiqueId: BoutiqueId,
            stock: stock,
          },
        ];
        setTotalprix(parseInt(prix) * parseInt(quantite));
        totalprix1 = parseInt(prix) * parseInt(quantite);
        // setTimeout(() => {
        envoi1(
          parseInt(quantite),
          parseInt(prix) * parseInt(quantite),
          parseInt(prix),
          nom,
          idant,
          stock,
          picture1,
          BoutiqueId,
          totalsold,
          quantifiable_product,
          nom + "  x  " + parseInt(quantite)
        );
      } else if (stock <= 0) {
        setShowToast4(true);
      }
    } else if (quantifiable_product === "non") {
      setprogress(true);
      setprogress1(true);
      setTimeout(() => {
        toast.loading(
          "Votre commande est en cours de traitement....\n\nVeuillez patienter.",
          {
            duration: 2000,
          }
        );
      }, 1000);
      let pan = [
        {
          product_id: idant,
          product_quantity: parseInt(quantite),
          product_name: nom,
          unite_price: parseInt(prix),
          total_price: parseInt(prix) * parseInt(quantite),
          picture1: picture1,
          Totalsold: totalsold,
          BoutiqueId: BoutiqueId,
          stock: stock,
        },
      ];
      setTotalprix(parseInt(prix) * parseInt(quantite));
      totalprix1 = parseInt(prix) * parseInt(quantite);


      // setTimeout(() => {
      envoi1(
        parseInt(quantite),
        parseInt(prix) * parseInt(quantite),
        parseInt(prix),
        nom,
        idant,
        stock,
        picture1,
        BoutiqueId,
        totalsold,
        quantifiable_product,
        String(parseInt(quantite) + nom)
      );
    }

    // }, 10000);
  };

  const envoi1 = (
    product_quantity,
    total_price,
    unite_price,
    product_name,
    product_id,
    stock,
    picture1,
    BoutiqueId,
    total_sold,
    quantifiable_product,
    messagewhat
  ) => {
    // console.log(messagewhat);
    // console.log(panier2, "panier2");
    // console.log(pan, "pan");
    // console.log(parseInt(pan.length),"longeur panier2");
    // if (parseInt(pan.length) > 0) {
    console.log("1ere etpa");
    // setprogress(true);
    Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList2", {
      product_quantity: product_quantity,
      total_price: total_price,
      unite_price: unite_price,
      product_name: product_name,
      product_id: product_id,
      stock: stock,
      picture1: picture1,
      BoutiqueId: BoutiqueId,
      total_sold: total_sold,
      quantifiable_product: quantifiable_product,
      whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
      // whatsapp: numUser === "" ? numberclient : numberclient,
      // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id,
      // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      console.log(ret.data);
      console.log("2ere etpa");
      envoi(ret.data, messagewhat);
    });
    // } else {
    //   setShowToast3(true);
    // }
  };
  const envoi = (ide, messagewhat) => {
    Axios.post(
      "https://backendtrader.digitalfirst.space/total_command_byseller",
      {
        invoice: ide,
      }
    ).then((ret) => {
      console.log("3ere etpa");
      // for (let index = 0; index < data.length; index++) {
      Axios.post("https://backendtrader.digitalfirst.space/ajoutcommande", {
        totalquant: ret.data[0].TOTALQUANTITE,
        totalprix: ret.data[0].TOTALPRICE,
        invoice: ret.data[0].invoice,
        status_paiement: type_product === "Physique" ? "PAYER" : "NON PAYER",
        // whatsapp: numUser === "" ? whatsapp : numUser,
        whatsapp: ret.data[0].whatsapp,
        // whatsapp: !numUser ? whatsapp : numUser,
        id_boutique: ret.data[0].seller_id,
        // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      }).then((ret) => {
        if (ret.data === "suc") {
          console.log("commande ajoutée");
          console.log("4ere etpa");
          setShowToast2(true);
          setprogress(false);
          setTimeout(() => {
            envoi3(messagewhat, ide);
          }, 3000);
        } else {
        }
      });
      // }
    });
  };

  const envoi3 = (messagewhat, ide) => {
    dispatch(dec(!trigger));
    dispatch(dectriggmod(!trigger2));
    // const whats = `https://wa.me/${
    //   JSON.parse(localStorage.getItem("parrain") + "")
    // }?text=${commande}`;
    // setprogress(false);

    dispatch(vider2(""));
    setprogress1(true);
    toast.success("commande effectué avec success");
    Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
      id_boutique: BoutiqueId,
      // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recupProduct(ret.data));
      console.log(ret.data);
      setprogress(false);
    });

    if (type_product === "Physique") {
      const whats = `https://wa.me/${numUser}?text=${
        "Commande d'articles via la platform digitalTrader : " + messagewhat
      }`;
      setTimeout(() => {
        {
          numUser !== "" && (window.location.href = whats);
        }
      }, 2000);
    } else if (type_product === "Numerique") {
      // setShowToast9(true);
      localStorage.setItem("montant_paiement", String(totalprix1));
      localStorage.setItem("invoice_command", String(ide));
      setTimeout(() => {
        window.location.href = "/paiement";
      }, 1000);
    }

    // setShowmodal(false);
  };

  const share = (message) => {
    var options = {
      // message: 'share this', // not supported on some apps (Facebook, Instagram)
      // subject: 'the subject', // fi. for email
      // files: ['', ''], // an array of filenames either locally or remotely
      url: message,
      // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
      // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
      // iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    };
    SocialSharing.shareWithOptions(options);
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      setmobile(true);
    }
  }, []);

  useEffect(() => {
    profile_full();
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
      dispatch(setnumberUser(boutique[0].whatsapp));
    }
  }, []);
  useEffect(() => {
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
      dispatch(setnumberUser(boutique[0].whatsapp));
    }
  }, [boutique]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("numberclient") + "")) {
      dispatch(
        setnumberclients(JSON.parse(localStorage.getItem("numberclient") + ""))
      );
    }
  }, [(trigger) => {}]);

  useEffect(() => {
    if (panier.filter((t) => t.product_id == idant)[0]) {
      // setAjoute((panier.filter((t:any)=>t.product_id== Id)[0]).product_quantity);
      setCommand(true);
    } else {
      // setAjoute(0);
      setCommand(false);
    }
  }, [(trigger) => {}]);

  useEffect(() => {
    getart();
    profile_full();
    if (JSON.parse(localStorage.getItem("favoris") + "")) {
      dispatch(createfavoris(JSON.parse(localStorage.getItem("favoris") + "")));
      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""));
    } else {
      var tab = [
        [
          {
            store_name: "",
            adress: "",
            description: "",
            website: "",
            facebook: "",
            whatsapp: "",
            boutiqueName: "startzeroh",
            image: "",
            id: "",
          },
        ],
      ];
      dispatch(createfavoris(tab));
      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""));
    }
  }, []);
  const clipboard = () => {
    // setCopy(true);
    // setTimeout(() => {
    //   setCopy(false);
    // }, [1000]);
    setShowToast3(true);
  };
  useEffect(() => {
    console.log(favoris);
  }, [favoris]);

  window.addEventListener("resize", updateDimensions);

  if (width < 500) {
    return (
      <>
        <IonPage>
          <IonHeader>
            {progress1 && (
              <div>
                <Toaster />
              </div>
            )}
            <IonToolbar>
              <div className="flex justify-between items-center">
                <IonButtons slot="start">
                  <IonButton onClick={() => putidboutique(BoutiqueId)}>
                    <IonIcon color="medium" icon={chevronBack} />
                  </IonButton>
                </IonButtons>
                <IonTitle className="nereide">Digital trader</IonTitle>

                <IonButtons
                  slot="end"
                  className="mr-4 text-xl cursor-pointer"
                  onClick={() => {
                    window.location.href = `/details/${idant}/${BoutiqueId}`;
                  }}
                >
                  <FiRefreshCw />
                </IonButtons>
              </div>
              {/* <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    window.location.href = `/details/${Id}/${BoutiqueId}`
                  }}
                >
                  <IonIcon color="medium" icon={chevronBack} />
                </IonButton>
              </IonButtons>
              <IonTitle className="nereide">Digital trader</IonTitle> */}
            </IonToolbar>
          </IonHeader>
          {/* <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={5000}
        /> */}
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Vous avez ajouté la boutique avec succèss"
            duration={3000}
            position="top"
          />
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="Boutique non existante"
            duration={3000}
            position="top"
          />
          <IonToast
            isOpen={showToast3}
            onDidDismiss={() => setShowToast3(false)}
            message="Lien copier dans le papier presse avec succès"
            duration={3000}
            position="top"
          />
          <IonToast
            isOpen={showToast4}
            onDidDismiss={() => setShowToast4(false)}
            message={"Article en rupture de stock"}
            icon={informationCircle}
            position="top"
            duration={4000}
          />
          <IonToast
            isOpen={showToast9}
            onDidDismiss={() => setShowToast9(false)}
            message="Commande effectuée avec succes vous serrez redirigez vers un autre page pour le paiement"
            icon={informationCircle}
            position="top"
            duration={3000}
          />

          <IonModal
            isOpen={modalShow2}
            onDidDismiss={() => {
              setModalShow2(false);
            }}
            initialBreakpoint={0.2}
            breakpoints={[0, 0.25, 0.5, 0.75]}
          >
            <IonContent className="ion-padding">
              <IonItem>
                <span className="">Partagé le produit</span>
              </IonItem>
              <div className="flex mt-4 items-center justify-center gap-3">
                <div className="flex flex-col justify-center items-center w-8 h-8 rounded-full shadow bg-purple-700 text-white text-xl cursor-pointer">
                  <CopyToClipboard
                    text={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                    onCopy={() => {
                      setShowToast3(true);
                      setModalShow2(false);
                    }}
                  >
                    <FiLink className="" />
                  </CopyToClipboard>
                </div>
                <FacebookShareButton
                  url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                  onClick={() => {
                    // setShowToast1(true);
                    setModalShow2(false);
                    // setDec(!dec);
                  }}
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <EmailShareButton
                  url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                  onClick={() => {
                    // setShowToast1(true);
                    setModalShow2(false);
                    // setDec(!dec);
                  }}
                >
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
                <TwitterShareButton
                  url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                  onClick={() => {
                    // setShowToast1(true);
                    setModalShow2(false);
                    // setDec(!dec);
                  }}
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                  onClick={() => {
                    // setShowToast1(true);
                    setModalShow2(false);
                    // setDec(!dec);
                  }}
                >
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                  onClick={() => {
                    // setShowToast1(true);
                    setModalShow2(false);
                    // setDec(!dec);
                  }}
                >
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
                <LinkedinShareButton
                  url={`https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}/`}
                  onClick={() => {
                    // setShowToast1(true);
                    setModalShow2(false);
                    // setDec(!dec);
                  }}
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </div>
            </IonContent>
          </IonModal>
          {/* <IonContent>
              <div>
                  <h1>{idant}</h1>
                  <h2>{BoutiqueId}</h2>
              </div>
          </IonContent> */}
          <IonContent fullscreen>
            <IonList lines="full" class="ion-no-margin">
              {copy ? (
                <div class="alert alert-success text-center" role="alert">
                  copier dans le papier press
                </div>
              ) : null}
              <IonToolbar className="ion-text-center Titre1 ">{nom}</IonToolbar>
              <div className="newdiv">
                <Swiper
                  spaceBetween={30}
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                  className=""
                >
                  {picture1 ? (
                    <SwiperSlide>
                      <img
                        src={`https://backendtrader.digitalfirst.space/${picture1}`}
                        alt="card"
                        className="imdet"
                      />
                    </SwiperSlide>
                  ) : null}
                  {picture2 ? (
                    <SwiperSlide>
                      <img
                        src={`https://backendtrader.digitalfirst.space/${picture2}`}
                        alt="card"
                        className="imdet"
                      />
                    </SwiperSlide>
                  ) : null}
                  {picture3 ? (
                    <SwiperSlide>
                      <img
                        src={`https://backendtrader.digitalfirst.space/${picture3}`}
                        alt="card"
                        className="imdet"
                      />
                    </SwiperSlide>
                  ) : null}
                  {picture4 ? (
                    <SwiperSlide>
                      <img
                        src={`https://backendtrader.digitalfirst.space/${picture4}`}
                        alt="card"
                        className="imdet"
                      />
                    </SwiperSlide>
                  ) : null}
                  {/* <SwiperSlide><img src={`https://backendtrader.digitalfirst.space/${picture2}`} alt="card" className="imdet" /></SwiperSlide>
                              <SwiperSlide><img src={`https://backendtrader.digitalfirst.space/${picture3}`} alt="card" className="imdet" /></SwiperSlide>
                              <SwiperSlide><img src={`https://backendtrader.digitalfirst.space/${picture4}`} alt="card" className="imdet" /></SwiperSlide> */}
                </Swiper>
              </div>

              {/* <IonItem className="nereide" lines="none">
                Description 
              </IonItem> */}
              <IonItem lines="none">
                <IonNote className="ion-margin-top nereide">
                  {description}
                </IonNote>
              </IonItem>

              <IonItem className="ion-margin-top nereide" lines="none">
                <IonGrid>
                  <IonRow>
                    Quantité disponible : &nbsp;
                    {quantifiable_product === "oui" ? (
                      <span>{stock <= 0 ? "En rupture de stock" : stock}</span>
                    ) : (
                      <span>Produit non Quantifiable</span>
                    )}
                  </IonRow>
                  {quantifiable_product === "oui" && (
                    <IonRow className="mt-4 flex items-center justify-center">
                      <IonCol className="ion-text-center -ml-10">
                        Quantité :
                      </IonCol>
                      <IonCol size="8" className="gap-4">
                        {/* <IonItem lines="none"> */}
                        <div className="flex gap-4 items-center justify-center">
                          <IonFabButton
                            size="small"
                            color="secondary"
                            onClick={() => {
                              decrem();
                            }}
                          >
                            <IonIcon icon={remove} />
                          </IonFabButton>

                          <IonInput
                            className="pl-3 entrees"
                            value={quantite}
                            max={stock}
                            min={1}
                            step="1"
                            readonly
                          ></IonInput>

                          <IonFabButton
                            size="small"
                            color="secondary"
                            onClick={() => {
                              increm();
                            }}
                          >
                            <IonIcon icon={addOutline} />
                          </IonFabButton>
                        </div>
                        {/* </IonItem> */}
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </IonItem>
              {progress ? (
                <div className="flex justify-end items-end mr-8 mt-3">
                  <div class="spinner"></div>
                </div>
              ) : numberclient === "" ? (
                <div className="flex justify-between items-center mt-5">
                  <div class="ml-4">
                    <input
                      type="text"
                      className="form-control h-12"
                      id="exampleFormControlInput1"
                      placeholder="Ex: 22969889350"
                      onChange={(e) => {
                        setWhatsapp("+" + e.target.value);
                        console.log("+" + e.target.value);
                      }}
                    />
                  </div>
                  <div className="mr-4">
                    <IonButton
                      className=""
                      color="secondary"
                      slot="end"
                      // size="small"
                      onClick={() => {
                        calc();
                      }}
                    >
                      Commander
                      <IonIcon icon={cart} />
                    </IonButton>
                  </div>
                </div>
              ) : (
                <div className="flex justify-end items-end">
                  <IonButton
                    className="mr-8 mt-8"
                    color="secondary"
                    slot="end"
                    // size="small"
                    onClick={() => {
                      ajout();
                    }}
                  >
                    Commander
                    <IonIcon icon={cart} />
                  </IonButton>
                </div>
              )}

              {/* <IonItem lines="none" className="ion-margin"> */}
              <div className="flex items-center bg-white w-full justify-between p-4">
                {/* <IonCol size="5"> */}
                {/* <IonItem lines="none"> */}
                {video == null ? null : (
                  <>
                    {" "}
                    <IonButton
                      className="ion-text-center"
                      color="secondary"
                      onClick={() => {
                        setShowmodal(true);
                        console.log(showmodal);
                      }}
                    >
                      <IonIcon icon={playCircleOutline} /> Voir video
                    </IonButton>
                  </>
                )}

                {/* </IonItem> */}
                {/* </IonCol>
                <IonCol size="5"> */}
                {/* <IonItem lines="none"> */}
                <IonButton
                  color="secondary"
                  className=""
                  onClick={() => {
                    setShowmodal1(true);
                  }}
                >
                  <IonIcon icon={bagOutline} />
                  {"   "} Voir la boutique
                </IonButton>
                {/* </IonItem> */}
                {/* </IonCol> */}

                {/* <div><button type="button" className="btn btn-primary" onClick={() => setShowmodal(true)}> <BsFillPlayCircleFill />Primary</button></div> */}
                {/* <div className="flex items-center text-2xl gap-2">
                <h3 className="ml-4 text-xl nereide">{nomboutik}</h3>
                <IonIcon
                  icon={starOutline}
                  className="text-3xl -mt-1 mr-3"
                  style={{ color: "rgb(61,194,255)" }}
                />
              </div> */}
              </div>

              {/* const whats = `https://backendtrader.digitalfirst.space/details/${numUser}/${numUser}/`; */}

              <div className="ml-4">
                {/* <button className="btn btn-primary py-2 px-3 me-3">
                  Envoyer
                </button> */}
                <IonButton
                  color="secondary"
                  className=""
                  onClick={() => {
                    if (mobile) {
                      share(
                        `https://trader.digitalfirst.space/details/${idant}/${BoutiqueId}`
                      );
                    } else {
                      setModalShow2(true);
                    }
                  }}
                >
                  <IonIcon icon={shareSharp} />
                  {"   "} Copier le lien de partage du produit
                </IonButton>
              </div>

              {/* </IonItem> */}

              <IonModal
                isOpen={showmodal}
                onDidDismiss={() => {
                  setShowmodal(false);
                }}
                id="example-modal"
              >
                <video controls height="200" className="vid">
                  <source
                    src={`https://backendtrader.digitalfirst.space/${video}`}
                  />
                </video>
              </IonModal>
              <IonModal isOpen={showmodal1}>
                <IonHeader>
                  <IonToolbar>
                    <IonTitle>Détail de boutique</IonTitle>
                    <IonButtons slot="end">
                      <IonButton onClick={() => setShowmodal1(false)}>
                        Fermer
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <div className="flex flex-col items-center justify-center">
                    {imagboutik === "" ? (
                      <img
                        src="store.png"
                        alt="card"
                        className="w-36 h-36 object-cover rounded-full mb-4"
                      />
                    ) : (
                      <img
                        src={`https://backendtrader.digitalfirst.space/uploads/${imagboutik}`}
                        alt="card"
                        className="w-36 h-36 object-cover rounded-full mb-4"
                      />
                    )}

                    <div className="flex flex-col items-center justify-start">
                      <h1 className="text-2xl text-black font-semibold">
                        Nom de la boutique{" "}
                      </h1>{" "}
                      {nomboutik === "" ? (
                        <h3 className="text-xl mt-4 mb-4">
                          Aucun nom configurer pour cette boutique
                        </h3>
                      ) : (
                        <h3 className="text-xl mt-4 mb-4">{nomboutik}</h3>
                      )}
                    </div>
                    <div className="flex flex-col items-center justify-start">
                      <h1 className="text-2xl text-black font-semibold">
                        Description{" "}
                      </h1>{" "}
                      {descboutik === "" ? (
                        <h3 className="text-xl mt-4 mb-4">
                          Aucune description configurer pour cette boutique
                        </h3>
                      ) : (
                        <p className="text-xl mt-4 mb-4">{descboutik}</p>
                      )}
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <h1 className="text-2xl text-black font-semibold">
                        Adresse{" "}
                      </h1>{" "}
                      {adressboutik === "" ? (
                        <h3 className="text-xl mt-4 mb-4">
                          Aucune adresse configurer pour cette boutique
                        </h3>
                      ) : (
                        <h3 className="text-xl mt-4 mb-4">{adressboutik}</h3>
                      )}
                    </div>
                    <div className="flex flex-col p-5 m-5">
                      {/* <button type="button" className="btn btn-primary">Primary</button> */}

                      <IonButton
                        color="secondary"
                        className="mb-4"
                        onClick={() => {
                          putidboutique(BoutiqueId);
                        }}
                      >
                        Voir les produits
                      </IonButton>

                      <IonButton
                        color="secondary"
                        className="mb-4"
                        onClick={() => {
                          {
                            window.location.href = `https://wa.me/${whatboutik}`;
                          }
                        }}
                      >
                        contacter le vendeur
                      </IonButton>
                      <IonButton
                        color="secondary"
                        className="mb-4"
                        onClick={() => {
                          // if (favoris[0][0].whatsapp === "") {
                          //   console.log("vide");
                          // } else {
                          //   console.log("quelquechose");
                          // }
                          console.log(favoris);
                          // dispatch(setfavoris([]));
                          //
                          if (
                            favoris.filter(
                              // (t: any) => t === codeboutik
                              (t) => t[0].boutiqueName === codeboutik
                            )[0]
                          ) {
                            console.log(
                              "la boutique a deja ete ajoute au favoris"
                            );
                            dispatch(deletefavoris(codeboutik));
                            // setfavr("Mettre en favoris");
                          } else {
                            console.log(
                              "la boutique na pas ete ajoute au favoris"
                            );
                            dispatch(setfavoris(boutique));
                            // setfavr("Retirer des favoris");
                            // setfavoriss()
                          }

                          //     .map((fav: any, index: any) => {
                          //  if(fav[0].whatsapp){
                          //   console.log( fav[0].whatsapp);
                          //  }else{
                          //   console.log("la boutique na pas ete ajoute au favoris");

                          //  }
                          //   })}
                          // console.log(codeboutik);
                          // dispatch(setfavoris(boutique));
                          // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""))
                        }}
                      >
                        {favoris.filter(
                          // (t: any) => t === codeboutik
                          (t) => t[0].boutiqueName === codeboutik
                        )[0] ? (
                          <IonIcon
                            icon={starOutline}
                            className="text-3xl -mt-1 mr-3"
                            style={{ color: "rgb(255,255,255)" }}
                          />
                        ) : (
                          <IonIcon
                            icon={star}
                            className="text-3xl -mt-1 mr-3"
                            style={{ color: "rgb(255,255,255)" }}
                          />
                        )}
                        {favoris.filter(
                          // (t: any) => t === codeboutik
                          (t) => t[0].boutiqueName === codeboutik
                        )[0]
                          ? "retirer des favoris"
                          : "Mettre en favoris"}
                      </IonButton>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message="Elément ajouté au panier"
                icon={informationCircle}
                position="top"
                duration={800}
              />
              {/* <IonToast
                isOpen={showToast2}
                onDidDismiss={() => setShowToast2(false)}
                message="Quantité mise à jour"
                icon={informationCircle}
                position="top"
                duration={800}
              /> */}
              <div className="aaa"></div>
            </IonList>
          </IonContent>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage>
          <IonHeader>
            <Navigation />
          </IonHeader>
          <IonContent fullscreen className="alice">
            <DetailPage />
          </IonContent>
        </IonPage>

        {/* kdjkdk */}
      </>
    );
  }
};

export default Details;
