import { createSlice } from "@reduxjs/toolkit";

export const panierSlice = createSlice({
  name: "panier",
  initialState: {
    panier: [],
    panier2: [],
    produitNumerique: [],
    listeproduitNumerique: [],
    totalPrix: 0,
    totalQuantite: 0,
    trigg: false,
    numberclient: "",
    transactionId: "",
  },

  reducers: {
    recupPan: (state, { payload }) => {
      if (payload) {
        state.panier = payload;
      }
    },
    recuptransactionId: (state, { payload }) => {
      if (payload) {
        state.transactionId = payload;
      }
    },
    recupPan2: (state, { payload }) => {
      if (payload) {
        state.panier2 = payload;
      }
    },
    recupproduitNumerique: (state, { payload }) => {
      if (payload) {
        state.produitNumerique = payload;
      }
    },
    recuplisteproduitNumerique: (state, { payload }) => {
      if (payload) {
        state.listeproduitNumerique = payload;
      }
    },
    setProductPan: (state, action) => {
      state.panier = [...state.panier, action.payload];
      localStorage.setItem("panier", JSON.stringify(state.panier));
    },
    setProductPan2: (state, action) => {
      state.panier2 = [action.payload];
      // state.panier2 = [...state.panier2, action.payload];
      localStorage.setItem("panier2", JSON.stringify(state.panier2));
    },
    updateQuantity: (state, { payload }) => {
      state.panier = state.panier.map((val) => {
        if (val.product_id === payload[1]) {
          return {
            ...val,
            product_quantity: payload[0],
            total_price: payload[2],
          };
        } else {
          return val;
        }
      });
      localStorage.setItem("panier", JSON.stringify(state.panier));
    },
    deleteProduct: (state, { payload }) => {
      state.panier = state.panier.filter((t) => t.product_id !== payload);
      localStorage.setItem("panier", JSON.stringify(state.panier));
    },
    dec: (state, { payload }) => {
      // if (payload) {
      // }
      state.trigg = payload;
    },
    vider: (state, { payload }) => {
      state.panier = [];
      localStorage.setItem("panier", JSON.stringify(state.panier));
    },
    vider2: (state, { payload }) => {
      state.panier2 = [];
      localStorage.setItem("panier2", JSON.stringify(state.panier2));
    },
    setnumberclients: (state, { payload }) => {
      state.numberclient = payload;
      localStorage.setItem("numberclient", JSON.stringify(state.numberclient));
    },
    setTotalPrix: (state, { payload }) => {
      state.totalPrix = payload;
    },
    setTotalQuantite: (state, { payload }) => {
      state.totalQuantite = payload;
    },
  },
});

export const {
  setProductPan,
  setProductPan2,
  recupPan,
  recupPan2,
  updateQuantity,
  deleteProduct,
  dec,
  vider,
  vider2,
  setnumberclients,
  setTotalPrix,
  setTotalQuantite,
  recupproduitNumerique,
  recuplisteproduitNumerique,
  recuptransactionId,
} = panierSlice.actions;
export default panierSlice.reducer;
