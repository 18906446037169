import React from 'react';

const BottomForm = () => {
    return (
        <div className='bottom__form__wrapper'>
            <h1>Vous ne trouvez pas votre produit?</h1>
            <input type="text" placeholder='Ecrivez ce que vous recherchez' />
            <button className='bg-blue-700'>
                Commander
            </button>
        </div>
    );
};

export default BottomForm;