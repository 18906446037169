import React from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

const FavoriteList = () => {
  //
  // const navig = useNavigate();
  const favoris = useSelector((state) => state.product.favoris);
  const selectboutique = (payload) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };
  //
  return (
    <>
      {favoris.filter((t) => t[0].boutiqueName !== "startzeroh")[0] ? (
        <>
          {favoris
            .filter((t) => t[0].boutiqueName !== "startzeroh")
            .map((bout, index) => {
              return (
                <>
                  <div
                    className="dropdown-item favorite__box"
                    // onClick={() => navig("/boutique")}
                    onClick={() => {
                      // dispatch(setBadge(bout[0].id));
                      // console.log(bout.id);
                      selectboutique(bout[0].id);
                    }}
                  >
                    {bout[0].image === "" ? (
                      <img src="store.png" alt="favoris" />
                    ) : (
                      <img
                        src={`https://backendtrader.digitalfirst.space/uploads/${bout[0].image}`}
                        alt="favoris"
                      />
                    )}
                    <span>
                      {bout[0].store_name === ""
                        ? bout[0].boutiqueName
                        : bout[0].store_name}
                    </span>
                  </div>
                </>
              );
            })}
        </>
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <h2 className="text-lg text-white">Aucun favoris</h2>
          </div>
        </>
      )}
    </>
  );
};

export default FavoriteList;
