/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { MdAddShoppingCart, MdRemove, MdFavorite } from "react-icons/md";
import { GrFormAdd } from "react-icons/gr";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import {
  deleteProduct,
  setProductPan,
  updateQuantity,
  dec,
} from "../../Feature/PanierSlice";
import {
  decdateact,
  setcategories,
  setlike_list,
  deletelike_list,
} from "../../Feature/DeclencheursSlice";

const HomeCard1 = ({
  Nom,
  Prix,
  Id,
  Stock,
  BoutiqueId,
  Ig,
  Totalsold,
  quantifiable_product,
}) => {
  //

  const [clic, setClic] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  // const [panier, setPanier] = useState<any[]>(useSelector((state) => state.panier.panier));
  let panier = useSelector((state) => state.panier.panier);
  let article = [].concat(useSelector((state) => state.product.product));
  // const [totalsold, setTotalsold] = useState(
  //   useSelector((state) => state.product.product).filter((t) => t.id == Id)
  //     .total_sold
  // );
  const [nom, setNom] = useState(Nom);
  const [achatv, setAchatv] = useState(false);
  const [quantite, setQuantite] = useState(1);
  const [prix, setPrix] = useState(Prix);
  const [command, setCommand] = useState();
  const [ajoute, setAjoute] = useState();
  const [telephone, setTelephone] = useState("rr");
  const [remarque, setRemarque] = useState("rr");
  const [adresse, setAdresse] = useState("rr");
  const [antecedant, setantecedant] = useState("rr");
  const [datenaissance, setdatenaissance] = useState("rr");
  const [chargeimg, setChargeImg] = useState(false);
  const [id, setId] = useState(0);
  const [trigger, setTrigger] = useState(
    useSelector((state) => state.panier.trigg)
  );
  const likeList = useSelector((state) => state.triggers.like_list);
  const dispatch = useDispatch();
  const [chargelike, setchargelike] = useState(false);
  var myImage = document.querySelector(".imga");

  // const nav = useNa
  // var downloadingImage = new Image();
  // downloadingImage.onload = function(){
  //     myImage.src = "images/loading.gif";
  // };
  // downloadingImage.src =`https://backendtrader.digitalfirst.space/${Ig}`

  // const trigger= useSelector((state) => state.panier.trigg);

  // const [showLoading, setShowLoading] = useState(true);

  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [showToast5, setShowToast5] = useState(false);

  const [nomboutik, setNomboutik] = useState();
  const [descboutik, setDescboutik] = useState();
  const [adressboutik, setAdressboutik] = useState();
  const [paysboutik, setPaysboutik] = useState();
  const [whatboutik, setWhatboutik] = useState();
  const [imagboutik, setImagboutik] = useState();
  const [codeboutik, setCodeboutik] = useState();

  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: BoutiqueId,
      }).then((ret) => {
        // dispatch(setBoutik(ret.data));
        setNomboutik(ret.data[0].store_name);
        setDescboutik(ret.data[0].description);
        setAdressboutik(ret.data[0].adress);
        setWhatboutik(ret.data[0].whatsapp);
        setImagboutik(ret.data[0].image);
        setCodeboutik(ret.data[0].boutiqueName);
        setPaysboutik(ret.data[0].pays);
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const majlike = (n) => {
    Axios.put("https://backendtrader.digitalfirst.space/majlike", {
      id: Id,
      nblike: article.find((e) => e.id == Id).like_number,
      addlike: n,
      id_boutique: article.find((e) => e.id == Id).seller_id,
    }).then((ret) => {
      if (ret.data == "success") {
        console.log("like ajouté");
      } else {
      }
    });
  };

  // setTimeout(() => {
  //     setShowLoading(false);
  // }, 10000);
  const declike = () => {
    fetch("https://backendtrader.digitalfirst.space/obtimg")
      .then((res) => {
        const data = res;
        return data;
      })
      .then((data) => {
        console.log(data);
        // setCategoryList(data);
      });
  };

  const increm = () => {
    if (quantite < Stock) {
      if (Stock > 0) {
        setQuantite(quantite + 1);
      }
    }
  };
  const decrem = () => {
    if (quantite > 0) {
      if (Stock > 0) {
        setQuantite(quantite - 1);
      }
    }
  };
  const recherche = (ide) => {
    if (ide) {
      setCommand(true);
    } else {
      setCommand(false);
    }
  };

  const getpan = () => {
    if (panier.filter((t) => t.product_id == Id)[0]) {
      setAjoute(panier.filter((t) => t.product_id == Id)[0].product_quantity);
      // setTotalsold(
      //   article.filter(
      //     (t) => t.id == Id
      //   ).total_sold
      // );
      setCommand(true);
    } else {
      setAjoute(0);
      setCommand(false);
    }

    // if(ajoute){
    //     setCommand(true);
    // }else{
    //     setCommand(false);
    // }

    // else {
    //     fetch('https://backendtrader.digitalfirst.space/affichepanier').then((res) => {
    //         const data = res.json()
    //         return data
    //     }).then((data) => {
    //         setPanier(data);
    //         setClic(false);
    //         for (var i = 0, len = data.length, a = 0; i < len; i++) {
    //             if (data[i].product_id == Id) {
    //                 setCommand(true);
    //                 a = a + 1;
    //                 console.log(data[i].product_quantity);
    //                 setAjoute(data[i].product_quantity);
    //             }
    //             if (a == 0 && i == (len - 1)) {
    //                 setCommand(false)
    //             }
    //         }
    //     })
    // }
  };

  const convertimg = async () => {
    // console.log("rr");
    var myImage = document.querySelector(".imga");
    fetch(`https://backendtrader.digitalfirst.space/${Ig}`).then((res) => {
      console.log(res);
    });
  };

  const suppression = (ide) => {
    // Axios.delete(`https://backendtrader.digitalfirst.space/deletepan/${ide}`);
    // setCommand(false);
    // setAjoute(quantite);
    // setClic(true);
    dispatch(deleteProduct(ide));
    dispatch(dec(!trigger));
    setCommand(false);
    setShowToast3(true);
  };

  const change = () => {
    if (command) {
      setQuantite(ajoute);
    }
    setAchatv(true);
  };

  const ajout = () => {
    if (Stock > 0) {
      setClic(true);
      if (ajoute) {
        if (quantite == 0) {
          // suppression(Id);
          dispatch(deleteProduct(Id));
          dispatch(dec(!trigger));
          // setCommand(false);
          setShowToast3(true);
          // setAjoute(0);
        } else {
          setAjoute(quantite);
          setCommand(true);

          dispatch(
            updateQuantity([parseInt(quantite), Id, Prix * parseInt(quantite)])
          );
          dispatch(dec(!trigger));
          setShowToast2(true);
          setQuantite(1);
        }
      } else {
        setAjoute(quantite);
        setCommand(true);
        if (!panier.find((e) => e.product_id == Id) && quantite > 0) {
          dispatch(
            setProductPan({
              product_id: Id,
              product_quantity: parseInt(quantite),
              product_name: nom,
              unite_price: parseInt(prix),
              total_price: Prix * parseInt(quantite),
              picture1: Ig,
              stock: Stock,
              total_sold: Totalsold,
              BoutiqueId: BoutiqueId,
            })
          );
          dispatch(dec(!trigger));
          setShowToast1(true);
          setQuantite(1);
        }
      }
    } else if (Stock <= 0) {
      setShowToast5(true);
    }
  };

  useEffect(() => {
    getpan();
    profile_full();

    // if((panier.find((e:any)=>e.product_id==Id))){
    //     setAjoute((panier.find((e:any)=>e.product_id==Id)).product_quantity);
    //     console.log('dia'); }

    // console.log('dia');
  }, [(trigger) => {}]);

  ////////////////////////////////////////////////////////////////////////////
  const [qte, setqte] = useState(1);
  // const nav = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const putidboutique = (payload) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  const addQte = () => {
    setqte(qte + 1);
  };
  const removeQte = () => {
    setqte(qte - 1);
  };
  function controlQte(e) {
    if (e.target.value < 0) {
      setqte(0);
    }
    if (e.target.value >= 20) {
      setqte(20);
    } else {
      setqte(e.target.value);
    }
  }
  //
  return (
    <div className="Product__cards  bg-white">
      {likeList.includes(Id) ? (
        <div className="like__wapper ">
          <MdFavorite
            className="like__icon"
            onClick={() => {
              dispatch(deletelike_list(Id));
              majlike(0);
            }}
          />
        </div>
      ) : (
        <div className="like__wapper ">
          <AiOutlineHeart
            className="like__icon"
            onClick={() => {
              dispatch(setlike_list(Id));
              majlike(1);
            }}
          />
        </div>
      )}
      {command ? (
        <div className="badge__wapper ">
          <span>{ajoute}</span>
        </div>
      ) : null}

      <div
        className="card__img__wapper"
        onClick={() => (window.location.href = `/details/${Id}/${BoutiqueId}`)}
      >
        {loaded ? null : (
          <img src="./images/loading.gif" className="chargement" />
        )}
        <img
          src={`https://backendtrader.digitalfirst.space/${Ig}`}
          style={loaded ? {} : { display: "none" }}
          onLoad={() => setLoaded(true)}
          alt="product name"
        />
      </div>
      <div className="card__info__wrapper">
        <p className="product__name">{Nom}</p>
        <div className="price__stock">
          <p>
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "XOF",
            }).format(Prix)}
          </p>
          <p>Stock:{quantifiable_product === "oui" ? Stock : " null"} </p>
        </div>
      </div>
      <div className="btn__wrapper">
        {achatv ? (
          <>
            <div className="container">
              <div className="qte__wrapper ">
                <div className="qte__select">
                  <MdRemove className="ctrl__icon" onClick={() => decrem()} />
                  <input
                    // min={0}
                    type="number"
                    value={quantite}
                  />
                  <GrFormAdd className="ctrl__icon" onClick={() => increm()} />
                </div>
              </div>
              <MdAddShoppingCart
                className="shop__add__icon bg-blue-700 ml-2"
                onClick={() => {
                  ajout();
                  setAchatv(false);
                }}
              />
            </div>
          </>
        ) : (
          <>
            {/* <button
              className="product__card__btn bg-blue-700"
              onClick={() => {
                putidboutique(BoutiqueId);
              }}
            >
              Voir la boutique
            </button> */}
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-between text-xl p-2 w-full gap-2">
                <span className="w-1/2">Vendeur: </span>
                <a
                  onClick={() => {
                    putidboutique(BoutiqueId);
                    // console.log(totalsold);
                  }}
                  className="w-1/2 text-xl truncate cursor-pointer"
                >
                  {" "}
                  {nomboutik}
                </a>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="w-[90%] h-8 absolute bottom-2 right-4 pl-2 rounded-xl flex items-center justify-center bg-black_done1 text-base text-white truncate">
        <span className="w-full flex items-center truncate">
          {paysboutik === ""
            ? "Non Configurer"
            : paysboutik + " / " + adressboutik}
        </span>
      </div>
    </div>
  );
};

export default HomeCard1;
