/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import Paniermodal from "./Paniermodal";
import { cart, closeCircleOutline } from "ionicons/icons";
import { Conteneur } from "./conteneur";
import { setBadge } from "../../Feature/ProductSlice";
import Navigation from "../Navigation";
import MainPage from "../../pages/MainPage";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Conteneurs } from "./conteneurs";
import { FiRefreshCw } from "react-icons/fi";
import Axios from "axios";
import { Modal } from "react-bootstrap";

export const calcule_temp = (val) => {
  const y = new Date(val);
  // const x = new Date(ret.data.date_start);
  console.log(JSON.parse(localStorage.getItem("dateActue") + ""));
  const x = new Date(JSON.parse(localStorage.getItem("dateActue") + ""));
  const date1utc = Date.UTC(x.getFullYear(), x.getMonth(), x.getDate());
  const date2utc = Date.UTC(y.getFullYear(), y.getMonth(), y.getDate());
  const dayunit = 1000 * 60 * 60 * 24;
  const numberday = (date2utc - date1utc) / dayunit;
  //const numberday = 8;
  console.log(numberday);
  return numberday;
};

const Populaire = () => {
  const dispatch = useDispatch();
  let panier = useSelector((state) => state.panier.panier);
  const [showmodal, setShowmodal] = useState(false);
  const [sho, setSho] = useState(false);
  const products_featured = useSelector(
    (state) => state.product.product_featured
  );
  const [width, setWindowWidth] = useState(window.innerWidth);

  const [searchText, setSearchText] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const favoris = useSelector((state) => state.product.favoris);
  const boutique_encoded = useSelector(
    (state) => state.product.boutique_encoded
  );

  console.log(products_featured,"produit future");

  // const badge = useSelector((state) => state.product.badge_client);
  let article2 = []
    .concat(useSelector((state) => state.product.product))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });
  const [accorde, setaccorde] = useState(true);
  // const boutiquecompte = useSelector((state) => state.Hash.boutiquecompte);
  const [commandeRech, setCommandeRech] = useState("rr");
  //   let far = useSelector((state) => state.auth.user.username);
  let username = "syunbiose";
  // JSON.parse(localStorage.getItem("store_name") + "") === ""
  //   ? far
  //   : JSON.parse(localStorage.getItem("store_name") + "");

  let numberwhat = "229 xxxxxxxx";
  // JSON.parse(localStorage.getItem("whatsapp") + "") === ""
  //   ? "229 xxxxxxxx"
  //   : JSON.parse(localStorage.getItem("whatsapp") + "");
  const selectboutique = (payload) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  const actu_time = () => {
    try {
      fetch("https://backendtrader.digitalfirst.space/date_time")
        .then((res) => {
          const data = res.json();
          return data;
        })
        .then((data) => {
          console.log(data[0].time_actu.split("T")[0]);
          localStorage.setItem(
            "dateActue",
            JSON.stringify(data[0].time_actu.split("T")[0])
          );
        });
    } catch (e) {}
  };

  const updateDimensions = () => {
    // const width = window.innerWidth;
    setWindowWidth(window.innerWidth);
  };

  document.getElementById("far")?.addEventListener("touchmove", (e) => {
    document.getElementById("far").style.left =
      ((e.changedTouches[0].clientX - 25) / window.innerWidth) * 100 + "%";
    document.getElementById("far").style.top =
      ((e.changedTouches[0].clientY - 25) / window.innerHeight) * 100 + "%";
  });
  const sortir = () => {
    localStorage.removeItem("estDejaLance1");
    setTimeout(() => {
      App.exitApp();
    }, 1000);
  };
  useEffect(() => {
    actu_time();
    if (Capacitor.isNativePlatform()) {
      App.addListener("backButton", () => {
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/home"
        ) {
          sortir();
        }
      });
    }
  });

  // useEffect(() => {
  //   // if (Capacitor.isNativePlatform()) {
  //     const estDejaLance = localStorage.getItem("estDejaLance1");

  //     if (localStorage.getItem("change_version1") === "oui") {
  //       if (localStorage.getItem("status_version1") === "obligatoire") {
  //         window.location.href = "/version";
  //       } else if (localStorage.getItem("status_version1") === "facultative") {
  //         if (!estDejaLance) {
  //           // setAfficherToast(true);
  //           setModalShow(true);
  //           localStorage.setItem("estDejaLance1", "true");
  //         }
  //       }
  //     }
  //   // }
  // }, []);

  window.addEventListener("resize", updateDimensions);

  if (width < 500) {
    return (
      <>
        <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            onClick={() => {
              setModalShow(false);
            }}
          >
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="text-red-800"
            >
              Version Dépassée !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col items-center justify-center">
              <span>
                Accéder à la dernière version de l'application sur ce lien.
              </span>
              {/* <CopyToClipboard
                text={"http://www.benindigital.com"}
                onCopy={() => {
                  setAfficherToast(true);
                }}
              > */}
              <span className="text-blue-800 cursor-pointer ">
                https://versatileskills.space/
              </span>
              {/* </CopyToClipboard> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={() => setModalShow(false)}>Close</Button> */}
          </Modal.Footer>
        </Modal>
        <IonMenu contentId="main-content">
          <div className="w-full h-48 bg-white flex flex-col">
            {/* <div className="flex mt-6 justify-between items-center">
              <img
                src="store.png"
                alt=""
                className="w-24 h-24 rounded-full object-cover"
              /> */}
            {/* {boutiquecompte
                  .filter((t) => t.id === badge)&& boutiquecompte
                  .filter((t) => t.id === badge)
                  .map((bat) => {
                    return (
                      bat.image === "" ?  <img
                      src="store.png"
                      alt=""
                      className="w-24 h-24 rounded-full object-cover"
                    /> :  <img
                      src={ boutiquecompte
                        .filter((t) => t.id === badge) && `https://backendtrader.digitalfirst.space/uploads/${bat.image}`}
                      alt=""
                      className="w-24 h-24 rounded-full object-fill"
                    />
                     
                    );
                  })} */}
            {/* <div className="flex flex-col items-center justify-center m-3">
                <div className="w-10 h-10 items-center justify-center mb-3">
                  <img src="brightness.png" alt="" className="object-cover" />
                </div>{" "}
                <div className="w-10 h-10 rounded-full bg-blue-200 flex items-center justify-center">
                  <img
                    src="save-instagram.png"
                    alt=""
                    className="w-5 h-5 items-center justify-center object-cover"
                  />
                </div>
              </div>
            </div> */}
            <div className="flex justify-center items-center mb-4">
              <h3 className="text-2xl text-black">Menu</h3>
            </div>
            <div>
              <IonAccordionGroup>
                <IonAccordion value="first">
                  <IonItem slot="header" color="light">
                    <IonLabel>Mes Favoris</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <div className="flex flex-col">
                      {favoris.filter(
                        (t) => t[0].boutiqueName !== "startzeroh"
                      )[0] ? (
                        <>
                          {favoris
                            .filter((t) => t[0].boutiqueName !== "startzeroh")
                            .map((bout, index) => {
                              return (
                                <>
                                  <div
                                    className="flex w-full items-center justify-start mb-3 cursor-pointer"
                                    onClick={() => {
                                      // dispatch(setBadge(bout[0].id));
                                      // console.log(bout.id);
                                      selectboutique(bout[0].id);
                                    }}
                                  >
                                    <div className="w-10 h-10">
                                      {bout[0].image === "" ? (
                                        <img
                                          src="store.png"
                                          alt=""
                                          className="w-10 h-10 object-cover rounded-full"
                                        />
                                      ) : (
                                        <img
                                          src={`https://backendtrader.digitalfirst.space/uploads/${bout[0].image}`}
                                          alt=""
                                          className="w-10 h-10 object-cover rounded-full"
                                        />
                                      )}

                                      {/* {badge === bout[0].id ? (
                                      <img
                                        src="correct.png"
                                        alt=""
                                        className="w-4 h-4 object-cover badgecor"
                                      />
                                    ) : null} */}
                                    </div>
                                    <h2 className="text-lg text-gray-900 ml-5">
                                      {bout[0].store_name === ""
                                        ? bout[0].boutiqueName
                                        : bout[0].store_name}
                                    </h2>
                                  </div>
                                </>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          <div className="flex w-full items-center justify-center mb-3 cursor-pointer">
                            <h2 className="text-lg text-gray-900 ml-5">
                              Aucuns favoris
                            </h2>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </IonAccordion>
                <IonAccordion value="third">
                  <IonItem slot="header" color="light">
                    <IonLabel>Mes Boutiques</IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <div className="flex flex-col">
                      {boutique_encoded.filter(
                        (t) => t[0].boutiqueName !== "startzeroh"
                      )[0] ? (
                        <>
                          {boutique_encoded
                            .filter((t) => t[0].boutiqueName !== "startzeroh")
                            .map((bout, index) => {
                              return (
                                <>
                                  <IonMenuToggle>
                                    <div
                                      className="flex w-full items-center justify-start mb-3 cursor-pointer"
                                      onClick={() => {
                                        // dispatch(setBadge(bout[0].id));
                                        // console.log(bout.id);
                                        selectboutique(bout[0].id);
                                      }}
                                    >
                                      <div className="w-10 h-10">
                                        {bout[0].image === "" ? (
                                          <img
                                            src="store.png"
                                            alt=""
                                            className="w-10 h-10 object-cover rounded-full"
                                          />
                                        ) : (
                                          <img
                                            src={`https://backendtrader.digitalfirst.space/uploads/${bout[0].image}`}
                                            alt=""
                                            className="w-10 h-10 object-cover rounded-full"
                                          />
                                        )}

                                        {/* {badge === bout[0].id ? (
                                      <img
                                        src="correct.png"
                                        alt=""
                                        className="w-4 h-4 object-cover badgecor"
                                      />
                                    ) : null} */}
                                      </div>
                                      <h2 className="text-lg text-gray-900 ml-5">
                                        {bout[0].store_name === ""
                                          ? bout[0].boutiqueName
                                          : bout[0].store_name}
                                      </h2>
                                    </div>
                                  </IonMenuToggle>
                                </>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          <div className="flex w-full items-center justify-center mb-3 cursor-pointer">
                            <h2 className="text-lg text-gray-900 ml-5">
                              Aucune boutique configurer
                            </h2>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </IonAccordion>
                <IonMenuToggle>
                  <IonRouterLink
                    routerLink="/addboutique"
                    className="text-black"
                  >
                    <div className="justify-start items-center flex mt-2 ml-1">
                      <img
                        src="add.png"
                        alt=""
                        className="w-7 h-7 object-cover"
                      />
                      <h3 className="text-lg mt-2 ml-4">
                        Ajouter une boutique{" "}
                      </h3>
                    </div>
                  </IonRouterLink>
                </IonMenuToggle>
              </IonAccordionGroup>
            </div>
            {/* <div>
                <div class="bg-white p-2">
                  <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none mb-4">
                      <div className="w-full flex items-center">
                        <div className="w-5 h-5 rounded-full bg-white flex items-center justify-center mr-4">
                          <img
                            src="save-instagram.png"
                            alt=""
                            className="w-6 h-6 items-center justify-center object-cover"
                          />
                        </div>
                        <span className="text-2xl text-black"> Favoris</span>
                      </div>
                      <span class="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height="24"
                          shape-rendering="geometricPrecision"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </summary>
                  </details>
                </div>
                <hr />
                <div class="bg-white p-2">
                  <details class="group">
                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none mb-4">
                      <div className="w-full flex items-center">
                        <img
                          src="store_imo.png"
                          alt=""
                          className="w-8 h-8 items-center justify-center object-cover mr-4"
                        />
  
                        <span className="text-2xl text-black">
                          {" "}
                          Mes Boutiques
                        </span>
                      </div>
                      <span class="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height="24"
                          shape-rendering="geometricPrecision"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </summary>
                    
                  </details>
                </div>
              </div> */}
          </div>
        </IonMenu>
        <IonPage id="main-content">
          <IonHeader>
            <IonToolbar>
              <div className="flex justify-between items-center">
                <IonButtons slot="start">
                  <IonMenuButton color="dark"></IonMenuButton>
                </IonButtons>
                <IonTitle className="nereide">Digital trader</IonTitle>

                <IonButtons
                  slot="end"
                  className="mr-4 text-xl cursor-pointer"
                  onClick={() => {
                    window.location.href = "/home";
                  }}
                >
                  <FiRefreshCw />
                </IonButtons>
              </div>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="alice">
            <div className="homes">
              <IonSearchbar
                mode="ios"
                value={searchText}
                placeholder={"rechercher un produit"}
                className="pl-0"
                animated={true}
                onIonChange={(e) => {
                  setSearchText(e.detail.value);
                  // change(e.detail.value!);
                }}
                onIonFocus={(e) => {
                  // dispatch(setactive_categ(""));
                  // dispatch(setactive_tendance(""));
                  // setNub(10);
                }}
              ></IonSearchbar>

              <IonRow></IonRow>
              <div className="flex flex-col mb-0 pl-5 LKJ ">
                  <div className="flex mt-6">
                    <span className="text-3xl">
                     NOS PRODUITS POPULAIRE
                    </span>
                  </div>
                  {/* <div className="flex">
                    <span className="text-3xl">
                      POPULAR
                    </span>
                  </div> */}
                </div>

              {article2[0] && products_featured[0] ? (
                <IonGrid className="grid1">
                  <IonRow>
                    {article2.filter((t) =>
                      t.name.toLowerCase().includes(searchText.toLowerCase())
                    )[0] ? (
                      products_featured
                        .filter(
                          (t) =>
                            calcule_temp(t.date_fin) > 0 && t.status === "Actif"
                        )
                        // .filter((t) => t.status === "Actif")
                        .map((feature, index) => {
                          let idproduit = feature.id_product;
                          return (
                            <>
                              {article2
                                // .slice(0, 10)
                                .filter(
                                  (t) =>
                                    t.id === idproduit &&
                                    t.name
                                      .toLowerCase()
                                      .includes(searchText.toLowerCase())
                                )
                                .map((card, index) => {
                                  return (
                                    <IonCol key={index} className="dril">
                                      <Conteneurs
                                        Nom={card.name}
                                        Prix={card.price}
                                        Id={card.id}
                                        Stock={card.stock}
                                        BoutiqueId={card.seller_id}
                                        Ig={card.picture1}
                                        quantifiable_product={
                                          card.quantifiable_product
                                        }
                                        Panier={panier}
                                      />
                                    </IonCol>
                                  );
                                })}
                            </>
                          );
                        })
                    ) : (
                      <div className="flex justify-center items-center">
                        <h2>aucun resultat</h2>
                      </div>
                    )}
                  </IonRow>
                </IonGrid>
              ) : (
                <div className="flex flex-col items-center justify-center mt-80">
                  <div class="loader">
                    <div class="cell d-0"></div>
                    <div class="cell d-1"></div>
                    <div class="cell d-2"></div>

                    <div class="cell d-1"></div>
                    <div class="cell d-2"></div>

                    <div class="cell d-2"></div>
                    <div class="cell d-3"></div>

                    <div class="cell d-3"></div>
                    <div class="cell d-4"></div>
                  </div>
                  <div className="flex items-center justify-center text-xl mt-10">
                    <span>Chargement des données en cours </span>
                  </div>
                </div>
              )}
            </div>
            <IonFab
              vertical="center"
              horizontal="center"
              slot="fixed"
              className="fab1"
              id="far"
            >
              <IonButton
                mode="ios"
                color="secondary"
                onClick={() => {
                  setShowmodal(true);
                }}
                size="small"
              >
                <IonBadge color="secondary">{panier.length}</IonBadge>
                <IonIcon icon={cart} className="animate__animated" />
                Votre commande
              </IonButton>
            </IonFab>

            <IonModal
              isOpen={showmodal}
              onDidDismiss={() => {
                setShowmodal(false);
              }}
              backdropBreakpoint={0.5}
              className="modal1"
            >
              <IonItem lines="none">
                <IonToolbar className="ion-text-center Titre1 ">
                  Votre Commande
                  <IonIcon
                    icon={closeCircleOutline}
                    size="large"
                    slot="end"
                    className="iconmod"
                    onClick={() => {
                      setShowmodal(false);
                    }}
                  />
                </IonToolbar>
              </IonItem>
              <Paniermodal
                Panier={panier}
                trigg={() => {
                  setSho(!sho);
                }}
              />
            </IonModal>
          </IonContent>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage>
          <IonHeader>
            <Navigation />
          </IonHeader>
          <IonContent fullscreen className="alice">
            <MainPage />
          </IonContent>
        </IonPage>

        {/* kdjkdk */}
      </>
    );
  }
};

export default Populaire;
