/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import React from "react";
import { useEffect, useState } from "react";
import Axios from "axios";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonApp,
  IonContent,
  IonList,
  IonItem,
  IonRow,
  IonCol,
  IonInput,
  IonGrid,
  IonLabel,
  IonMenuButton,
  IonThumbnail,
  IonAvatar,
  IonBadge,
  IonImg,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonCardSubtitle,
  IonFooter,
  IonLoading,
  IonSpinner,
  IonModal,
  IonToast,
  IonProgressBar,
} from "@ionic/react";
import {
  arrowBack,
  arrowForward,
  checkmarkSharp,
  closeCircle,
  closeCircleOutline,
  informationCircle,
  trash,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import "swiper/scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination } from "swiper";
import "./paniermodal.css";
import { PanierItem } from "./PanierItem";
import "./swip.css";
import { tab5 } from "./PanierItem";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProduct,
  setProductPan,
  updateQuantity,
  dec,
  vider,
  setnumberclients,
} from "../../Feature/PanierSlice";
import { recupProduct, setBoutik } from "../../Feature/ProductSlice";
import {
  decdateact,
  setcategories,
  setlike_list,
  recuplike_list,
  dectriggmod,
  setnumberUser,
} from "../../Feature/DeclencheursSlice";
import toast, { Toaster } from "react-hot-toast";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";

export const Tableau13 = (namet: boolean, prenomt: number) => [
  {
    Formation: namet,
    Cible: prenomt,
  },
];

export let tab4 = 12;

const aff = () => {
  setTimeout(() => {
    tab4 = 12;
  }, 500);
  tab4 = 14;
};
export const atr = () => {
  console.log("er");
};

interface Ajout_utiliformprops {
  // nom: String;
  // prenom: String;

  Panier: [][];
  trigg: () => void;
}

const Paniermodal: React.FC<Ajout_utiliformprops> = ({ Panier, trigg }) => {
  const [idant, setIdant] = useState(
    parseInt(window.location.pathname.split("/")[2])
  );
  // const [panier, setPanier] = useState<any[]>(useSelector((state:any) => state.panier.panier));
  const [showmodal, setShowmodal] = useState(false);
  const [showmodal1, setShowmodal1] = useState(false);
  const [progress, setprogress] = useState(false);
  const [totalquant, setTotalquant] = useState<number>(0);
  const [totalprix, setTotalprix] = useState<number>(0);
  const [invoice, setInvoice] = useState<any>();
  const [whatsapp, setWhatsapp] = useState<any>("");
  const [sexe, setSexe] = useState("");
  const [trashed, setTrash] = useState(false);
  const [edited, setEdited] = useState(false);
  const [telephone2, setTelephone2] = useState(0);
  const [quartier, setQuartier] = useState("Quartier");
  const [ville, setVille] = useState("Ville");
  const [maison, setMaison] = useState("Maison");
  const [adresse, setAdresse] = useState("");
  let [antecedants, setAntecedants] = useState(" ");
  let [date, setdate] = useState("");
  const [remarque, setRemarque] = useState(" ");
  // const [commande, setCommande] = useState<any>();
  const [showLoading, setShowLoading] = useState(true);
  const [zer, setZer] = useState<any>(
    useSelector((state: any) => state.panier.panier)
  );
  const [trigger, setTrigger] = useState<any>(
    useSelector((state: any) => state.panier.trigg)
  );
  let trigger2 = useSelector((state: any) => state.triggers.triggermod);
  let panier = useSelector((state: any) => state.panier.panier);
  // let numUser = useSelector((state: any) => state.triggers.numberUser);
  const boutique = useSelector((state: any) => state.product.boutique);
  const numberclient = useSelector((state: any) => state.panier.numberclient);
  const [numUser, setnumUser] = useState("");

  const [reclusia, setRec] = useState<any[]>([]);
  // const Regex = /^\+229\d{8}$/;
  const Regex = /^\+\d{1,3}\d{8,9}$/;

  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const [showToast9, setShowToast9] = useState(false);
  let commande: any = "";
  const dispatch = useDispatch();
  const [progress1, setprogress1] = useState(false);
  const [progress2, setprogress2] = useState(false);

  const getpan = () => {
    // fetch('https://backendtrader.digitalfirst.space/affichepanier').then((res) => {
    //     const data = res.json()
    //     return data
    // }).then((data) => {
    //     setPanier(data);
    //     setInvoice(data[0].invoice);
    // })
    setTotalquant(
      panier
        .map((e: any) => e.product_quantity)
        .reduce((prev: any, curr: any) => prev + curr, 0)
    );
    setTotalprix(
      panier
        .map((e: any) => e.total_price)
        .reduce((prev: any, curr: any) => prev + curr, 0)
    );

    for (var i = 0; i < panier.length; i++) {
      commande = [
        ...commande,
        "\n" + panier[i].product_quantity + " " + panier[i].product_name,
      ];
    }
  };
  const suppression = (ide: number | React.SetStateAction<any>) => {
    Axios.delete(`https://backendtrader.digitalfirst.space/deletepan/${ide}`);
    getpan();
    aff();
    getpan();
  };

  const calc = () => {
    console.log(whatsapp);

    if (whatsapp.match(Regex)) {
      dispatch(setnumberUser(whatsapp));
      dispatch(setnumberclients(whatsapp));
      envoi1();
    } else {
      // setShowToast1(true);
      setprogress1(true);
      setprogress1(true);
      toast.error(
        "Vueillez configurer le numeros de telephone pour passer la commande "
      );
    }
  };

  const envoi1 = () => {
    if (parseInt(panier.length) > 0) {
      console.log("1ere etpa");
      setprogress(true);
      setprogress2(true);
      setTimeout(() => {
        toast.loading(
          "Chargement des données en cours....\n\nVeuillez patienter.",
          {
            duration: 60000,
          }
        );
      }, 1000);
      Axios.post(
        "https://backendtrader.digitalfirst.space/ajoutcomList",
        {}
      ).then((ret) => { 
        for (let index = 0; index < panier.length; index++) {
          Axios.post("https://backendtrader.digitalfirst.space/ajoutcomList1", {
            product_quantity: panier[index].product_quantity,
            total_price: panier[index].total_price,
            unite_price: panier[index].unite_price,
            product_name: panier[index].product_name,
            product_id: panier[index].product_id,
            stock: panier[index].stock,
            picture1: panier[index].picture1,
            BoutiqueId: panier[index].BoutiqueId,
            total_sold: panier[index].total_sold,
            quantifiable_product: panier[index].quantifiable_product,
            whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
            invoice: ret.data,
          }).then((rets) => {
            console.log(rets.data);
          });
        }
        console.log(ret.data);
        setTimeout(() => {
          envoi(ret.data);
        }, 3000);
      });
    } else {
      setShowToast3(true);
    }
  };
  const suppr = () => {
    fetch("https://backendtrader.digitalfirst.space/supprpan", {}).then(
      (data) => {
        if (data) {
        } else {
        }
      }
    );
    getpan();
    aff();
  };
  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      }).then((ret) => {
        dispatch(setBoutik(ret.data));
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const envoi = (ide: any | React.SetStateAction<any>) => {
    Axios.post(
      "https://backendtrader.digitalfirst.space/total_command_byseller",
      {
        invoice: ide,
      }
    ).then((ret) => {
      // for (let index = 0; index < data.length; index++) {
      Axios.post("https://backendtrader.digitalfirst.space/ajoutcommande", {
        totalquant: totalquant,
        totalprix: totalprix,
        invoice: ide,
        status_paiement:
          panier[0].type_product === "Physique" ? "PAYER" : "NON PAYER",
        // invoice: ret.data[0].invoice,
        // whatsapp: numUser === "" ? whatsapp : numUser,
        whatsapp: JSON.parse(localStorage.getItem("numberclient") + ""),
        // whatsapp: !numUser ? whatsapp : numUser,
        id_boutique: ret.data[0].seller_id,
        // id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
      }).then((ret) => {
        if (ret.data === "suc") {
          console.log("commande ajoutée");
          console.log("2ere etpa");
          setShowToast2(true);
          setprogress(false);
          setprogress2(false);
          setTimeout(() => {
            envoi3(ide);
          }, 3000);
        } else {
        }
      });
      // }
    });
  };

  const envoi3 = (ide: any) => {
    dispatch(dec(!trigger));
    dispatch(dectriggmod(!trigger2));
    // const whats = `https://wa.me/${
    //   JSON.parse(localStorage.getItem("parrain") + "")
    // }?text=${commande}`;
    if (panier[0].type_product === "Physique") {
      const whats = `https://wa.me/${numUser}?text=${
        "Commande d'articles via la platform digitalTrader : " + commande
      }`;

      {
        numUser !== "" && (window.location.href = whats);
      }
    } else if (panier[0].type_product === "Numerique") {
      // setShowToast9(true);
      // setShowmodal1(true);
      localStorage.setItem("montant_paiement", String(totalprix));
      localStorage.setItem("invoice_command", String(ide));
      setTimeout(() => {
        window.location.href = "/paiement";
      }, 1000);
    }

    dispatch(vider(""));
    Axios.post("https://backendtrader.digitalfirst.space/afficheart", {
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recupProduct(ret.data));
      console.log(ret.data);
    });
    setShowmodal(false);
  };

  const refr = () => {
    Axios.post("http://127.0.0.1:8000/api/auth/checkToken", {
      token: "",
    }).then((ret) => {
      if (ret.data.success) {
        console.log("success");
      } else {
        console.log("non success");
      }
    });
  };
  const open = (totalprix: any) => {
    openKkiapayWidget({
      amount: totalprix,
      api_key: "f360c365307f9afa1c1cded51173173beef6f22b",
      // sandbox: true,
      email: "mevivital@gmail.com",
      phone: "61940010",
      name: "viyt",
    });
  };

  function successHandler(response: any) {
    console.log(response);
  }

  useEffect(() => {
    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success");
    };
  }, []);

  useEffect(() => {
    getpan();
    profile_full();
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
    }
  }, []);
  useEffect(() => {
    if (boutique[0]) {
      setnumUser(boutique[0].whatsapp);
    }
  }, [boutique]);

  useEffect(() => {
    getpan();
    if (JSON.parse(localStorage.getItem("numberclient") + "")) {
      dispatch(
        setnumberclients(JSON.parse(localStorage.getItem("numberclient") + ""))
      );
    }
  }, [(trigger: any) => {}]);

  return (
    <>
      {progress2 && (
        <div>
          <Toaster />
        </div>
      )}
      <IonContent fullscreen>
        <IonList lines="full" class="ion-no-margin">
          <IonList>
            {panier.map((val: any, key: any) => {
              return (
                <PanierItem
                  Id={val.product_id}
                  Stock={val.stock}
                  Add={val.product_quantity}
                  Name={val.product_name}
                  Unit={val.unite_price}
                  Total={val.total_price}
                  Ig={val.picture1}
                />
              );
            })}
          </IonList>
        </IonList>
      </IonContent>
      <IonFooter className="cartFooter">
        {progress ? (
          <IonProgressBar type="indeterminate"></IonProgressBar>
        ) : (
          <div className="cartCheckout">
            <IonCardSubtitle>
              {new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "XOF",
              }).format(totalprix)}
            </IonCardSubtitle>

            {numberclient === "" ? (
              // {JSON.parse(localStorage.getItem("numberclient") + "") === "" ? (
              <IonButton
                color="dark"
                onClick={() => {
                  setShowmodal(true);
                }}
              >
                <IonIcon icon={checkmarkSharp} />
                &nbsp;Commander
              </IonButton>
            ) : (
              <IonButton
                color="dark"
                onClick={() => {
                  envoi1();
                  // setShowmodal1(true);
                }}
              >
                <IonIcon icon={checkmarkSharp} />
                &nbsp;Commander
              </IonButton>
            )}
          </div>
        )}
      </IonFooter>
      <IonModal
        isOpen={showmodal}
        onDidDismiss={() => {
          setShowmodal(false);
        }}
        initialBreakpoint={0.25}
        breakpoints={[0, 0.25, 0.5, 0.75]}
        // className="w-full bg-white"
      >
        {progress1 && (
          <div>
            <Toaster />
          </div>
        )}
        <IonItem>
          <IonLabel>Veuillez entrer votre numéro whatsapp</IonLabel>
        </IonItem>
        <IonItem lines="none">
          <div className="flex justify-between items-center flex-col w-full ">
            <div className="w-full mt-3">
              <input
                type="text"
                className="form-control w-full"
                id="exampleFormControlInput1"
                placeholder="Ex: 22969889350"
                onChange={(e) => {
                  setWhatsapp("+" + e.target.value);
                  console.log("+" + e.target.value);
                }}
              />
            </div>
            <div className="mt-3">
              <IonButton
                onClick={() => {
                  // setShowmodal(false)
                  calc();
                }}
                // size="small"
                color="secondary"
              >
                Lancer la commande
              </IonButton>
            </div>
          </div>

          {/* <IonCol>
            <IonInput
              onIonChange={(e) => {
                setWhatsapp("+229" + e.detail.value);
              }}
            ></IonInput>
          </IonCol> */}
          {/* <IonCol size="3">
            
          </IonCol> */}
        </IonItem>
      </IonModal>
      <IonModal
        isOpen={showmodal1}
        onDidDismiss={() => {
          setShowmodal1(false);
        }}
        initialBreakpoint={0.25}
        breakpoints={[0, 0.25, 0.5, 0.75]}
        // className="w-full bg-white"
      >
        <IonItem>
          <IonLabel>Veuillez choisir un mode de paiement</IonLabel>
        </IonItem>
        <IonItem lines="none">
          <div className="flex flex-wrap gap-4 mt-4 justify-center items-center w-full ">
            <div
              className="flex flex-col justify-center items-center cursor-pointer"
              onClick={() => {
                open(totalprix);
              }}
            >
              <img
                src="kkiapay.jpg"
                alt=""
                className="rounded-full w-20 h-20 object-cover"
              />
              <div>
                <h2 className="text-2xl text-neutral-800">kkia pay</h2>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <img
                src="fedapay.png"
                alt=""
                className="rounded-full w-20 h-20 object-cover"
              />
              <div>
                <h2 className="text-2xl text-neutral-800">Feda pay</h2>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <img
                src="cinetpay.png"
                alt=""
                className="rounded-full w-20 h-20 object-cover"
              />
              <div>
                <h2 className="text-2xl text-neutral-800">Cinet pay</h2>
              </div>
            </div>
          </div>
        </IonItem>
      </IonModal>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Veuillez entrer un numéro valide"
        icon={informationCircle}
        position="top"
        duration={3000}
      />

      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message="Commande effectuée"
        icon={informationCircle}
        position="top"
        buttons={[
          {
            text: "fermer",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast3}
        onDidDismiss={() => setShowToast3(false)}
        message="Veuillez ajouter un produit"
        icon={informationCircle}
        position="top"
        duration={3000}
      />
      <IonToast
        isOpen={showToast9}
        onDidDismiss={() => setShowToast9(false)}
        message="Commande effectuée avec succes vous serrez redirigez vers un autre page pour le paiement"
        icon={informationCircle}
        position="top"
        duration={3000}
      />
    </>
  );
};

export default Paniermodal;
