/* eslint-disable no-lone-blocks */
import React from "react";
import { useEffect, useState } from "react";
import Axios from "axios";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonApp,
  IonContent,
  IonList,
  IonItem,
  IonRow,
  IonCol,
  IonInput,
  IonGrid,
  IonLabel,
  IonMenuButton,
  IonFabButton,
  IonNote,
  IonToast,
  IonModal,
  IonBackdrop,
  IonRouterLink,
} from "@ionic/react";
import {
  addOutline,
  arrowBack,
  arrowForward,
  bagOutline,
  cart,
  informationCircle,
  planet,
  play,
  playCircleOutline,
  playOutline,
  remove,
  removeCircleOutline,
  star,
  starHalfOutline,
  starOutline,
} from "ionicons/icons";
import "swiper/scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination } from "swiper";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProduct,
  setProductPan,
  updateQuantity,
  dec,
} from "../../Feature/PanierSlice";
import { RiStarSFill } from "react-icons/ri";
import { BsFillPlayCircleFill } from "react-icons/bs";

import "./swip.css";
import {
  createfavoris,
  deletefavoris,
  setBoutik,
  setfavoris,
  setproduitbout,
} from "../../Feature/ProductSlice";
import { setcategories } from "../../Feature/DeclencheursSlice";

interface Ajout_utiliformprops {
  Id: number;
  BoutiqueId: number;
}

const Description: React.FC<Ajout_utiliformprops> = ({ Id, BoutiqueId }) => {
  const [idant, setIdant] = useState<any>(Id); // window.location.pathname.split("/")[2])
  const [article, setArticle] = useState<any[]>([]);
  const [quantite, setQuantite] = useState<any>(1);
  let panier = useSelector((state: any) => state.panier.panier);
  const [idcategorie, setIdcategorie] = useState(0);
  const [nom, setNom] = useState<any>();
  const [nomboutik, setNomboutik] = useState<any>();
  const [descboutik, setDescboutik] = useState<any>();
  const [adressboutik, setAdressboutik] = useState<any>();
  const [whatboutik, setWhatboutik] = useState<any>();
  const [imagboutik, setImagboutik] = useState<any>();
  const [codeboutik, setCodeboutik] = useState<any>();
  const [prix, setPrix] = useState<any>();
  const [sexe, setSexe] = useState("");
  const [stock, setStock] = useState<any>(0);
  const [description, setDescription] = useState(0);
  const [picture1, setPicture1] = useState<any>();
  const [picture2, setPicture2] = useState<any>();
  const [picture3, setPicture3] = useState<any>();
  const [picture4, setPicture4] = useState<any>();
  const [video, setVideo] = useState<any>();
  const [, setAdresse] = useState("");
  let [antecedants, setAntecedants] = useState(" ");
  let [date, setdate] = useState("");
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState<any>(
    useSelector((state: any) => state.panier.trigg)
  );
  const [command, setCommand] = useState<any>();
  const [showmodal, setShowmodal] = useState(false);
  const [showmodal1, setShowmodal1] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [showToast3, setShowToast3] = useState(false);
  const boutique = useSelector((state: any) => state.product.boutique);
  const favoris = useSelector((state: any) => state.product.favoris);
  // const [favoris, setfavoriss] = useState(
  //   JSON.parse(localStorage.getItem("favoris") + "")
  // );
  // const [changestart, setchangestart] = useState(favoris.filter(
  //   // (t: any) => t === codeboutik
  //   (t: any) => t[0].boutiqueName === codeboutik
  // )[0] ? false : true);
  // const [favr, setfavr] = useState();
  const [changestart, setchangestart] = useState(false);

  const getart = () => {
    Axios.post("https://backendtrader.digitalfirst.space/recupart", {
      id: idant,
      id_boutique: BoutiqueId,
      // id_boutique: JSON.parse(localStorage.getItem("parrain") + "")[0].id
    }).then((ret) => {
      setArticle(ret.data);

      setStock(ret.data[0].stock);
      setIdcategorie(ret.data[0].idClinique);
      setNom(ret.data[0].name);
      setPrix(ret.data[0].price);
      setDescription(ret.data[0].description);
      setPicture1(ret.data[0].picture1);
      setPicture2(ret.data[0].picture2);
      setPicture3(ret.data[0].picture3);
      setPicture4(ret.data[0].picture4);
      setVideo(ret.data[0].video);
      setStock(ret.data[0].stock);
    });
  };
  const profile_full = () => {
    try {
      Axios.post("https://backendtrader.digitalfirst.space/profile_full", {
        id: BoutiqueId,
      }).then((ret) => {
        dispatch(setBoutik(ret.data));
        setNomboutik(ret.data[0].store_name);
        setDescboutik(ret.data[0].description);
        setAdressboutik(ret.data[0].adress);
        setWhatboutik(ret.data[0].whatsapp);
        setImagboutik(ret.data[0].image);
        setCodeboutik(ret.data[0].boutiqueName);
        console.log(ret.data);
      });
    } catch (e) {}
  };

  const increm = () => {
    if (quantite < stock) {
      setQuantite(quantite + 1);
    }
  };
  const decrem = () => {
    if (quantite > 1) {
      setQuantite(quantite - 1);
    }
  };
  const ajout = () => {
    if (panier.filter((t: any) => t.product_id == idant)[0]) {
      // setAjoute((panier.filter((t:any)=>t.product_id== Id)[0]).product_quantity);
      dispatch(
        updateQuantity([
          parseInt(quantite),
          idant,
          parseInt(prix) * parseInt(quantite),
        ])
      );
      dispatch(dec(!trigger));
      setShowToast2(true);
    } else {
      dispatch(
        setProductPan({
          product_id: idant,
          product_quantity: parseInt(quantite),
          product_name: nom,
          unite_price: parseInt(prix),
          total_price: parseInt(prix) * parseInt(quantite),
          picture1: picture1,
          stock: stock,
        })
      );
      setShowToast1(true);
      dispatch(dec(!trigger));
    }

    // Axios.post('https://backendtrader.digitalfirst.space/ajoutpanier', {
    //         product_quantity: parseInt (quantite),
    //         product_name: nom,
    //         unite_price: parseInt (prix),
    //         total_price:  prix * quantite ,
    //         product_id: idant
    //     }).then((ret) => {
    //         if (ret.data == 'suc') {
    //             alert('Element enrégistré');
    //         } else {
    //             alert('Element non enrégistré');
    //         }
    //     })
    //     console.log(prix);
  };

  const data = [
    {
      title: "Road",
      subtitle: "Long road",
      image: "/assets/1e.jpg",
    },
    {
      title: "Moun",
      subtitle: "Big mountains",
      image: "/assets/1e.jpg",
    },
    {
      title: "Unk",
      subtitle: "This is unknown",
      image: "/assets/1e.jpg",
    },
    {
      title: "Unk",
      subtitle: "This is unknown",
      image: "/assets/1e.jpg",
    },
  ];

  const putidboutique = (payload: any) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  useEffect(() => {
    if (panier.filter((t: any) => t.product_id == idant)[0]) {
      // setAjoute((panier.filter((t:any)=>t.product_id== Id)[0]).product_quantity);
      setCommand(true);
    } else {
      // setAjoute(0);
      setCommand(false);
    }
  }, [(trigger: any) => {}]);

  useEffect(() => {
    getart();
    profile_full();
    if (JSON.parse(localStorage.getItem("favoris") + "")) {
      dispatch(createfavoris(JSON.parse(localStorage.getItem("favoris") + "")));
      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""));
    } else {
      var tab = [
        [
          {
            store_name: "",
            adress: "",
            description: "",
            website: "",
            facebook: "",
            whatsapp: "",
            boutiqueName: "startzeroh",
            image: "",
            id: "",
          },
        ],
      ];
      dispatch(createfavoris(tab));
      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""));
    }
  }, []);
  useEffect(() => {
    console.log(favoris);
  }, [favoris]);

  return (
    <>
      {/* <IonHeader translucent>
                    <IonToolbar color='secondary'>
                        <IonButtons slot="start">
                            <IonButton routerLink="/">
                                <IonIcon style={{ fontSize: '30px' }} icon={arrowBack} />
                            </IonButton>
                        </IonButtons>
                        <IonTitle className='ion-text-center'>Descriptif</IonTitle>
                        <IonButtons slot="end">
                            <IonMenuButton />
                        </IonButtons>
                    </IonToolbar> 
                </IonHeader> */}
      <IonContent fullscreen>
        <IonList lines="full" class="ion-no-margin">
          <IonToolbar className="ion-text-center Titre1 ">{nom}</IonToolbar>
          <div className="newdiv">
            <Swiper
              spaceBetween={30}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className=""
            >
              {picture1 ? (
                <SwiperSlide>
                  <img
                    src={`https://backendtrader.digitalfirst.space/${picture1}`}
                    alt="card"
                    className="imdet"
                  />
                </SwiperSlide>
              ) : null}
              {picture2 ? (
                <SwiperSlide>
                  <img
                    src={`https://backendtrader.digitalfirst.space/${picture2}`}
                    alt="card"
                    className="imdet"
                  />
                </SwiperSlide>
              ) : null}
              {picture3 ? (
                <SwiperSlide>
                  <img
                    src={`https://backendtrader.digitalfirst.space/${picture3}`}
                    alt="card"
                    className="imdet"
                  />
                </SwiperSlide>
              ) : null}
              {picture4 ? (
                <SwiperSlide>
                  <img
                    src={`https://backendtrader.digitalfirst.space/${picture4}`}
                    alt="card"
                    className="imdet"
                  />
                </SwiperSlide>
              ) : null}
              {/* <SwiperSlide><img src={`https://backendtrader.digitalfirst.space/${picture2}`} alt="card" className="imdet" /></SwiperSlide>
                            <SwiperSlide><img src={`https://backendtrader.digitalfirst.space/${picture3}`} alt="card" className="imdet" /></SwiperSlide>
                            <SwiperSlide><img src={`https://backendtrader.digitalfirst.space/${picture4}`} alt="card" className="imdet" /></SwiperSlide> */}
            </Swiper>
          </div>
          <div className="flex items-center bg-white w-full justify-between p-4">
            <IonCol size="5">
              <IonItem lines="none">
                <IonButton
                  className="ion-text-center"
                  color="secondary"
                  onClick={() => {
                    setShowmodal(true);
                    console.log(showmodal);
                  }}
                >
                  <IonIcon icon={playCircleOutline} /> Voir video
                </IonButton>
              </IonItem>
            </IonCol>
            <IonCol size="5">
              <IonItem lines="none">
                <IonButton
                  color="secondary"
                  className=""
                  onClick={() => {
                    setShowmodal1(true);
                  }}
                >
                  <IonIcon icon={bagOutline} /> Boutique
                </IonButton>
              </IonItem>
            </IonCol>

            {/* <div><button type="button" className="btn btn-primary" onClick={() => setShowmodal(true)}> <BsFillPlayCircleFill />Primary</button></div> */}
            {/* <div className="flex items-center text-2xl gap-2">
              <h3 className="ml-4 text-xl nereide">{nomboutik}</h3>
              <IonIcon
                icon={starOutline}
                className="text-3xl -mt-1 mr-3"
                style={{ color: "rgb(61,194,255)" }}
              />
            </div> */}
          </div>

          <IonItem className="nereide">Description</IonItem>
          <IonItem>
            <IonNote className="ion-margin-top nereide">{description}</IonNote>
          </IonItem>

          <IonItem className="ion-margin-top nereide">
            <IonGrid>
              <IonRow>Quantité disponible :{stock<=0 ? "  En rupture de stock" : stock}</IonRow>
              <IonRow>
                <IonCol className="ion-text-center lab">Quantité :</IonCol>
                <IonCol size="8">
                  <IonItem lines="none">
                    <IonFabButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        decrem();
                      }}
                    >
                      <IonIcon icon={remove} />
                    </IonFabButton>

                    <IonInput
                      className="entrees"
                      value={quantite}
                      max={stock}
                      min={1}
                      step="1"
                      readonly
                    ></IonInput>

                    <IonFabButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        increm();
                      }}
                    >
                      <IonIcon icon={addOutline} />
                    </IonFabButton>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none" className="ion-margin">
            <IonButton
              color="secondary"
              slot="end"
              size="small"
              onClick={() => {
                ajout();
              }}
            >
              Commander
              <IonIcon icon={cart} />
            </IonButton>
          </IonItem>

          <IonModal
            isOpen={showmodal}
            onDidDismiss={() => {
              setShowmodal(false);
            }}
            id="example-modal"
          >
            <video controls height="200" className="vid">
              <source src={`https://backendtrader.digitalfirst.space/${video}`} />
            </video>
          </IonModal>
          <IonModal isOpen={showmodal1}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Détail de boutique</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setShowmodal1(false)}>
                    Fermer
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <div className="flex flex-col items-center justify-center">
                {imagboutik === "" ? (
                  <img
                    src="store.png"
                    alt="card"
                    className="w-36 h-36 object-cover rounded-full mb-4"
                  />
                ) : (
                  <img
                    src={`https://backendtrader.digitalfirst.space/uploads/${imagboutik}`}
                    alt="card"
                    className="w-36 h-36 object-cover rounded-full mb-4"
                  />
                )}

                <div className="flex flex-col items-center justify-start">
                  <h1 className="text-2xl text-black font-semibold">
                    Nom de la boutique{" "}
                  </h1>{" "}
                  {nomboutik === "" ? (
                    <h3 className="text-xl mt-4 mb-4">
                      Aucun nom configurer pour cette boutique
                    </h3>
                  ) : (
                    <h3 className="text-xl mt-4 mb-4">{nomboutik}</h3>
                  )}
                </div>
                <div className="flex flex-col items-center justify-start">
                  <h1 className="text-2xl text-black font-semibold">
                    Description{" "}
                  </h1>{" "}
                  {descboutik === "" ? (
                    <h3 className="text-xl mt-4 mb-4">
                      Aucune description configurer pour cette boutique
                    </h3>
                  ) : (
                    <p className="text-xl mt-4 mb-4">{descboutik}</p>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-2xl text-black font-semibold">
                    Adresse{" "}
                  </h1>{" "}
                  {adressboutik === "" ? (
                    <h3 className="text-xl mt-4 mb-4">
                      Aucune adresse configurer pour cette boutique
                    </h3>
                  ) : (
                    <h3 className="text-xl mt-4 mb-4">{adressboutik}</h3>
                  )}
                </div>
                <div className="flex flex-col p-5 m-5">
                  {/* <button type="button" className="btn btn-primary">Primary</button> */}

                  <IonButton
                    color="secondary"
                    className="mb-4"
                    onClick={() => {
                      putidboutique(BoutiqueId);
                    }}
                  >
                    Voir les produits
                  </IonButton>

                  <IonButton
                    color="secondary"
                    className="mb-4"
                    onClick={() => {
                      {
                        window.location.href = `https://wa.me/${whatboutik}`;
                      }
                    }}
                  >
                    contacter le vendeur
                  </IonButton>
                  <IonButton
                    color="secondary"
                    className="mb-4"
                    onClick={() => {
                      // if (favoris[0][0].whatsapp === "") {
                      //   console.log("vide");
                      // } else {
                      //   console.log("quelquechose");
                      // }
                      console.log(favoris);
                      // dispatch(setfavoris([]));
                      //
                      if (
                        favoris.filter(
                          // (t: any) => t === codeboutik
                          (t: any) => t[0].boutiqueName === codeboutik
                        )[0]
                      ) {
                        console.log("la boutique a deja ete ajoute au favoris");
                        dispatch(deletefavoris(codeboutik));
                        // setfavr("Mettre en favoris");
                      } else {
                        console.log("la boutique na pas ete ajoute au favoris");
                        dispatch(setfavoris(boutique));
                        // setfavr("Retirer des favoris");
                        // setfavoriss()
                      }

                      //     .map((fav: any, index: any) => {
                      //  if(fav[0].whatsapp){
                      //   console.log( fav[0].whatsapp);
                      //  }else{
                      //   console.log("la boutique na pas ete ajoute au favoris");

                      //  }
                      //   })}
                      // console.log(codeboutik);
                      // dispatch(setfavoris(boutique));
                      // setfavoriss(JSON.parse(localStorage.getItem("favoris") + ""))
                    }}
                  >
                    {favoris.filter(
                      // (t: any) => t === codeboutik
                      (t: any) => t[0].boutiqueName === codeboutik
                    )[0] ? (
                      <IonIcon
                        icon={starOutline}
                        className="text-3xl -mt-1 mr-3"
                        style={{ color: "rgb(255,255,255)" }}
                      />
                    ) : (
                      <IonIcon
                        icon={star}
                        className="text-3xl -mt-1 mr-3"
                        style={{ color: "rgb(255,255,255)" }}
                      />
                    )}
                    {favoris.filter(
                      // (t: any) => t === codeboutik
                      (t: any) => t[0].boutiqueName === codeboutik
                    )[0]
                      ? "retirer des favoris"
                      : "Mettre en favoris"}
                  </IonButton>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="Elément ajouté au panier"
            icon={informationCircle}
            position="top"
            duration={800}
          />
          <IonToast
            isOpen={showToast2}
            onDidDismiss={() => setShowToast2(false)}
            message="Quantité mise à jour"
            icon={informationCircle}
            position="top"
            duration={800}
          />
          <div className="aaa"></div>
        </IonList>
      </IonContent>
    </>
  );
};

export default Description;
