import React from 'react';
import ShopForm from './ShopForm';

const AddShopForm = () => {
    return (
        <div className='form__body'>
            <ShopForm/>
        </div>
    );
};

export default AddShopForm;