/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useEffect, useRef, useState } from "react";
import {
  IonApp,
  IonButton,
  IonCol,
  IonList,
  IonModal,
  IonThumbnail,
  IonSearchbar,
  IonContent,
  IonAvatar,
  IonSelectOption,
  IonPage,
  IonItemDivider,
  IonSelect,
  IonRadioGroup,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonAlert,
  IonButtons,
  IonMenuButton,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonNote,
  IonBadge,
  IonRouterLink,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from "@ionic/react";

import "./homes.css";
import {
  triangle,
  ellipse,
  square,
  arrowBack,
  arrowForward,
  personCircleOutline,
  globeOutline,
  calendar,
  informationCircle,
  map,
  personCircle,
  chevronBack,
  search,
} from "ionicons/icons";
import { Route, Redirect } from "react-router";

// import { zer } from '../../pages/Nouv2';
// import { SearchModal } from './searchModal';
import { IonReactRouter } from "@ionic/react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { App } from "@capacitor/app";
import { useIonRouter } from "@ionic/react";
import { Conteneur } from "./conteneur";
import { recupProduct } from "../../Feature/ProductSlice";
import { FiRefreshCw } from "react-icons/fi";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import {
  recuplisteproduitNumerique,
  recupproduitNumerique,
  recuptransactionId,
} from "../../Feature/PanierSlice";

// console.log(Date.parse('2001-10-10'));            // Convertir un string en date (renvoie le nombre de seconde avant 1970)

interface Ajout_utiliformprops {
  Panier: [][];
}

export const Download_file: React.FC<Ajout_utiliformprops> = () => {
  const [response, setresponse] = useState("");
  const [showToast, setShowToast] = useState(false);
  let produitNumerique = useSelector(
    (state: any) => state.panier.produitNumerique
  );
  let listeproduitNumerique = useSelector(
    (state: any) => state.panier.listeproduitNumerique
  );
  let transactionId = useSelector((state: any) => state.panier.transactionId);
  const dispatch = useDispatch();

  const recuplistcommande = () => {
    Axios.post("https://backendtrader.digitalfirst.space/listecommandenum", {
      invoice: localStorage.getItem("invoice_command"),
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recupproduitNumerique(ret.data));
    });
  };
  const recuptransactionIde = () => {
    Axios.post("https://backendtrader.digitalfirst.space/verif_valid_fac", {
      invoice: localStorage.getItem("invoice_command"),
      id_boutique: JSON.parse(localStorage.getItem("BoutiqueId") + ""),
    }).then((ret) => {
      dispatch(recuptransactionId(ret.data));
    });
  };
  const recuplistproduitNumerique = () => {
    Axios.get(
      "https://backendtrader.digitalfirst.space/listeproduitnum",
      {}
    ).then((ret) => {
      dispatch(recuplisteproduitNumerique(ret.data));
    });
  };

  const putidboutique = (payload: any) => {
    localStorage.setItem("BoutiqueId", JSON.stringify(payload));
    window.location.href = "/prodbout";
  };

  useEffect(() => {
    recuplistcommande();
    recuplistproduitNumerique();
    recuptransactionIde();
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <div className="flex justify-between items-center">
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  putidboutique(
                    JSON.parse(localStorage.getItem("BoutiqueId") + "")
                  );
                }}
              >
                <IonIcon color="medium" icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle className="nereide">Digital trader</IonTitle>

            {/* <IonButtons
              slot="end"
              className="mr-5 text-xl cursor-pointer"
              onClick={() => {
                window.location.href = "/prodbout";
              }}
            >
              <FiRefreshCw />
            </IonButtons> */}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Module en cours d'intégration "
        duration={3000}
        position="top"
      />
      
      <IonContent fullscreen className="">
        <div className="flex flex-col justify-center items-center w-full h-full">
          {transactionId[0] &&
            transactionId.map((t: any, i: any) => {
              return t.transactionId === "1" ? (
                <div className="w-[80%] flex flex-col  shadow-md rounded-sm bg-white px-6 gap-6 py-6 justify-center items-center">
                  <span>
                    Vueillez éffectuer le paiement pour accéder aux
                    télechargement de vos produits
                  </span>
                </div>
              ) : (
                <div className="w-[80%] flex flex-col  shadow-md rounded-sm bg-white px-6 gap-6 py-6 justify-center items-center">
                  {produitNumerique.map((card: any, index: any) => {
                    return listeproduitNumerique
                      .filter((t: any) => t.id_product === card.product_id)
                      .map((val: any, i: any) => {
                        return (
                          <div className="flex w-full h-5 justify-between items-center text-sm">
                            <span>{card.product_name}</span>
                            <a
                              className="bg-deep_sky_blue no-underline text-white block leading-5 px-3 py-2 rounded-xl font-semibold"
                              href={`https://backendtrader.digitalfirst.space/${val.file_upload}`}
                            >
                              Télécharger
                            </a>
                          </div>
                        );
                      });
                  })}
                </div>
              );
            })}
        </div>
      </IonContent>
    </IonPage>
  );
};
