/* eslint-disable no-unused-vars */
import { IonButton, IonCol, IonGrid, IonRow, useIonRouter } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiFilter } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  setactive_categ,
  setactive_tendance,
} from "../../Feature/DeclencheursSlice";
import { dec } from "../../Feature/PanierSlice";
import HomeCard from "../mainPage/HomeCard";
import toast, { Toaster } from "react-hot-toast";


const Filterproduct = () => {
  const [modalShowfilter, setModalShowfilter] = React.useState(false);

  return (
    <div className="flex items-center justify-end mt-0">
      <button
        className="bg-blue-700 px-2 py-2 flex rounded-lg text-xl text-white items-center mr-5"
        onClick={() => {
          setModalShowfilter(true);
        }}
      >
        <BiFilter className="mr-2" />
        Filtrer les produits
      </button>
      {/* <button className="bg-blue-700 px-2 py-2 flex rounded-lg text-xl text-white items-center">
        <BiFilter className="mr-2" />
        Votre commande
      </button> */}
      <Modalfilter
        show={modalShowfilter}
        onHide={() => setModalShowfilter(false)}
      />
    </div>
  );
};

export default Filterproduct;

function Modalfilter(props) {
  let article2 = []
    .concat(useSelector((state) => state.product.product_bout))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });
  const products_featured = useSelector(
    (state) => state.product.product_featured
  );
  let panier = useSelector((state) => state.panier.panier);
  const [showmodal, setShowmodal] = useState(false);
  const [produit, setProduitlist] = useState([]);
  const [velk, setVelk] = useState([]);
  const [titre, setTitre] = useState();
  const [age, setAge] = useState(0);
  const [groupee, setGroupee] = useState("rr");
  const [nomCli, setNomCli] = useState("rr");
  const [telephone, setTelephone] = useState("rr");
  const [remarque, setRemarque] = useState("rr");
  const [adresse, setAdresse] = useState("rr");
  const [antecedant, setantecedant] = useState("rr");
  const [id, setId] = useState(0);
  const [checked, setChecked] = useState(false);
  const [searchText, setSearchText] = useState("");
  const router = useIonRouter();
  const productsRef = useRef();
  let act_categ = [].concat(
    useSelector((state) => state.triggers.active_categ)
  );
  let act_tend = [].concat(
    useSelector((state) => state.triggers.active_tendance)
  );
  let category = [].concat(useSelector((state) => state.triggers.categories));
  const dispatch = useDispatch();
  let article1 = [].concat(useSelector((state) => state.product.product_bout));
  const [nub, setNub] = useState(10);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  let trigger = useSelector((state) => state.triggers.triggermod);
  const trigg = useSelector((state) => state.panier.trigg);
  let toastaddcommande = useSelector((state) => state.triggers.toastaddcommande);

  const loadData = (ev) => {
    setTimeout(() => {
      setNub(nub + 10);
      ev.target.complete();
    }, 500);
  };

  const change1 = async (n) => {
    // article= article.filter((e: any) => (e.category_id == n))
    await productsRef.current.classList.add("animate__fadeOutLeft");
    setTimeout(() => {
      productsRef.current.classList.remove("animate__fadeOutLeft");
      productsRef.current.classList.add("animate__fadeInRight");
      dispatch(setactive_categ(n));
      // setSeg(n)
    }, 700);
  };

  const change2 = async (n) => {
    // article= article.filter((e: any) => (e.category_id == n))
    await productsRef.current.classList.add("animate__fadeOutLeft");
    setTimeout(() => {
      productsRef.current.classList.remove("animate__fadeOutLeft");
      productsRef.current.classList.add("animate__fadeInRight");
      dispatch(setactive_tendance(n));
      // setSeg(n)
    }, 700);
  };

  let article = []
    .concat(useSelector((state) => state.product.product_bout))
    .sort(function (a, b) {
      var key1 = new Date(a.creation_date);
      var key2 = new Date(b.creation_date);
      if (key1 < key2) {
        return 1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return -1;
      }
    });

  const permu = async (n) => {
    dispatch(setactive_categ(n));
    // await productsRef.current.classList.add("animate__fadeOutLeft");
    // setTimeout(() => {
    //     productsRef.current.classList.remove("animate__fadeOutLeft");
    //     productsRef.current.classList.add("animate__fadeInRight");
    //     // setSeg(n)
    // }, 500);
  };

  const change = (ide) => {
    setSearchText(ide);
    const query = ide.toLowerCase();
    setVelk(
      produit.filter((t) => t.name.toLowerCase().includes(ide.toLowerCase()))
    );
    dispatch(dec(!trigg));
  };

  useEffect(() => {}, [trigger]);

  useEffect(() => {
    toast.error(
      "Ce produits appartient à une boutique autre que celle de votre commande",{
        duration: 6000,
      }
    );
  }, [toastaddcommande])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
     {toastaddcommande && (
        <div>
          <Toaster />
        </div>
      )}
      <Modal.Header closeButton  onClick={props.onHide} >
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 class="modal-title text-lg font-bold" id="exampleModalLongTitle">
            Filtre
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-wrap items-center justify-center w-full">
          {/* <div>
            <div>
              <h2>Categories</h2>
            </div>
            <IonGrid>
              <IonRow style={{ margin: "auto" }}>
                {props.category.map((f) => (
                  <IonCol key={f} size="auto">
                    <IonButton
                      className="nereide"
                      mode="ios"
                      size="small"
                      expand="full"
                      color={props.act_categ === f.id ? "secondary" : "light"}
                      // onClick={() => { change1(f.id) }}
                    >
                      <span className="cache">{f.nom}</span>
                    </IonButton>
                  </IonCol>
                ))}
                <IonCol size="auto">
                  <IonButton
                    className="nereide"
                    mode="ios"
                    size="small"
                    expand="full"
                    color="light"
                  >
                    <span
                      className="cache"
                      //  onClick={e => { dispatch(setactive_categ("")); dispatch(setactive_tendance("")); setNub(10) }}
                    >
                      Tous
                    </span>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div> */}
          <div className="search__wrapper">
            <form className="form">
              <label for="search">
                <input
                  className="input"
                  type="text"
                  required=""
                  placeholder="Rechercher un produit"
                  onChange={(e) => {
                    setSearchText(String(e.target.value));
                    change(e.detail.value);
                  }}
                  id="search"
                />
                <div className="fancy-bg"></div>
                <div className="search">
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                  >
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                    </g>
                  </svg>
                </div>
                <button className="close-btn" type="reset">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </label>
            </form>
          </div>
          {article2[0] ? (
            <div className="container-fluid mt-0 mb-0 flex flex-wrap justify-center items-center gap-3 card__wrapers">
              {article2.filter((t) =>
                t.name.toLowerCase().includes(searchText.toLowerCase())
              )[0] ? (
                article2
                  .filter((t) =>
                    t.name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((card, index) => {
                    return (
                      <HomeCard
                        Nom={card.name}
                        Prix={card.price}
                        Id={card.id}
                        Stock={card.stock}
                        BoutiqueId={card.seller_id}
                        Ig={card.picture1}
                        Totalsold={card.total_sold}
                        quantifiable_product={card.quantifiable_product}
                        type_product={card.type_product}
                        Panier={props.panier}
                      />
                    );
                  })
              ) : (
                <div>
                  <h2>aucun resultat</h2>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mt-80">
              <div class="loader">
                <div class="cell d-0"></div>
                <div class="cell d-1"></div>
                <div class="cell d-2"></div>

                <div class="cell d-1"></div>
                <div class="cell d-2"></div>

                <div class="cell d-2"></div>
                <div class="cell d-3"></div>

                <div class="cell d-3"></div>
                <div class="cell d-4"></div>
              </div>
              <div className="flex items-center justify-center text-xl mt-10">
                <span>Chargement des données en cours </span>
              </div>
            </div>
          )}

          {/* <HomeCard/> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}
