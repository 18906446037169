import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';


const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const domain ='https://digitaltrader.benindigital.com/'
        // const domain ='https://backendtrader.digitalfirst.space/shopclient'
        // const slug = event.url.split('.app').pop();
        const slugArray = event.url.split(domain);

        // get the last element with pop()
        const slug = slugArray.pop()
        if (slug) {
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;